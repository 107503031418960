import React, { useState } from 'react';
import FormularioSimulacion from "./components/FormularioSimulacion/FormularioSimulacion";
import ResultadosSimulacion from "./components/ResultadosSimulacion/ResultadosSimulacion";
import Cronograma from "./components/Cronograma/Cronograma";
import ProductoDropdown from "./components/ProductoDropDown/ProductoDropdown";
import "./index.scss";
function App() {
  const [product, setProduct] = useState(null);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [mostrarCronograma, setMostrarCronograma] = useState(false);
  const [parametersProduct, setParametersProduct] = useState([])
  const [creditSummary, setCreditSummary] = useState(undefined);
  const [paymentPlan, setPaymentPlan] = useState([])
  const [urlDownload, setUrlDownload] = useState("")
  const handleProduct = (producto) => {
    setProduct(producto);
    setMostrarFormulario(true);
    setMostrarResultados(false);
  };

  const handleSimularCredito = (data) => {
    setCreditSummary({ creditSummary: data.creditSummary, formCredit: data.formCredit });
    setPaymentPlan(data.result);
    setUrlDownload(data.urlPdfSchedule)
    setMostrarFormulario(false);
    setMostrarResultados(true);
  };

  return (
    <div className="container__Simulador">

      {!mostrarResultados && <ProductoDropdown onProduct={handleProduct} setParametersProduct={setParametersProduct} />}
      <FormularioSimulacion visible={mostrarFormulario} product={product} productDetail={parametersProduct.find(item => product!=null && item.value == product.value)} onSimularCredito={handleSimularCredito} />
      {!mostrarCronograma && mostrarResultados && (
        <ResultadosSimulacion product={product?.name} creditSummary={creditSummary} onChangeVisibilityCronograma={setMostrarCronograma}  />
      )}
      {mostrarCronograma && (<Cronograma product={product?.name} paymentPlan={paymentPlan} urlDownload={urlDownload} handleShowResults={(e) => {
         setMostrarFormulario(false);
         setMostrarResultados(true);
         setMostrarCronograma(false);
      }} />)
      }



    </div>
  );
}

export default App;



