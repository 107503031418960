import React from "react";
import { Container } from "react-bootstrap";
import Bannerhome1 from './../../assets/images/banner-donde-estamos.png'
import SurgirCardLocations from "components/ui/SurgirCardLocations/SurgirCardLocations";
import "./WhereWeAre.scss";

const WhereWeAre: React.FC = () => {
  return (
    <div>

      <div className="boxi" >
        <Container>
          <div className="whe-we-are-container-banner" >
            <div className="whe-we-are-container-text">
              <span className="whe-we-are-text-banner" >Dónde estamos<br />
                <span className="whe-we-are-subText-banner">Encuentra la oficina más cercana a ti. </span>
              </span>
            </div>
            <div>
              <img className="whe-we-are-image" src={Bannerhome1} alt="Banner Donde Estamos" />
            </div>
          </div>
        </Container>

      </div>
      <SurgirCardLocations />

    </div>
  );
}
export default WhereWeAre;
