export const isSafeUrl = (url: string) => {
    try {
        const parsedUrl = new URL(url, window.location.origin);
        return ['http:', 'https:'].includes(parsedUrl.protocol);
    } catch (e) {
        return false;
    }
};

export const createLink = (url: string, target?: '_blank' | '_self' | '_parent' | '_top' | 'framename') => {
    if (url && isSafeUrl(url)) {
        window.open(url, target, 'noopener,noreferrer');
    } else {
        console.warn("URL no segura, no se abrira el enlace");
    }
}