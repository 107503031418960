import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MESSAGE_ERROR_PENDING_CONFORMITY, MESSAGE_ERROR_REQUIRED, MESSAGE_ERROR_REQUIRED_PROTECTION_DATA } from "./FormComplaintsBook.fixture";
import { apiPostSaveClaim, apiPostSaveDemand, apiUploadDocument } from "service/apiEndPoint";
import moment from "moment/moment";
import React from "react";
import BannerComplaintsBook from "views/complaints-book/components/BannerComplaintsBook";
import SurgirStepper from "components/ui/SurgirStepper/SurgirStepper";
import { SurgirModal } from "components/ui/SurgirModal/SurgirModal";
import './FormComplaintsBook.scss'
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import FormPersonalData from "./components/FormPersonalData/FormPersonalData";
import FormContactInformation from "./components/FormContactInformation/FormContactInformation";
import FormInformationComplaints from "./components/FormInformationComplaints/FormInformationComplaints";
import { SurgirSnackbarContentWrapper } from "components/ui/SurgirSnackbarContentWrapper/SurgirSnackbarContentWrapper";
import { validateDocumentNumber, validateEmail, validatePhone, validateText } from "utils/ValidateField";

const FormComplaintsBook: React.FC = () => {
  const [formData, setformData] = useState({
    typeDocument: null as any,
    document: "",
    activeClient: 1,
    firstName: "",
    lastName: "",
    corporateName: "",
    localCustomer: 1,
    dialCode: null as any,
    date: "",
    phone: "",
    otherPhone: "",
    responseChannel: "Correo electrónico",
    email: "",
    typeRoad: "",
    addressName: "",
    typeAddress: "",
    addressInsideNumber: "",
    department: null as any,
    province: null as any,
    district: null as any,
    reference: "",
    amount: "",
    coin: null as any,
    productCode: null as any,
    reasonCode: null as any,
    troubleCode: "Demora/ Error/trámite de operaciones y productos",
    subReasonCode: null as any,
    claimMessage: "",
    codeReasonRequest: null as any,
    checksValidation: false as any,
    compliance: false,
    policy: false,
    dataFile: [],
    captchaRef: null
  });

  const [step, setStep] = useState(1);
  const [disabledButtonSend, setDisabledButtonSend] = useState(false);
  const [urlDownload, setUrlDownload] = useState("")
  const [idRCL, setIdRCL] = useState(1);
  const [idREQ, setIdREQ] = useState(1)
  const [messagesError, SetMessagesError] = useState([{}]);
  const navigate = useNavigate();
  const typeForm = sessionStorage.getItem("typeForm");
  const total = 3;

  const continuar = () => {
    if (step === 1) {
      const formPasoUno = [
        formData.typeDocument,
        formData.document,
        formData.firstName,
        formData.lastName,
      ];

      if (validateValues(formPasoUno)) {
        SetMessagesError([
          {
            text: MESSAGE_ERROR_REQUIRED,
          },
        ]);
        openSnackerrorFunction();
        setFocusValidation(true);
      } else {
        if (
          !validateDocumentNumber(formData.document, formData.typeDocument || '')
            .error &&
          !validateText(formData.firstName).error &&
          !validateText(formData.lastName).error
        ) {
          continueFlow();
        } else {
          SetMessagesError([
            {
              text: MESSAGE_ERROR_REQUIRED,
            },
          ]);
          openSnackerrorFunction();
        }
      }
    }

    if (step === 2) {
      const formPasoDos = [
        formData.dialCode,
        formData.phone,
        formData.email,
        formData.responseChannel
      ];
      if (formData.localCustomer) {
        formPasoDos.push(
          ...[
            formData.department,
            formData.province,
            formData.district,
            formData.reference,
            formData.addressName,
          ]
        );
      }

      if (validateValuesStep2(formPasoDos)) {
        SetMessagesError([
          {
            text: MESSAGE_ERROR_REQUIRED,
          },
        ]);
        setFocusValidation(true);
        openSnackerrorFunction();
      } else {
        if (
          !validateEmail(formData.email).error &&
          !validatePhone(formData.phone).error
        ) {
          continueFlow();
        } else {
          SetMessagesError([
            {
              text: MESSAGE_ERROR_REQUIRED,
            },
          ]);
          setFocusValidation(true);
          openSnackerrorFunction();
        }
      }
    }
  };

  const continueFlow = () => {
    if (step >= total) {
      setStep(total);
    }
    if (step < total) {
      setStep(step + 1);
    }
  };

  const regresar = () => {
    if (step <= 1) {
      setStep(1);
      navigate("/reclamos-y-requerimientos");
    }
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [openSnackError, setopenSnackError] = useState(false);
  const [focusValidation, setFocusValidation] = useState(false);

  const handleModalSuccessClose = () => {
    setVisibleModalSuccess(false);
    navigate("/reclamos-y-requerimientos");
  };

  const handleModalSuccessShow = () => {
    setDisabledButtonSend(true)
    const msgs = [];
    if (typeForm === "1") {
      const formPasoTres1 = [
        formData.productCode,
        formData.reasonCode,
        formData.subReasonCode,
        formData.troubleCode,
        formData.claimMessage,
      ];

      if (validateValuesStep2(formPasoTres1)) {
        msgs.push({
          text: MESSAGE_ERROR_REQUIRED,
        });
        setFocusValidation(true);
      }
      if (!formData.compliance) {
        msgs.push({
          text: "Pendiente dar conformidad del reclamo o queja ingresado.",
        });
      }
      if(!formData.captchaRef){
        msgs.push({
          text: "Pendiente completar correctamente la verificación.",
        });
      }
      if (!formData.policy) {
        msgs.push({
          text: MESSAGE_ERROR_REQUIRED_PROTECTION_DATA,
        });
      }

      if (msgs.length) {
        SetMessagesError(msgs);
        openSnackerrorFunction();
        setDisabledButtonSend(false)
      } else {
        sendInformacionClaim();
      }
    } else {
      const formPasoTres2 = [
        formData.productCode,
        formData.codeReasonRequest,
        formData.claimMessage,
      ];

      if (validateValuesStep2(formPasoTres2)) {
        msgs.push({
          text: MESSAGE_ERROR_REQUIRED,
        });
      }
      if (!formData.compliance) {
        msgs.push({
          text: MESSAGE_ERROR_PENDING_CONFORMITY,
        });
      }
      if (!formData.policy) {
        msgs.push({
          text: MESSAGE_ERROR_REQUIRED_PROTECTION_DATA,
        });
      }
      if(!formData.captchaRef){
        msgs.push({
          text: "Pendiente completar correctamente la verificación.",
        });
      }

      if (msgs.length) {
        SetMessagesError(msgs);
        openSnackerrorFunction();
        setDisabledButtonSend(false)
      } else {
        sendInformacionRequirement();
      }
    }
  };

  const validateValues = (array: any) => {
    const res = array.filter((x: string, index: number) => {
      if (index == 1) {
        if (array[0] !== null && array[0].name == 'DNI') {
          return x.length !== 8
        } else {
          return x === "" || x === null || x === undefined;
        }
      } else {
        return x === "" || x === null || x === undefined;
      }
    });

    return res.length > 0 ? true : false;
  };
  const validateValuesStep2 = (array: any) => {
    const res = array.filter((x: string) => {
      return x === "" || x === null || x === undefined;
    });

    return res.length > 0 ? true : false;
  };

  const openSnackerrorFunction = () => {
    setopenSnackError(true);
  };

  const showSnackbarError = () => {
    setDisabledButtonSend(false);
    SetMessagesError([{
      text: "Se presentó un error en el envío de la solicitud",
    }]);
    openSnackerrorFunction();
  }

  const sendInformacionClaim = () => {
    const documentRequest = new FormData();
    formData.dataFile.forEach((item) => {
      documentRequest.append("files", item);
    });
    if (formData.dataFile.length > 0) {
      apiUploadDocument(documentRequest)
        .then((data) => data.json())
        .then((response) => {
          const request = {
            typeDocument: formData.typeDocument.name,
            document: formData.document,
            name: formData.firstName,
            lastName: formData.lastName,
            bussinessName: formData.corporateName,
            codeCountry: formData.dialCode.name,
            phone: formData.phone,
            otherPhone: formData.otherPhone,
            email: formData.email,
            addressIsPeru: formData.localCustomer == 1,
            departament: formData.localCustomer == 1 ? formData.department.name : "",
            province: formData.localCustomer == 1 ? formData.province.name : "",
            district: formData.localCustomer == 1 ? formData.district.name : "",
            reference: formData.reference,
            addressName: formData.addressName,
            responseChannel: formData.responseChannel,
            isClient: formData.activeClient,
            dateEvent: formData.date != "" ? moment(formData.date, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
            amount: formData.amount,
            coin: formData.coin !== null ? formData.coin.name : "",
            product: {
              productCode: formData.productCode.value,
              productType: formData.productCode.name,
            },
            reason: {
              reasonCode: formData.reasonCode ? formData.reasonCode.value : "",
              reasonType: formData.reasonCode ? formData.reasonCode.name : "",
              reasonSBS: formData.reasonCode ? formData.reasonCode.valueSBS : ""
            },
            subreason: {
              subreasonCode: formData.subReasonCode !== null ? formData.subReasonCode.value : "",
              subreasonType: formData.subReasonCode !== null ? formData.subReasonCode.name : "",
              subreasonSBS: formData.subReasonCode !== null ? formData.subReasonCode.valueSBS : ""
            },
            claimMessage: formData.claimMessage,
            files: response.response,
            acceptedAccordance: true,
            readAndAcceptedPolicy: true,
          };
          apiPostSaveClaim(request)
            .then((response) => {
              if (response.status < 300) {
                response.json().then(data => {
                  setIdRCL(data.data.rcl_id)
                  setUrlDownload(data.urlPdfClaim)
                  setVisibleModalSuccess(true);
                  setDisabledButtonSend(false)
                })
              } else {
                showSnackbarError();
              }
            })
            .catch((error) => {
              showSnackbarError();
            });
        })
        .catch((error) => {
          showSnackbarError();
        });
    } else {
      const request = {
        typeDocument: formData.typeDocument.name,
        document: formData.document,
        name: formData.firstName,
        lastName: formData.lastName,
        bussinessName: formData.corporateName,
        codeCountry: formData.dialCode.name,
        phone: formData.phone,
        otherPhone: formData.otherPhone,
        email: formData.email,
        addressIsPeru: formData.localCustomer == 1,
        departament: formData.localCustomer == 1 ? formData.department.name : "",
        province: formData.localCustomer == 1 ? formData.province.name : "",
        district: formData.localCustomer == 1 ? formData.district.name : "",
        reference: formData.reference,
        addressName: formData.addressName,
        responseChannel: formData.responseChannel,
        isClient: formData.activeClient,
        dateEvent: formData.date != "" ? moment(formData.date, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
        amount: formData.amount,
        coin: formData.coin !== null ? formData.coin.name : "",
        product: {
          productCode: formData.productCode.value,
          productType: formData.productCode.name,
        },
        reason: {
          reasonCode: formData.reasonCode ? formData.reasonCode.value : "",
          reasonType: formData.reasonCode ? formData.reasonCode.name : "",
          reasonSBS: formData.reasonCode ? formData.reasonCode.valueSBS : ""
        },
        subreason: {
          subreasonCode: formData.subReasonCode !== null ? formData.subReasonCode.value : "",
          subreasonType: formData.subReasonCode !== null ? formData.subReasonCode.name : "",
          subreasonSBS: formData.subReasonCode !== null ? formData.subReasonCode.valueSBS : ""
        },
        claimMessage: formData.claimMessage,
        files: [],
        acceptedAccordance: true,
        readAndAcceptedPolicy: true,
      };
      apiPostSaveClaim(request)
        .then((response) => {
          if (response.status === 200) {
            response.json().then(data => {
              setIdRCL(data.data.rcl_id)
              setUrlDownload(data.urlPdfClaim)
              setVisibleModalSuccess(true);
              setDisabledButtonSend(false)
            }).catch((error) => {
              showSnackbarError();
            })
          } else {
            showSnackbarError();
          }
        })
        .catch((error) => {
          showSnackbarError();
        });
    }
  };

  const sendInformacionRequirement = () => {
    const documentRequest = new FormData();
    formData.dataFile.forEach((item) => {
      documentRequest.append("files", item);
    });
    if (formData.dataFile.length > 0) {
      apiUploadDocument(documentRequest)
        .then((data) => data.json())
        .then((response) => {
          const request = {
            typeDocument: formData.typeDocument.name,
            document: formData.document,
            name: formData.firstName,
            lastName: formData.lastName,
            bussinessName: formData.corporateName,
            codeCountry: formData.dialCode.name,
            phone: formData.phone,
            otherPhone: formData.otherPhone,
            email: formData.email,
            addressIsPeru: formData.localCustomer == 1,
            departament: formData.localCustomer == 1 ? formData.department.name : "",
            province: formData.localCustomer == 1 ? formData.province.name : "",
            district: formData.localCustomer == 1 ? formData.district.name : "",
            reference: formData.reference,
            addressName: formData.addressName,
            responseChannel: formData.responseChannel,
            isClient: formData.activeClient,
            dateEvent: formData.date !== '' ? moment(formData.date, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
            product: {
              productCode: formData.productCode.value,
              productType: formData.productCode.name,
            },
            typeRequirements: {
              requirementCode: formData.codeReasonRequest.value,
              typeRequirement: formData.codeReasonRequest.name,
            },
            requestMessage: formData.claimMessage,
            files: response.response,
            acceptedAccordance: true,
            readAndAcceptedPolicy: true,
          };
          apiPostSaveDemand(request)
            .then((response) => {
              if (response.status < 300) {
                response.json().then(data => {
                  setIdREQ(data.data.req_id)
                  setVisibleModalSuccess(true);
                  setDisabledButtonSend(false);
                })
              }
            })
            .catch((error) => {
              setDisabledButtonSend(false)
            });
        })
        .catch((error) => {
            showSnackbarError();
        });
    } else {
      const request = {
        typeDocument: formData.typeDocument.name,
        document: formData.document,
        name: formData.firstName,
        lastName: formData.lastName,
        bussinessName: formData.corporateName,
        codeCountry: formData.dialCode.name,
        phone: formData.phone,
        otherPhone: formData.otherPhone,
        email: formData.email,
        addressIsPeru: formData.localCustomer == 1,
        departament: formData.localCustomer == 1 ? formData.department.name : "",
        province: formData.localCustomer == 1 ? formData.province.name : "",
        district: formData.localCustomer == 1 ? formData.district.name : "",
        reference: formData.reference,
        addressName: formData.addressName,
        responseChannel: formData.responseChannel,
        isClient: formData.activeClient,
        dateEvent: formData.date !== '' ? moment(formData.date, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
        product: {
          productCode: formData.productCode.value,
          productType: formData.productCode.name,
        },
        typeRequirements: {
          requirementCode: formData.codeReasonRequest.value,
          typeRequirement: formData.codeReasonRequest.name,
        },
        requestMessage: formData.claimMessage,
        files: [],
        acceptedAccordance: true,
        readAndAcceptedPolicy: true,
      };
      apiPostSaveDemand(request)
        .then((response) => {
          if (response.status === 200) {
            response.json().then(data => {
              setIdREQ(data.data.req_id)
              setVisibleModalSuccess(true);
              setDisabledButtonSend(false)
            })
          } else {
            showSnackbarError();
          }
        })
        .catch((error) => {
          showSnackbarError();
        });
    }
  };

  return (
    <div>
      <BannerComplaintsBook></BannerComplaintsBook>
      <div className="container">
        <SurgirStepper bgcolor="#EC0000" height={4} step={step} total={total} />

        {step === 1 && (
          <>
            <FormPersonalData
              formData={formData}
              setformData={setformData}
              showValidation={focusValidation}
              setShowValidation={setFocusValidation}
            ></FormPersonalData>
          </>
        )}

        {step === 2 && (
          <>
            <FormContactInformation
              formData={formData}
              setformData={setformData}
              showValidation={focusValidation}
              setShowValidation={setFocusValidation}
            ></FormContactInformation>
          </>
        )}
        {step === 3 && (
          <>
            <FormInformationComplaints
              formData={formData}
              setformData={setformData}
              showValidation={focusValidation}
              setShowValidation={setFocusValidation}
            ></FormInformationComplaints>
          </>
        )}
      </div>

      <SurgirModal
        show={visibleModalSuccess}
        handleClose={handleModalSuccessClose}
        formData={formData}
        rcl={idRCL}
        req={idREQ}
        urlDownload={urlDownload}
        handleReset={() => {
          setVisibleModalSuccess(false);
          navigate("/reclamos-y-requerimientos");
        }}
      />

      <div className="container-button-form">
        <SurgirButton
          variant={"outline"}
          color="error"
          border={"1px solid #EC0000"}
          radius="24px"
          width={"100%"}
          height={"48px"}
          colorText={"#ec0000"}
          onClick={regresar}
        >
          Regresar
        </SurgirButton>

        {step !== 3 && (
          <SurgirButton
            variant="danger"
            radius="24px"
            width={"100%"}
            height={"48px"}
            onClick={continuar}
          >
            Continuar
          </SurgirButton>
        )}

        {step === 3 && (
          <SurgirButton
            variant="danger"
            radius="24px"
            width={"100%"}
            height={"48px"}
            onClick={handleModalSuccessShow}
            disabled={disabledButtonSend}
          >
            Enviar
          </SurgirButton>
        )}

        <SurgirSnackbarContentWrapper
          open={openSnackError}
          onopen={setopenSnackError}
          messages={messagesError}
        ></SurgirSnackbarContentWrapper>
      </div>

    </div>
  );
}

export default FormComplaintsBook