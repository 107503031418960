import React from 'react';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import AdminRoutes from './routes/AdminRoutes';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
  <React.StrictMode>
    <AdminRoutes />
  </React.StrictMode>
);

reportWebVitals();
