import React from "react";
import { useEffect, useState } from "react";
import { apiGetTypeDocuments } from "service/apiEndPoint";
import { optionalInputMessage, subTitleDataStepTwo } from "views/complaints-book/ComplaintsBook.fixture";
import { Col, Row } from "react-bootstrap";
import SurgirDropDown from "components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirTextField } from "components/ui/SurgirTextField/SurgirTextField";
import { PATTER_ALPH } from "utils/constants";
import './FormPersonalData.scss'
import { patternTypeDocument, validateDocumentNumber, validateFieldObject, validateFieldString } from "utils/ValidateField";


interface FormPersonalDataProps {
  formData: {
    typeDocument: string | any;
    document: string | any;
    firstName: string | any;
    lastName: string | any;
    corporateName: string | any;
  } ,
  setformData: any,
  showValidation: any,
  setShowValidation: any,
} 

const FormPersonalData: React.FC<FormPersonalDataProps> = ({
  formData,
  setformData,
}) => {
  const [listDocumentType, setListDocumentType] = useState([]);

  const [showErrorDocumento, setShowErrorDocumento] = useState(false);
  const [typeDocument, setTypeDocument] = useState(null);
  const [typeInput, setTypeInput] = useState("number");

  const handleClickDocument = () => {
    if (typeDocument == null) {
      setShowErrorDocumento(true);
    }
  };

  useEffect(() => {
    formData.typeDocument !== "" && setTypeDocument(formData.typeDocument);
    apiGetTypeDocuments({codeForm:'ComplaintsBook'})
      .then((response) => {
        return response.json();
      })
      .then((data) =>
        setListDocumentType(
          data.data.map((item: { codeTypeDoc: string, documentType: string}) => {
            return {
              value: item.codeTypeDoc,
              name: item.documentType,
            };
          })
        )
      )
      .catch((err) => console.log(err));

    return () => {};
  }, []);

  const handleTypeDocument = (e: any) => {
    setformData({ ...formData, document: "" });
    setShowErrorDocumento(false);
    setTypeDocument(e);
    setformData((prev: any) => ({ ...prev, typeDocument: e }));
    validateFieldObject(e);
    if (e === "02") {
      setTypeInput("text");
    }
    if (e === "01" || e === "03" || e === "04") {
      setTypeInput("number");
    }
  };

  return (
    <div>
      <div className="container" style={{ padding: 0 }} id={"cont-princ"}>
        <div className="container-form-personal">
          <form>
          <span className="sub-title-span">{subTitleDataStepTwo}</span>

            <div className="container-static-personal">
              <Row style={{marginRight:0, marginLeft:0, width:'100%'}}>
                <Col style={{padding:'0px 10px 0px 0px'}} >
                  <SurgirDropDown
                    id="selectTypeDocument"
                    name="select-type-document"
                    hint={"Tipo de documento"}
                    dataSource={listDocumentType}
                    error={
                      showErrorDocumento &&
                      validateFieldObject(formData.typeDocument).error
                    }
                    messageError={
                      validateFieldObject(formData.typeDocument).message
                    }
                    value={formData.typeDocument}
                    onChange={handleTypeDocument}
                  />
                </Col>
                <Col style={{padding:'0px 0px 0px 10px'}}>
                  <SurgirTextField
                    id="txtNumberDocument"
                    name="input-number-document"
                    value={formData.document}
                    dependValue={formData.typeDocument}
                    validateError={validateDocumentNumber}
                    label="Número de documento"
                    fullWidth={true}
                    variant="filled"
                    type={"text"}
                    setValue={(e) => {
                      setformData({ ...formData, document: e });
                    }}
                    pattern={patternTypeDocument(formData.typeDocument)}
                    disabled={
                      formData.typeDocument == undefined ||
                      formData.typeDocument == null ||
                      formData.typeDocument == ""
                    }
                  />
                </Col>
              </Row>

              <SurgirTextField
                id="txtName"
                name="input-name"
                value={formData.firstName}
                label="Nombres"
                fullWidth={true}
                variant="filled"
                type={"text"}
                validateError={validateFieldString}
                pattern={PATTER_ALPH}
                setValue={(e) => {
                  setformData({ ...formData, firstName: e });
                }}
              />

              <SurgirTextField
                id="txtLastName"
                name="input-last-name"
                value={formData.lastName}
                label="Apellidos"
                fullWidth={true}
                variant="filled"
                validateError={validateFieldString}
                pattern={PATTER_ALPH}
                setValue={(e) => {
                  setformData({ ...formData, lastName: e });
                }}
              />

              <SurgirTextField
                id="txtBusinessName"
                name="input-business-name"
                value={formData.corporateName}
                label="Razón social"
                fullWidth={true}
                variant="filled"
                validateError={() => {
                  return { error: false, messageError: '' };
                }}
                setValue={(e) => {
                  setformData({ ...formData, corporateName: e });
                }}
              />
            </div>

            <div className="div-razon-social">
              <span>{optionalInputMessage}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FormPersonalData