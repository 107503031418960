import React from "react";
import "./SurgirSlideSecondary.scss";
import SurgirButton from "../SurgirButton/SurgirButton";

interface SurgirSlideSecondaryProps {
  image: string;
  title: string;
  textButton: string;
  eventButton: any
}

const SurgirSlideSecondary: React.FC<SurgirSlideSecondaryProps> = ({
  image,
  title,
  textButton,
  eventButton
}) => {

  return (
    <div className="slide-secondary-container">
      <div  className="slide-secondary">
        <div className="slide-secondary__column">
          <img src={`images/home/${image}`} alt={image} />
        </div>
        <div className="slide-secondary__content">
          <div className="slide-secondary__content__title">
            {title}
          </div>
          <div >
            <SurgirButton
              onClick={eventButton}
              variant="danger"
              radius={"50px"}
              width={"177px"}
              height={"48px"}
              marginBottom={"0px"}
              marginTop={"0px"}
              fontFamily={"'Str-Microtext-Bold', sans-serif"}
            >
              {textButton}
            </SurgirButton>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SurgirSlideSecondary;