import { BASE_URL, VERSION } from '../utils/constants'
let headers = new Headers({
  'Content-Type': 'application/json'

})

export function getRequest(URL, config) {
  let request = ''
  if (config.params !== undefined) {
    request = new Request(BASE_URL + VERSION + URL + '?'+(new URLSearchParams(config.params)).toString(),{headers: headers, method:'GET',mode: 'cors',credentials: 'same-origin'})
  }else{
    request = new Request(BASE_URL + VERSION + URL,{headers: headers, method:'GET',mode: 'cors',credentials: 'same-origin'})
  }
  return fetch(request)
}

export function postRequest(URL, payload, isForm = false) {
  let myInit = {};
  let request;
  if (isForm) {
    myInit = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: payload,
    }
    request = new Request(BASE_URL + VERSION + URL, myInit)
    return fetch(request)
  } else {
    myInit = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      headers:headers,
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(payload),
    }
    request = new Request(BASE_URL + VERSION + URL, myInit)
    return fetch(request)
  }
}

export function patchRequest(URL, payload) {
  let request = new Request()
  request.headers = headers
  request.method = 'POST'
  request.url = BASE_URL + VERSION + URL + '/' + payload.id
  request.body = payload
  return fetch(request)
}
