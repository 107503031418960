import React, { useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import BannerComplaintsBook from "./components/BannerComplaintsBook";
import { SurgirCardRadioGroup } from "components/ui/SurgirCardRadioGroup/SurgirCardRadioGroup";
import { GotoFrequentQuestionsSubTitle1, GotoFrequentQuestionsSubTitle2, GotoFrequentQuestionsText1, GotoFrequentQuestionsText2, GotoFrequentQuestionsTitle1, GotoFrequentQuestionsTitle2, typeComplaints } from "./ComplaintsBook.fixture";
import styled from "styled-components";
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import "./ComplaintsBook.scss";


export const CardDoubts = styled.div`
  height: 212px;
  background: #f8fbfc;
  border: 1px solid #cedee7;
  border-radius: 16px;
  padding: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  @media (min-width: 1024px) {
    width: 552px;
    height: 164px;
    padding: 16px;
  }
`;

const ComplaintsBook: React.FC = () => {
  const navigate = useNavigate();

  const [select, setSelect] = useState({
    id: 1,
    name: "Tengo un reclamo o queja",
  });

  const continuar = () => {
    sessionStorage.setItem("typeForm", JSON.stringify(select.id));
    const options = {
      state: { id: select }, 
    };
    navigate("/reclamos-y-requerimientos/formulario", options);
  };
  const handleClick = () => {
    continuar();
  };

  const GotoFrequentQuestions = () => {
    navigate("/preguntas-frecuentes");
  };
  const GotoProcedimientos = () => {
    navigate("/transparencia");
  };

  return (
    <div>
      <div>
        <BannerComplaintsBook></BannerComplaintsBook>

        <SurgirCardRadioGroup
          selected={setSelect}
          radioData={typeComplaints}
          defaultValue={"1"}
        ></SurgirCardRadioGroup>

        <div className="container-button">
          <SurgirButton
            variant="danger"
            radius="24px"
            width={"100%"}
            height={"48px"}
            onClick={handleClick}
          >
            Continuar
          </SurgirButton>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <CardDoubts>
            <div className="title-card">{GotoFrequentQuestionsTitle1}</div>
            <div className="sub-title-card ">
              {GotoFrequentQuestionsSubTitle1}
            </div>
            <button
              className="link-card"
              onClick={GotoProcedimientos}
              style={{ cursor: 'pointer', border: 'none', background: 'none', padding: 0 }}
            >
              {GotoFrequentQuestionsText1}
            </button>
          </CardDoubts>
          <CardDoubts>
            <div className="title-card ">{GotoFrequentQuestionsTitle2}</div>
            <div className="sub-title-card ">
              {GotoFrequentQuestionsSubTitle2}
            </div>
            <button
              className="link-card"
              onClick={GotoFrequentQuestions}
              style={{ cursor: 'pointer', border: 'none', background: 'none', padding: 0 }}
            >
              {GotoFrequentQuestionsText2}
            </button>
          </CardDoubts>
        </div>

        <hr />
      </div>

    </div>
  );
}

export default ComplaintsBook