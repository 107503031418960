import React, { useState } from 'react';
import './AccordionIcon.scss'; 
import ChevronDownGray from './../../../../assets/icons/chevron_downgray.svg';
// import ChevronUpGray from './../../../assets/icons/chevron_upgray.svg';
import ChevronUpGray from './../../../../assets/icons/chevron_upgray.svg';
const AccordionIcon = ({ items }: any) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item: any, index: any) => (
        <div className="accordion-item" key={index}>
          <div className="accordion-header" onClick={() => toggleAccordion(index)}>
          <img src={`images/percentage-credit/coverage/${item.icon}.svg`}className="accordion-image"  alt="super seguro" />
            <div className="accordion-title">{item.title}</div>
            <img
            src={activeIndex === index ?  ChevronUpGray  : ChevronDownGray}
            className={`accordion-icon ${activeIndex === index ? 'expanded' : ''}`}
            alt="Flecha hacia arriba"
            onClick={() => toggleAccordion(index)}
          />
          </div>
          <p className={`accordion-description ${activeIndex === index ? 'expanded' : ''}`}>
            {item.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AccordionIcon;
