import { Operation } from "domain/models/SurgirFixedDeposit"

export const STEPS_DPFF: Operation[]  = [
  { 
    title: 'Completa nuestro ',
    description: 'Ingresa tus datos personales y nos contactaremos contigo.',
    url:"/abre-tu-deposito-a-plazo-fijo",
    link: 'formulario' },
  { 
    title: 'Descarga la ',
    description: 'Un ejecutivo te llamará y te guiará para completar la solicitud.',
    link: 'Solicitud de Apertura'
  },
  { 
    title: 'Deposita el capital para tu Depósito a Plazo Fijo',
    description: 'Realiza la transferencia a nuestras cuentas recaudadoras SURGIR y realizaremos la apertura.'
  },
]