export  const next = (sliderRef) => {
  /* istanbul ignore else */
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
};

export const previous = (sliderRef) => {
  /* istanbul ignore else */
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
};