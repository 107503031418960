import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as IcOkSuccess } from '../../../assets/images/ic_ok_success.svg'
import { ReactComponent as DownloadLogo } from '../../../assets/images/Icons_descargar.svg'
import { ReactComponent as IcCloseBanner } from '../../../assets/images/ic_close_banner.svg';

interface SurgirModalProps {
    show: boolean;
    handleClose: () => void;
    handleShow?: () => void;
    handleReset: () => void;
    formData: any;
    rcl: number;
    req: number;
    urlDownload: string;
}

const ContainerBtnModal = styled.div`
    @media (min-width: 768px){
        margin-top: 0px;
    }
`;

const ContentParrafo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 24px;

    .parrafo{
        width: 296px;
        text-align: center;
    }
`;

const ContentBody = styled.div`
    text-align: center;
    svg{
        width: 73px;
        height: 73px;
        margin-bottom: 23px;
    }

    @media (min-width: 768px){
        svg{
            width: 93px;
            height: 93px;
            margin-bottom: 25px;
        }
    }
`;

const SurgirModal: React.FC<SurgirModalProps> = ({ show, handleClose, handleShow, handleReset, formData, rcl, req, urlDownload }) => {

    const typeForm = sessionStorage.getItem("typeForm");

    const getYearActual = (): number => {
        const today = new Date();
        const yearActual = today.getFullYear();
        return yearActual;
      };
    return (

        <Modal className="modalsuccess" show={show} onHide={handleClose} backdrop="static" >

            <Modal.Body>
                <div className="container-btn-close">
                    <Button variant="link" onClick={handleClose}> <IcCloseBanner /> </Button>
                </div>
                <ContentBody>

                    <IcOkSuccess />
                    <br />

                    <div className="subtitle_form">{typeForm === "1" ? ("Se registró con éxito tu reclamo o queja") : ("Se registró con éxito tu requerimiento")}</div>

                    <ContentParrafo>
                        <div className="parrafo">
                            En breve recibirás en <strong>{formData.email}</strong> una copia de tu {typeForm === "1" ? ("reclamo o queja") : ("requerimiento")} identificado con el código <strong>{typeForm === "1" ? `RCL N° ${rcl}-${getYearActual()}` : `REQ N° ${req}-${getYearActual()}`}</strong>
                        </div>
                    </ContentParrafo>

                    {typeForm === "1" ?

                        <a href={urlDownload} style={{ color: 'red', display: 'flex', alignContent: 'center', flexDirection: 'row', justifyContent: 'center' }}> <DownloadLogo style={{ width: 24, height: 24 }} /> Descarga tu copia aquí </a>

                        : null}

                    <ContainerBtnModal style={{ marginTop: typeForm === "1" ? 8 : 50 }}>
                        <Button style={{ width: '99%', borderRadius: 32, fontFamily: "Str-Microtext-Bold", fontSize: '16', height: 48, textAlign: "center" }}
                            variant="danger" onClick={handleReset}>¡Listo! </Button>
                    </ContainerBtnModal>

                </ContentBody>
            </Modal.Body>

        </Modal>

    );
}

export { SurgirModal };

