import React from "react";
import "./SurgirCardProduct.scss";
import Arrow from '../../../assets/images/arrow-left.svg'

interface SurgirCardProductProps {
  title: string;
  text: string;
  className: string;
  url: string;
  alt: any;
}

const SurgirCardProduct: React.FC<SurgirCardProductProps> = ({
  title,
  alt,
  text,
  className,
  url
}) => {
  return (
    <div className={className}>

      <div className="card-producto-gradient" >
        <div className="titulo-card-producto" >
          {title}
        </div>

        <div className="parrafow descripcion-producto">
          {text}
        </div>

        <div>
          <a href={url} className="home-arrow" >
            Conoce más{"  "}
          </a><img alt="" src={Arrow}  />
        </div>
      </div>
    </div>
  )
};

export default SurgirCardProduct;