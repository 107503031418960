import React from 'react';
import './CardLink.scss'
import { ButtonBase } from '@mui/material';
import styled from 'styled-components';

interface SurgirCardLinkProps {
  cardLink: {
    title: string;
    icon: string;
    isNew: boolean;
    link: string;
  }
}

const ImageButton = styled(ButtonBase)`
  height: auto;
  width: 100%;
  padding: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: left !important;
  align-items: flex-start !important;
  gap: 12px !important;


  &:hover .card-link__hover-image {
    display: block;
    transform: translateX(280%);
    z-index: 2;
  }

  && .MuiTouchRipple-child {
    background-color: gray !important;
  }
`;

const HoverImage = styled.img`
position: relative;
z-index: -1;
transform: translateX(0);
transition: transform 0.5s ease-in-out;
right: 50%;

`

const SurgirCardLink: React.FC<SurgirCardLinkProps> = ({
  cardLink
}) => {

  const handleClick = () => {
    window.open(cardLink.link, '_blank');
  };

  return (
    <ImageButton
      className='card-link'
      style={{
        backgroundColor: cardLink.isNew ? '#EC0000' : '#FFFFFF',
        wordBreak: 'break-word'
      }}
      focusRipple
      onClick={handleClick}
    >
      {
        cardLink.isNew && <span className='card-link__alert'>¡Nuevo!</span>
      }

      <div style={{ display: 'flex', position: 'relative', zIndex: '1' }}>
        <div className='card-link__icon'>
          <img src={`icons/home/${cardLink.icon}`} alt="" />
        </div>
        <HoverImage
          className='card-link__hover-image'
          src='icons/home/lines-card.svg'
          alt='lines'
        />
      </div>

      <div
        className='card-link__text'
        style={{
          color: cardLink.isNew ? '#FFFFFF' : '#222222'
        }}
      >
        {cardLink.title}
      </div>

    </ImageButton>
  );
};

export default SurgirCardLink;