import { useEffect } from 'react';

interface Props {
  event: string;
}

declare global {
  interface Window {
    fbq: string;
  }
}

const MetaPixel = (props: Props) => {  
  useEffect(() => {
    if (['Lead','CompleteRegistration'].includes(props.event)) {
      let scriptHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1002303161563045');
      fbq('track', 'PageView');
      fbq('track', '${ props.event }');
      `;

      document.getElementById('meta-pixel-script')!.innerHTML = scriptHTML;
    }
  }, [props.event]);

  return (
    <>
    </>
  );
};

export default MetaPixel;
