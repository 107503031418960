export const getUbigeo = () => { return 'findUbigeo' }
export const getTypeDocument = () => { return 'findTypeDocument' }
export const getCoinType = () => { return 'findCoinType/all' }
export const getListKeybord = () => { return 'search-web' }
export const getListPrincipal = () => { return 'search-web/principal' }
export const getTypeDpfProduct = () => { return 'dpf-product' }
export const getCreditProductType = () => { return 'credit-product' }
export const getFindReasoncontact = () => { return 'findReasoncontact' }
export const sendContactUsForm = () => { return 'contactUsForm/create' }
export const getFindUbigeoDep = () => { return 'findUbiGeo/dep' }
export const postFindUbigeo = () => { return 'findUbiGeo' }
export const postSaveClaim = () => { return 'complainstBook/' }
export const postSaveDemand = () => { return 'requirement' }
export const postSaveFormRequestCredit = () => { return 'asset-form' }
export const postSaveDpfForm = () => { return 'dpf-form' }
export const getDialCode = () => { return 'findDialCode/all' }
export const getProduct = () => { return 'product' }
export const routeGetProblems = (param) => { return `reason/${param.productCode}` }
export const routeGetReasons = (param) => { return `claim-subreason/${param.reasonCode}` }
export const routeGetRequirement = () => { return 'requirement-type' }
export const routeUploadDocument = () => {return `storage/uploadFiles`}
export const getDNI = () => { return 'cash-payment' }

export const getTypeCredit = () => { return 'credit-product' }
export const getTypeInsurance = () => { return 'type-insurance-desgravamen' }
export const getTypeSuperInsurance = () => { return 'super-insurance-option' }
export const sendSaveSimulator = () => {return 'credit-simulator'}

export const getTerms = () => `dpf-term`;
export const postSaveDpfSolicitudeForm = () => { return 'dpf-solicitude' }
export const getFindDpfSolicitudeForm = () => { return 'dpf-solicitude' }

export const getPromotions = () => {return `promotion`}