import React from 'react';
import './SliderCustom.scss'
import SurgirButton from 'components/ui/SurgirButton/SurgirButton';
import styled from 'styled-components';

interface SliderCustomProps {
  slideCustom: {
    imageURL: string;
    title: string;
    description: string;
    textButton: string;
    eventButton?: any;
    colorText: string;
    isShade: boolean;
    textRegular: string;
    imageSmall: string;
    ariaLabel?: string;
    typeButtonBanner?: string;
  }
}

const ContainerSlider = styled.div<{ backgroundImageUrl: string }>`
  background-image: url(${props => props.backgroundImageUrl}) !important;
`;

const SliderCustom: React.FC<SliderCustomProps> = ({
  slideCustom
}) => {

  const isSmall = window.matchMedia('(min-width: 320px) and (max-width: 767px)')?.matches;

  const backgroundImageUrl = isSmall ? `images/home/sliders/${slideCustom.imageSmall}` : `images/home/sliders/${slideCustom.imageURL}`;

  let backgroundPositionX;

  if (slideCustom.isShade) {
    backgroundPositionX = isSmall ? 'center' : 'right';
  } else {
    backgroundPositionX = isSmall ? 'center' : '80%';
  }

  let backgroundStyle;

  if (slideCustom.isShade) {
    if (isSmall) {
      backgroundStyle = 'linear-gradient(180deg, rgba(34, 34, 34, 0.6) 50%, rgba(34, 34, 34, 0) 100%)';
    } else {
      backgroundStyle = 'linear-gradient(90deg, rgba(34, 34, 34, 0.8) 65.62%, rgba(34, 34, 34, 0) 100%)';
    }
  } else {
    backgroundStyle = 'none';
  }

  return (
    <ContainerSlider
      aria-label={slideCustom.ariaLabel}
      data-testid="container-slider"
      className="slider-custom__principal"
      backgroundImageUrl={backgroundImageUrl}
      style={{
        backgroundPositionX: backgroundPositionX
      }}
    >
      <div className='slider-custom'>
        <div 
          className='container-text'
          style={{
            color: slideCustom.colorText,
            background: backgroundStyle,
          }}
        >
          <div className='slider-custom__content'>
            <div
              className='slider-custom__content__title'
            >
              {
                slideCustom.textRegular ? slideCustom.title.split(slideCustom.textRegular).map((part, i) => (
                  <React.Fragment key={part}>
                    {i > 0 &&
                      <span className="span-regular">{slideCustom.textRegular}</span>
                    }
                    {part}
                  </React.Fragment>
                )) : (
                  slideCustom.title
                )
              }

            </div>
           {  slideCustom.description && <div className='slider-custom__content__description'>
             {slideCustom.description}
           </div>
           }
           {
            slideCustom.textButton && <div>
            <SurgirButton
              onClick={slideCustom.eventButton}
              variant="danger"
              radius={"50px"}
              width={"auto"}
              height={"48px"}
              marginBottom={"0px"}
              marginTop={"32px"}
              fontFamily={"'Str-Microtext-Bold', sans-serif"}
              padding={"0px 26px"}
              color={slideCustom.typeButtonBanner === 'secondary' ? '#FFFFFF' : '#ec0000'}
              colorText={slideCustom.typeButtonBanner === 'secondary' ? '#ec0000' : '#FFFFFF'}
            >
              {slideCustom.textButton}
            </SurgirButton>
          </div>
           }
            
          </div>
        </div>
        <div>

        </div>
      </div>
    </ContainerSlider>

  );
};

export default SliderCustom;