import { Box } from '@mui/material';
import SurgirCardRedirect from 'components/ui/SurgirCardRedirect/SurgirCardRedirect';
import SurgirHeadline from 'components/ui/SurgirHeadline/SurgirHeadline';
import SurgirSlideSantander from 'components/ui/SurgirSlideSantander/SurgirSlideSantander';
import SurgirSlideSecondary from 'components/ui/SurgirSlideSecondary/SurgirSlideSecondary';
import SurgirSliders from 'components/ui/SurgirSliders/SurgirSliders';
import LinkRoutes from 'constants/LinkRoutes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SurgirButton from '../../components/ui/SurgirButton/SurgirButton';
import { ReactComponent as HandIconTab } from './../../assets/icons/home/tabs/hand.svg';
import { ReactComponent as PigIconTab } from './../../assets/icons/home/tabs/pig.svg';
import { ReactComponent as ShieldIconTab } from './../../assets/icons/home/tabs/shield.svg';
import { CARDS_LINK, CARDS_YOUTUBE, ITEMS_ABOUT, PRODUCTS_AHORROS, PRODUCTS_CREDITOS, PRODUCTS_SEGUROS } from './Home.fixture';
import './Home.scss';
import SurgirCardLink from './components/CardLink/CardLink';
import SurgirTabsHome from 'components/ui/SurgirTabsHome/SurgirTabsHome';
import { createLink } from "utils/urls";


const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

const ThreeColumnGrid = ({ children }: any) => {
  return (
    <GridContainer className='grid-container-tabs'>
      {children}
    </GridContainer>
  );
};

const downloadCertificado = () => {
  createLink(process.env.REACT_APP_DOCS_CERTIFICADO_FUNCIONAMIENTO ?? "", "_blank");
};

const redirectContactanos = () => {
  window.open(LinkRoutes.contact, '_blank')
}

const redirectRequestCredit = () => {
  window.open(LinkRoutes.requestCredit, '_blank')
}

const redirectGoogleForms = () => {
  window.open('https://forms.office.com/e/2ZE0EV18ZB', '_blank')
}

const redirectChannels = () => {
  window.open(LinkRoutes.channels, '_blank')
}

const redirectPromotionsHistory = () => {
  window.open(LinkRoutes.promotionHistory, '_blank')
}

const redirectDPF = () => {
  window.open(LinkRoutes.dpf, '_blank')
}

const TABS_PRODUCTS = [
  {
    titleTab: 'Ahorros',
    iconTab: (<PigIconTab />),
    contentTab: (
      <ThreeColumnGrid>
        {
          PRODUCTS_AHORROS.map((product) => {
            return (
              <SurgirCardRedirect
                key={product.title}
                image={product.hero}
                title={product.title}
                description={product.description}
                alt={product.alt}
                link={product.href}
                textStrong={product.textStrong}
              />
            )
          })
        }
      </ThreeColumnGrid>
    )
  },
  {
    titleTab: 'Créditos',
    iconTab: (<HandIconTab />),
    contentTab: (
      <ThreeColumnGrid>
        {
          PRODUCTS_CREDITOS.map((product) => {
            return (
              <SurgirCardRedirect
                key={product.title}
                image={product.hero}
                title={product.title}
                description={product.description}
                alt={product.alt}
                link={product.href}
              />
            )
          })
        }
      </ThreeColumnGrid>
    )
  },
  {
    titleTab: 'Seguros',
    iconTab: (<ShieldIconTab />),
    contentTab: (
      <ThreeColumnGrid>
        {
          PRODUCTS_SEGUROS.map((product) => {
            return (
              <SurgirCardRedirect
                key={product.title}
                image={product.hero}
                title={product.title}
                description={product.description}
                alt={product.alt}
                link={product.href}
              />
            )
          })
        }
      </ThreeColumnGrid>
    )
  },
]

const SLIDERS_HOME = [
  {
    imageURL: 'navidad-2024.png',
    title: 'Una Navidad de Oportunidades',
    description: 'Aprovecha las fiestas y cierra el año a lo grande',
    colorText: '#FFFFFF',
    textButton: 'Solicitar ahora',
    isShade: true,
    eventButton: (redirectRequestCredit),
    imageSmall: 'navidad-2024-small.png',
    ariaLabel: 'Alt_ Banner Web Home SURGIR Una Navidad de Oportunidades Desembolso Crédito'
  },
  {
    imageURL: 'regalos-2024.png',
    title: 'SURGIR pone los regalos',
    description: 'Esta Navidad adelanta tu cuota y equipa tu casa',
    colorText: '#FFFFFF',
    isShade: false,
    eventButton: (redirectRequestCredit),
    imageSmall: 'regalos-2024-small.png',
    ariaLabel: 'Alt: Los regalos los ponemos nosotros Canales de Pago Cuota Crédito SURGIR'
  },
  {
    imageURL: 'dpf-noviembre.png',
    title: '¡Tenemos el Regalo Perfecto para ti!',
    description: 'Esta Navidad haz crecer tu dinero con nuestro Depósito a Plazo Fijo',
    colorText: '#FFFFFF',
    isShade: false,
    textButton: 'Solicítalo ahora',
    eventButton: (redirectDPF),
    imageSmall: 'dpf-noviembre-small.png',
    ariaLabel: 'Alt_Regalo Navidad con nuestra súper tasa ahorros inversión SURGIR depósito a plazo fijo Banner home'
  },
  {
    imageURL: 'credito-prospera.png',
    title: 'Crédito Prospera',
    description: 'Crece tu negocio tan rápido como tus ganas de SURGIR',
    colorText: '#222222',
    textButton: 'Solicítalo ahora',
    isShade: false,
    eventButton: (redirectRequestCredit),
    imageSmall: 'credito-prospera-small.png',
    ariaLabel: 'Alt_Crédito Prospera préstamo individual SURGIR'
  },
  {
    imageURL: 'canales-digitales.png',
    title: '¡Que nada interrumpa tu día!',
    description: 'Paga tu cuota a través de los canales digitales',
    colorText: '#FFFFFF',
    textButton: 'Canales de pago',
    isShade: true,
    eventButton: (redirectChannels),
    imageSmall: 'canales-digitales-small.png',
    ariaLabel: 'Alt_Canales de pago digitales BCP Yape Interbank IBK BanBif SURGIR'
  },
]

const Home: React.FC = () => {

  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const isMedium = useMediaQuery('(min-width: 768px) and (max-width: 1199px)');
  const isSmall = useMediaQuery('(min-width: 320px) and (max-width: 767px)');

  const redirectAboutSurgir = () => {
    window.open(LinkRoutes.aboutUs, '_blank', "noopener");
  };

  const redirectChannelYoutube = () => {
    window.open('https://www.youtube.com/@SURGIR-SantanderMicrofinanzas', '_blank', "noopener");
  }

  const [youtubeSrc, setYoutubeSrc] = useState<string>(CARDS_YOUTUBE[0].idVideo);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const handleButtonYoutube = (newSrc: string, index: number) => {
    setYoutubeSrc(newSrc);
    setActiveButtonIndex(index);
  };

  return (
    <>
      <div className='sliders-home' style={{ marginTop: '32px' }}>
        <SurgirSliders
          sliders={SLIDERS_HOME}
        />
      </div>

      <div className='container-std'>

        <div className='home-cards'>
          <SurgirHeadline
            title='¿Cómo te podemos ayudar?'
            subtitle='SURGIR a tu disposición'
          />
          <Box
            className='home-cards__box'
          >
            {
              CARDS_LINK.map((card: any) => {
                return (
                  <SurgirCardLink
                    key={card.title}
                    cardLink={card}
                  />
                )
              })
            }
          </Box>
        </div>

      </div>

      <div>
        <div className='home-products'>
          <SurgirHeadline
            title='Conoce los productos que tenemos para ti'
          />

          <SurgirTabsHome
            tabs={TABS_PRODUCTS}
          />

        </div>
      </div>

      <div className='container-std'>
        <div className='home-about'>
          <div className='home-about__title-responsive'>
            <SurgirHeadline
              title='Apasionados por acelerar el progreso de los que hacen SURGIR al país'
            />
          </div>
          <div className='home-about__main'>
            <div className='home-about__main__hero'>
              <img src="images/home/hero-about.png" alt="" />
            </div>
            <div className='home-about__main__content'>
              <div className='home-about__main__content__title'>
                <SurgirHeadline
                  title='Apasionados por acelerar el progreso de los que hacen SURGIR al país'
                  align='left'
                />
              </div>
              <div className='home-about__main__content__list'>
                <ul>
                  {
                    ITEMS_ABOUT.map((item: { title: string, description: string, icon: string }) => {
                      return (
                        <li key={item.icon}>
                          <div className='home-about__main__content__list__icon'>
                            <img src={`icons/home/${item.icon}`} alt={item.icon} />
                          </div>
                          <div className='home-about__main__content__list__description'>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                          </div>
                        </li>
                      )
                    })
                  }

                </ul>
              </div>

              <div className='button-about'>
                <SurgirButton
                  onClick={redirectAboutSurgir}
                  variant="danger"
                  radius="50px"
                  width={"207px"}
                  height={"48px"}
                  marginBottom={"0px"}
                  marginTop={"0px"}
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                >
                  Más sobre SURGIR <img src="icons/home/chevron-right-small.svg" alt="" />
                </SurgirButton>
              </div>

            </div>
          </div>

          <div className='button-about-responsive'>
            <SurgirButton
              onClick={redirectAboutSurgir}
              variant="danger"
              radius="50px"
              width={"207px"}
              height={"48px"}
              marginBottom={"0px"}
              marginTop={"0px"}
              fontFamily={"'Str-Microtext-Bold', sans-serif"}
            >
              Más sobre SURGIR <img src="icons/home/chevron-right-small.svg" alt="" />
            </SurgirButton>
          </div>
        </div>

        <div>
          <SurgirSlideSantander
            isBorderRadius={true}
            textButtonSlide={'Entérate más'}
            widthButtonSlide={'142px'}
            radiusButtonSlide={'50px'}
            linkButton={'https://www.santander.com.pe/'}
          />
        </div>

        <div className='home-videos-body' >
          <div className='home-videos'>
            <div className='home-videos__content'>
              <div className='home-videos__content__title'>
                  <SurgirHeadline
                    title='¡Historias de Progreso de emprendedores como tú!'
                    align={isMedium || isSmall ? 'center' : 'left'}
                    subtitle='Inspiradoras historias de nuestros clientes que lograron SURGIR y cumplir sus sueños'
                  />
                </div>

              
              <div className='card-youtube-desktop'>
                <button
                  className='home-videos__content__card'
                  onClick={redirectChannelYoutube}
                >
                  <div className='home-videos__content__card__icon'>
                    <img src="icons/home/youtube.svg" alt="" />
                  </div>
                  <div className='home-videos__content__card__text'>
                    Conoce más historias en nuestro canal de YouTube
                  </div>
                </button>
              </div>
            </div>
            <div className='home-videos__player'>
              <div className='home-videos__player__principal'>
                <iframe
                  width="100%"
                  height="100%"
                  src={youtubeSrc}
                  title="YouTube video player"
                  style={{
                    borderRadius: '12px'
                  }}
                >
                </iframe>
              </div>
              <div className='home-videos__player__secondary'>
                {
                  CARDS_YOUTUBE.map((card, i) => {
                    const backgroundImageUrl = `images/home/youtube/${card.background}`;
                    const isActive = activeButtonIndex === i;

                    return (
                      <div
                        key={card.background}
                        className={`home-videos__player__secondary__card ${isActive ? 'active' : ''}`}
                        style={{
                          backgroundImage: `url(${backgroundImageUrl})`,
                        }}
                      >
                        <div
                          className='home-videos__player__secondary__card__content'
                          style={{
                            background: `${!isActive ? 'rgba(255, 255, 255, 0.4)' : 'none'}`
                          }}
                        >
                          <button
                            onClick={() => handleButtonYoutube(card.idVideo, i)}
                          >
                            {isActive ? (
                              <img src="icons/home/pause.svg" alt="" />
                            ) : (
                              <img src="icons/home/play.svg" alt="" />
                            )}
                          </button>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>

          <div className='card-youtube-reponsive'>
            <button
              className='home-videos__content__card'
              onClick={redirectChannelYoutube}
            >
              <div className='home-videos__content__card__icon'>
                <img src="icons/home/youtube.svg" alt="" />
              </div>
              <div className='home-videos__content__card__text'>
                Conoce más historias en nuestro canal de YouTube
              </div>
            </button>
          </div>
        </div>

        <div>
          <SurgirSlideSecondary
            image={'person-store.png'}
            title={'Únete a las más de 80,000 personas que están logrando sus metas con SURGIR'}
            textButton={'Solicita tu Crédito'}
            eventButton={redirectContactanos}
          />
        </div>
      </div>

    </>
  );
};

export default Home;