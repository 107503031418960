import React, { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Location from "../../../assets/images/IconsLocation.svg";
import SurgirListPlaces from "../SurgirListPlaces/SurgirListPlaces";
import "./SurgirCardLocations.scss";

const LIMA = {
  name: "Lima",
  divisions: [
    {
      numBlock: 1,
      items: [
        { title: "Este", text: "Ate Vitarte, El Agustino, La Molina, Lurigancho-Chosica, Rímac, San Juan de Lurigancho y Santa Anita." },
        { title: "Norte", text: "Ancón, Carabayllo, Comas, Independencia, Los Olivos, Puente Piedra, San Martín De Porres y Santa Rosa." },
        { title: "Sur", text: "Chorrillos, Lurín, Pachacámac, Pucusana, Punta Hermosa, Punta Negra, San Bartolo, San Juan de Miraflores, Santa María del Mar, Villa El Salvador y Villa María Del Triunfo." }
      ]
    },
    {
      numBlock: 2,
      items: [
        { title: "Callao", text: "Bellavista, Callao, Carmen de La Legua-Reynoso, La Perla, La Punta, Mi Perú y Ventanilla." },
        { title: "Cañete", text: "Chilca" },
        { title: "Huarochirí", text: "San Antonio" }
      ]
    }
  ]
}

const OFICINAS_INFORMATIVAS = {
  name: "Oficinas Informativas",
  divisions: [
    {
      numBlock: 1,
      items: [
        {
          title: "Lima",
          subTitle: 'Oficina Canto Grande',
          text: "Av. Canto Grande 3530, Urb. San Rafael, San Juan de Lurigancho.",
          schedule: (
            <span>L a V: 9:00 am - 1:00 pm / 2:00 pm - 6:00 pm <br /> S: 9:00 am - 1:00 pm</span>
          )
        },
        {
          subTitle: "Oficina Los Olivos", 
          text: "Av. Santiago Antúnez de Mayolo 1587, Urb. Pinares, Los Olivos.",
          schedule: (
            <span>L a V: 9:00 am - 1:00 pm / 2:00 pm - 6:00 pm <br /> S: 9:00 am - 1:00 pm</span>
          )
        },
        {
          subTitle: "Oficina San Juan de Miraflores", 
          text: "Av. Ramón Vargas Machuca 598-A Tda. 1, San Juan de Miraflores.",
          schedule: (
            <span>L a V: 9:00 am - 1:00 pm / 2:00 pm - 6:00 pm <br /> S: 9:00 am - 1:00 pm</span>
          )
        }
      ]
    },
    {
      numBlock: 2,
      items: [
        {
          title: "Arequipa", 
          subTitle: 'Oficina Cercado',
          text: "Cl.Jerusalén 202-B Zn.Cercado, Arequipa.",
          schedule: (
            <span>L a V: 9:00 am - 1:00 pm / 2:00 pm - 6:00 pm <br /> S: 9:00 am - 1:00 pm</span>
          )
        }
      ]
    },
    {
      numBlock: 3,
      items: [
        {
          title: "Piura", 
          subTitle: 'Oficina Piura Centro',
          text: "Cl. Libertad 450-454 Tda. A2 Pi. 1, Piura",
          schedule: (
            <span>L a V: 9:00 am - 1:00 pm / 2:00 pm - 6:00 pm <br /> S: 9:00 am - 1:00 pm</span>
          )
        }
      ]
    }
  ]
}

const SurgirCardLocations: React.FC = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};
  
  const zonaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const hash = window.location.hash;
    if (hash === "#zona-cobertura" && zonaRef.current) {

      setTimeout(() => {
        const zona = document.getElementById('zonaCobertura')?.offsetTop ?? 0;
        const topBanner = document.getElementById('topBanner')?.scrollHeight ?? 0;

        window.scrollTo({top: (zona - (56 + (scrollPosition === 0 ? topBanner : 0)))})
      }, 200);
      
    }
  }, []);

  return (
    <Container style={{ marginBottom: "96px", paddingTop: "47px" }}>

      <div>
        <Card className="card2-style">
          <Card.Body>
            <Card.Title>
              <span className="titulo" >
                Oficinas
              </span>
            </Card.Title>
          </Card.Body>

          <div style={{ paddingLeft: "1%", width: "99%" }} >
            <div style={{ margin: "20 0 20 0", display:'flex'}} >
              <div>
                <img alt="" src={Location} />
              </div>
              <div style={{paddingLeft:10, width:"100%"}}>
                <span data-testid="spanText" style={{fontSize:"18px", color:" #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif"}}>
                  Oficinas de Atención al Público
                </span>
                <hr className="linea"/>
              </div>
            </div>

            <div className="donde-estamos-container" style={{ display: 'flex', }}>
              <div >
                <div style={{ marginBottom: "16px" }}>
                  <span className="texto" style={{ paddingLeft: "35px", fontSize: "20px", paddingBottom: "15px" }} >
                    Lima
                  </span>
                  <span className="texto" style={{ fontSize: "16px", paddingLeft: "35px" }}>
                    Oficina Especial Compartida <br />
                  </span>
                  <span className="subtext" style={{ paddingLeft: "35px" }}>
                    Cl. Amador Merino Reina 411, San Isidro.
                  </span>
                  <span className="subtext" style={{ paddingLeft: "35px" }}>
                    L a V: 9:00 am - 6:00 pm
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingLeft: "1%", width: "99%" }}>
            <div>
              <div style={{ margin: "20 0 20 0", display: 'flex' }} >
                <div>
                  <img alt="" src={Location} />
                </div>
                <div style={{ paddingLeft: 10, width: "100%" }}>
                  <span style={{ fontSize: "18px", color: " #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif" }}>
                    {OFICINAS_INFORMATIVAS.name}
                  </span>
                  <hr className="linea" />
                </div>
              </div>

              <div>
                {OFICINAS_INFORMATIVAS.divisions.map((element: any) => {
                  return (<div key={element} className="donde-estamos-container">
                    {element.items.map((item: any) => {
                      return (<div key={item.title}>
                        <SurgirListPlaces 
                          subTitle={item.subTitle} 
                          title={item.title} 
                          text={item.text}
                          schedule={item.schedule}
                        />
                      </div>)
                    })}
                  </div>)
                })}
              </div>
            </div>
          </div>
        </Card>
      </div>
      
      <div>
        <Card className="card4-style">
          <Card.Body>
            <Card.Title>
              <span className="titulo" >
                Oficina Principal
              </span>
            </Card.Title>
          </Card.Body>

          <div style={{ paddingLeft: "1%", width: "99%" }} >

            <div style={{ margin: "20 0 20 0", display:'flex'}} >
              <div>
                <img alt="" src={Location} />
              </div>
              <div style={{paddingLeft:10, width:"100%"}}>
                <span style={{fontSize:"18px", color:" #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif"}}>
                  Oficina
                </span>
                <hr className="linea"/>
              </div>
            </div>

          <br />
            <div className="donde-estamos-container" style={{ display: 'flex', }}>
              <div >
                <div style={{ marginBottom: "16px" }}>
                  <span className="texto" style={{ paddingLeft: "35px", fontSize: "20px", paddingBottom: "15px" }} >
                    Av. Rivera Navarrete 475, piso 3, San Isidro.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div id="zona-cobertura" ref={zonaRef}>
        <Card className="card3-style" id="zonaCobertura">
          <Card.Body>
            <Card.Title>
              <span className="titulo">
                Zona de Cobertura*
              </span>
            </Card.Title>

            <span className="subtext" >
              Conoce la zona de cobertura de nuestros créditos.
            </span>

          </Card.Body>

          <div style={{ paddingLeft: "1%", width: "99%" }}>
            <div>
              <div style={{ margin: "20 0 20 0", display:'flex'}} >
                <div>
                  <img alt="" src={Location} />
                </div>
                <div style={{paddingLeft:10, width:"100%"}}>
                  <span style={{fontSize:"18px", color:" #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif"}}>
                    {LIMA.name}
                  </span>
                  <hr className="linea"/>
                </div>
              </div>


              <div>
                {LIMA.divisions.map((element: any) => {
                  return (<div key={element} className="donde-estamos-container">
                    {element.items.map((item: any) => {
                      return (<div key={item.title}>
                        <SurgirListPlaces title={item.title} text={item.text} />
                      </div>)
                    })}
                  </div>)
                })}
              </div>
            </div>

            <div >
              <div style={{ margin: "20 0 20 0", display:'flex'}} >
                <div>
                  <img alt="" src={Location} />
                </div>
                <div style={{paddingLeft:10, width:"100%"}}>
                  <span style={{fontSize:"18px", color:" #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif"}}>
                    Arequipa
                  </span>
                  <hr className="linea"/>
                </div>
              </div>

              <div className="donde-estamos-container">
                <div  >
                  <SurgirListPlaces title={"Centro"} text={"Alto Selva Alegre, Arequipa, Mariano Melgar, Miraflores, Paucarpata, Sachaca y Yanahuara."} />
                </div>
                <div  >
                  <SurgirListPlaces title={"Norte"} text={"Cayma, Cerro Colorado y Yura."} />
                </div>
                <div >
                  <SurgirListPlaces title={"Sur"} text={"Characato, Hunter, Jose Luis Bustamante y Rivero, Mollebaya, Sabandía, Socabaya, Tiabaya y Uchumayo."} />
                </div>
              </div>
            </div>
            <div>
              <div style={{ margin: "20 0 20 0", display:'flex'}} >
                <div>
                  <img alt="" src={Location} />
                </div>
                <div style={{paddingLeft:10, width:"100%"}}>
                  <span style={{fontSize:"18px", color:" #FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif"}}>
                    Piura
                  </span>
                  <hr className="linea"/>
                </div>
              </div>

              <div className="donde-estamos-container">
                <div  >
                  <SurgirListPlaces title={"Piura"} text={"26 de Octubre, Castilla, Catacaos, Piura y Tambograndre."} />
                </div>
                <div >
                  <SurgirListPlaces title={"Sullana"} text={"Bellavista, Ignacio Escudero, Marcavelica, Miguel Checa, Querecotillo, Salitral y Sullana."} />
                </div>
                <div  >
                  <SurgirListPlaces title={"Paita"} text={"La Huaca"} />
                </div>
              </div>
            </div>
            <span className="subtext" >
              *La cobertura de nuestros créditos excluye las zonas de Chosica en Lurigancho-Chosica y Manchay en Pachacámac.
            </span>

          </div>
        </Card>
      </div>
    </Container>
  );
}

export default SurgirCardLocations



