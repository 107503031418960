import React from 'react';

interface ListIconProps {
  icon: any;
  name: string;
}
const SurgirListIcon: React.FC<ListIconProps> = ({ icon, name }) => {
    return (
      <div style={{ margin: "20 0 20 0", display:'flex', alignItems:'center' }} >
        <div>
          <img alt='' src={icon} />
        </div>
        <div style={{paddingLeft:10}}>
          <span className="parrafo">
            {name}
          </span>
        </div>
      </div>
    );
  }
  export default SurgirListIcon;