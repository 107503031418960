import React from 'react'
import IcLibroMobile from "./../../../../assets/images/ic_book_mobile.svg";
import './style.scss'
export default function LogoLibro() {
    return (
        <div className="footer-bottom-container-libro">
            <span className="parrafow" >
                Libro de Reclamaciones
            </span>
            <div >
                <a href="/reclamos-y-requerimientos" rel="noopener noreferrer">
                    <img alt='' className="fotter-botton-container-libro__icon" src={IcLibroMobile} />
                </a>
            </div>
        </div>
    )
}