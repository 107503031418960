import React from "react";
import { Container } from "react-bootstrap";
import SurgirButton from "../SurgirButton/SurgirButton";
import './SurgirBanner.scss'

interface SurgirBannerProps {
  image: string;
  title: string;
  description?: string;
  showButton: boolean;
  textButton?: string;
  showFooter: boolean;
  imageFooter?: string;
  eventClickButton?: any;
  backgroundColor: string;
  altImgBanner?: string;
  
}

const SurgirBanner: React.FC<SurgirBannerProps> = ({
  image,
  title,
  description,
  imageFooter,
  showButton,
  textButton,
  showFooter,
  eventClickButton,
  backgroundColor,
  altImgBanner,
  
}) => {

  return (
    <div className="banner" style={{backgroundColor: backgroundColor}}>
          <Container className={'banner-container'}>
            <div className="banner__body">
              <div className="banner__body__content">
                <div className="banner__body__content__title">{title}</div>
                {
                  description && <p className="banner__body__content__description">{description}</p>
                }

                {
                  showButton && <div className="banner__body__content__button">
                    <SurgirButton
                      variant="danger"
                      radius="32px"
                      width={"167px"}
                      height={"46px"}
                      marginTop="0px"
                      marginBottom="0px"
                      fontFamily={'"Str-Microtext-Bold", sans-serif'}
                      onClick={eventClickButton}
                    >
                    { textButton }
                  </SurgirButton>
                  </div>
                }

                {
                  showFooter &&  <div className="banner__body__content__footer">
                    <span className="banner__body__content__footer__text">Con el respaldo de</span>
                    <img src={`images/logos/${imageFooter}`} alt={title}/>
                  </div>  
                }
               
              </div>

              <div className="banner__body__logo">
                <img alt={altImgBanner}
                   src={`images/banners/${image}`}
                   />
                {
                  showFooter && <div className="banner__body__content__footer-responsive">
                    <div className="banner__body__content__footer-responsive__text">Con el respaldo de</div>
                    <img src={`images/logos/${imageFooter}`} alt={title} />
                  </div>
                }
                
              </div>
            </div>

          </Container>
        </div>
  );
}

export default SurgirBanner