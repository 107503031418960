import React from 'react';

interface CustomDotsProps {
  isMedium: boolean;
  isSmall: boolean;
  previous: () => void;
  next: () => void;
  dots: React.ReactNode;
}

const CustomDots: React.FC<CustomDotsProps> = ({ isMedium, isSmall, previous, next, dots }) => {
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '21px' }}>
      <h1>{isSmall}</h1>
      <button name='left' style={{ display: isMedium || isSmall ? 'none' : 'flex' }} className="button-sliders" onClick={previous}>
        <img src="icons/sliders/chevron-left.svg" alt="" /> 
      </button>
      <ul style={{ margin: "0px", display: 'flex' }}> {dots} </ul>
      <button name='right' style={{ display: isMedium || isSmall ? 'none' : 'flex' }} className="button-sliders" onClick={next}>
        <img src="icons/sliders/chevron-right.svg" alt="" />
      </button>
    </div>
  )
};

export default CustomDots;
