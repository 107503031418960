import React, { FC } from 'react';

interface SurgirListIconChannelBlackProps {
  Icon: FC;
  name: string;
}
const SurgirListIconChannelBlack: React.FC<SurgirListIconChannelBlackProps> = ({
  Icon,
  name,
}) => {
  return (
    <div style={{ margin: '20 0 20 0', display: 'flex' }}>
      <div>
        <Icon />
      </div>
      <div style={{ paddingLeft: 10, width: '100%' }}>
        <span
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            color: ' #1D252D',
            fontFamily: 'Str-Headline-Bold, sans-serif',
          }}
        >
          {name}
        </span>
      </div>
    </div>
  );
}

export default SurgirListIconChannelBlack;


