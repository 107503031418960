import React from 'react';
import SurgirhoverList from '../SurgirhoverList/SurgirhoverList';
import { menuItems } from './SurgirHoverBody.fixture';

interface Props {
    onShowSearch: () => void;
}
const SurgirhoverBody: React.FC<Props> = ({ onShowSearch }) => {
    ;
    return (
        <SurgirhoverList menuItems={menuItems} onSearch={onShowSearch} />
    );
};

export default SurgirhoverBody;

