import { SafeTypes, TablePlan, Plan, Operation, Coverage } from "domain/models/SurgirSafe";
import React from "react";
import { createLink } from "utils/urls";

export const SAFE_TYPES: SafeTypes[] = [
  {
    title: 'Muerte natural',
    description: 'Fallecimiento del asegurado por causas distintas a un accidente.',
    showDescription: false,
    className: "card-one",
    classNameSecondary: "card-one-dark",
    alt: 'Muerte Natural Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Muerte accidental',
    description: 'Fallecimiento del asegurado como consecuencia directa e inmediata de un accidente.',
    showDescription: false,
    className: "card-two",
    classNameSecondary: "card-two-dark",
    alt: 'Muerte Accidental Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Muerte accidental en transporte público',
    description: 'Fallecimiento del asegurado por un accidente en un medio de transporte público terrestre con rutas preestablecidas.',
    showDescription: false,
    className: "card-three",
    classNameSecondary: "card-three-dark",
    alt: 'Muerte Accidental en transporte público Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Indemnización diaria por hospitalización',
    description: 'Cuando el asegurado sea hospitalizado a consecuencia de un accidente o de una enfermedad, se le pagará la renta diaria indicada en la póliza.',
    showDescription: false,
    className: "card-four",
    classNameSecondary: "card-four-dark",
    alt: 'Indemnización diaria por hospitalización Super Seguro Cobertura Seguro Vida'
  },
  {
    title: 'Desamparo súbito familiar',
    description: 'Si a consecuencia de un mismo accidente, amparado por el presente seguro, fallecieran el asegurado y su cónyuge.',
    showDescription: false,
    className: "card-five",
    classNameSecondary: "card-five-dark",
    alt: 'Desamparo súbito familiar Super Seguro Cobertura Seguro Vida'
  }
  ,{
    title: 'Indemnización por sepelio',
    description: 'Reembolso de los gastos de sepelio en los que se hubiera incurrido como consecuencia del fallecimiento del asegurado.',
    showDescription: false,
    className: "card-six",
    classNameSecondary: "card-six-dark",
    alt: 'Indemnización por sepelio Super Seguro Cobertura Seguro Vida'
  }
]

export const TABLE_PLANS: TablePlan[] = [
  {
    icon: 'telefonica',
    titlePlan: 'Orientación médica telefónica *',
    values : [
      {
        title: '',
        amount: 'S/ 0',
        description: false,
        id: 19
      },
      {
        title: '',
        amount: 'S/ 0',
        description: false,
        id: 20
      },
      {
        title: '',
        amount: 'S/ 0',
        description: false,
        id: 21
      },
    ]
  },
  {
    icon: 'martillo',
    titlePlan: 'Gasfitería *',
    values : [
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 22
      },
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 23
      },
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 24
      },
    ]
  },
  {
    icon: 'enchufe',
    titlePlan: 'Electricidad *',
    values : [
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 25
      },
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 26
      },
      {
        title: 'Copago',
        amount: 'S/ 250',
        description: false,
        id: 27
      },
    ]
  },
  {
    icon: 'llave',
    titlePlan: 'Cerrajería *',
    values : [
      {
        title: 'Copago',
        amount: 'S/ 90',
        description: false,
        id: 28
      },
      {
        title: 'Copago',
        amount: 'S/ 90',
        description: false,
        id: 29
      },
      {
        title: 'Copago',
        amount: 'S/ 90',
        description: false,
        id: 30
      },
    ]
  },
  {
    icon: 'hospital',
    titlePlan: 'Indemnización diaria por hospitalización**',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 50',
        description: true,
        id: 10
      },
      {
        title: 'Hasta',
        amount: 'S/ 75',
        description: true,
        id: 11
      },
      {
        title: 'Hasta',
        amount: 'S/ 75',
        description: true,
        id: 12
      },
    ]
  },
  {
    icon: 'plant',
    titlePlan: 'Muerte natural',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 750',
        id: 1,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 1,000',
        id: 2,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 1,000',
        id: 3,
        description: false,
      },
    ]
  },
  {
    icon: 'zap',
    titlePlan: 'Muerte accidental',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 3,500',
        id: 4,
        description: false,

      },
      {
        title: 'Hasta',
        amount: 'S/ 6,500',
        id: 5 ,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 10,000',
        id: 6,
        description: false,
      },
    ]
  },
  {
    icon: 'car',
    titlePlan: 'Muerte accidental en transporte público',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 1,000',
        id: 7 ,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 2,000',
        id: 8,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 3,000',
        id: 9,
        description: false,
      },
    ]
  },
  {
    icon: 'family',
    titlePlan: 'Desamparo súbito familiar',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 3,500',
        id: 13,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 5,000',
        id: 14,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 8,000',
        id: 15,
        description: false,
      },
    ]
  },
  {
    icon: 'drop',
    titlePlan: 'Indemnización por sepelio',
    values : [
      {
        title: 'Hasta',
        amount: 'S/ 1,500',
        id: 16,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 1,800',
        id: 17,
        description: false,
      },
      {
        title: 'Hasta',
        amount: 'S/ 1,800',
        id: 18,
        description: false,
      },
    ]
  }
]

export const PLANS: Plan[] = [
  { title: 'Plan 4', amount: 'S/ 15', description: 'al mes'},
  { title: 'Plan 5', amount: 'S/ 25', description: 'al mes'},
  { title: 'Plan 6', amount: 'S/ 33', description: 'al mes'}
]

export const OPERATIONS: Operation[] = [
  { title: 'Elena adquiere el Super Seguro' , description: 'Al solicitar su crédito con nosotros, Elena adquirió el plan 2 del Super Seguro que le cobra S/ 25 al mes.' },
  { title: 'Elena podría dejar desprotegida a su familia', description: 'Ella podría sufrir un accidente y fallecer, dejando a su familia sin el apoyo económico que siempre les ha brindado.' },
  { title: 'Su familia quedaría protegida', description: 'Gracias al Super Seguro que contrato Elena y al plan que eligió, su familia podría estar protegida y recibir hasta S/ 6,500 por su seguro.' },
]

export const COVERAGE: Coverage[] = [
  { 
    icon: 'orientacion',
    title: 'Orientación médica telefónica',
    description: 'Atención y asesoramiento sobre todas las dudas de carácter médico que el asegurado desee plantear.',
  },
  { 
    icon: 'gasfiteria',
    title: 'Gasfitería',
    description: 'En caso de rotura de tuberías, estanques de agua, llaves u otras instalaciones fijas de agua.',
  },
  { 
    icon: 'electricidad',
    title: 'Electricidad',
    description: 'Por falta de energía eléctrica, producida como consecuencia de una falla o avería de las instalaciones eléctricas.',
  },
  { 
    icon: 'cerrajeria',
    title: 'Cerrajería',
    description: 'Ante pérdida, extravío o robo de llaves o inutilización de chapas o cerraduras a consecuencia de robo u otra causa accidental.',
  },
  { 
    icon: 'hospital',
    title: 'Indemnización diaria por hospitalización',
    description: 'Cuando el asegurado sea hospitalizado a consecuencia de un accidente o de una enfermedad, se le pagará la renta diaria indicada en la póliza.',
  },
  { 
    icon: 'natural',
    title: 'Muerte natural',
    description: 'Fallecimiento del asegurado por causas distintas a un accidente.',
  },
  { 
    icon: 'accidental',
    title: 'Muerte accidental',
    description: 'Fallecimiento del asegurado como consecuencia directa e inmediata de un accidente.',
  },
  { 
    icon: 'transporte',
    title: 'Muerte accidental en transporte público',
    description: 'Fallecimiento del asegurado por un accidente en un medio de transporte público terrestre con rutas preestablecidas.',
  },
  { 
    icon: 'familiar',
    title: 'Desamparo súbito familiar',
    description: 'Si a consecuencia de un mismo accidente, amparado por el presente seguro, fallecieran el asegurado y su cónyuge.',
  },
  { 
    icon: 'sepelio',
    title: 'Indemnización por sepelio',
    description: 'Reembolso de los gastos de sepelio en los que se hubiera incurrido como consecuencia del fallecimiento del asegurado.',
  }

]

export const frequentQuestionsPART1 = [
  {
    title: '¿En qué consiste el Super Seguro?',
    body:  <p>
    SUPER SEGURO es un seguro de vida que otorga coberturas por Muerte Natural, 
    Muerte Accidental, Muerte Accidental en Transporte Público, Indemnización Diaria por 
    Hospitalización, Desamparo Súbito Familiar e Indemnización por Sepelio. 
    Aplicándose exclusiones de acuerdo con lo establecido en el <span className="span-surgir">
      <a onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_SUPERSEGURO_CERTIFICADO ?? "", "_blank")} rel="noopener noreferrer">Certificado del Seguro</a>
      </span>.
  </p>
  },
  {
    title: '¿Con qué compañía se otorga este seguro?',
    body:  <p>
    El seguro pertenece a los productos ofertados por Mapfre Perú Compañía de Seguros y Reaseguros S.A, una de las más importantes compañías de seguros con amplia presencia internacional.
  </p>
  },
  {
    title: '¿En qué momento empiezo a tener cobertura del seguro?',
    body: <p>
    Desde la suscripción del seguro, siendo un seguro que se otorga junto a un crédito, estarás asegurado desde el momento del desembolso del crédito.
  </p>
  },
  {
    title: ' ¿Este seguro requiere exámenes médicos?',
    body:  <p>
    No, si bien existen preexistencias que son excluyentes para la contratación o cobertura; eres tú quien al contratar estás prestando una declaración jurada de no sufrir ninguna de ellas.
  </p>
  },
  {
    title: '¿Qué es una preexistencia?',
    body:  <p>
    En el ámbito de los seguros, se conoce así a aquellas patologías con las que ya contaba el titular de un seguro de salud, antes de la contratación de su póliza.
  </p>
  },
  {
    title: '¿Hasta cuándo estoy asegurado?',
    body: <p>
    Tendrás la cobertura mientras se encuentre vigente tu crédito acorde a tu cronograma proporcionado con el desembolso. Recuerda que la vigencia del producto es mensual y es importante que te encuentres al día en el pago de tus cuotas.
  </p>
  },
  {
    title: '¿Si cancelo anticipado mi crédito, mi seguro también se cancela?',
    body: <p>
    Si cancela el crédito, se cancela también el seguro. Recuerda que en esta modalidad de venta, el producto está asociado al crédito y, por consiguiente, al cancelar el crédito ya no hay vinculo contractual entre SURGIR y el cliente.
  </p>
  },
  {
    title: '¿Mis beneficiarios son obligatorios o puedo elegirlos?',
    body: <p>
    Puedes elegir al beneficiario que desees, para ello los debes designar en la solicitud certificado del seguro, sino hubiera dicha designación serán los herederos legales. En el caso de la cobertura por renta por hospitalización, será siempre el Asegurado.
  </p>
  },
]

export const frequentQuestionsPART2 = [
  {
    title: '¿Puede ser beneficiario del seguro mi hijo menor de edad?',
    body:   <p>
    Sí, para ello deberá tener un DNI vigente según la legislación peruana.
  </p>
  },
  {
    title: '¿Me devuelven las primas en caso no haga uso de las coberturas?',
    body: <p>
    No, este seguro tiene una renovación y pago de prima mensual. No tiene y/o no aplica la modalidad con retorno.
  </p>
  },
  {
    title: '¿Puedo leer las condiciones antes de contratar el seguro?',
    body: <p>
    Por supuesto, puedes revisar todas las condiciones del seguro, las cuales se encuentran detalladas en el <span className="span-surgir">
      <a onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_SUPERSEGURO_CERTIFICADO ?? "", "_blank")} rel="noopener noreferrer">Certificado del Seguro</a></span>.
  </p>
  },
]

export const whatWeDoNotCover = [
  {
    title: 'Para la cobertura de fallecimiento, muerte accidental y desamparo súbito',
    body: <ol className="accordion-what-we-do-not__tabs__list-order">
    <li>Tratamientos o procedimientos médicos o cirugías con fines estéticos, cirugías plásticas o reconstructivas.</li>
    <li>
      Accidentes que se produzcan cuando el Asegurado se encuentre en estado de ebriedad o embriaguez y/o de consumo de drogas y/o estado de sonambulismo y siempre que dicho estado del Asegurado haya sido la causa del Accidente o haya contribuido en el Accidente. Se considerará estado de embriaguez si el examen médico correspondiente practicado al Asegurado arroja 0.50 gr/lt o más al momento del Accidente. Para los efectos de determinar el grado de intoxicación alcohólica del Asegurado al momento del Accidente, las partes convienen en establecer que éste se determinará por el examen médico correspondiente y que el grado de metabolización del alcohol en la sangre es de 0.15 gr/lt por hora transcurrida desde el momento del Accidente hasta el instante mismo en que se practique la prueba, conforme a la fórmula utilizada por la Policía Nacional del Perú."
    </li>
    <li>Suicidio, salvo que hubiesen transcurrido al menos dos (2) años completos e ininterrumpidos desde la fecha de contratación del seguro.
    </li>
    <li>
      Síndrome Inmunológico Deficiencia Adquirida (SIDA), así como todas las enfermedades causadas o relacionadas al virus VIH positivo, incluyendo la enfermedad de Sarcoma de Kaposi, Síndrome Complejo relativo al SIDA (SCRS), linfomas o cualquier neoplasia vinculada con el VIH, SCRS o SIDA."
    </li>
    <li>Viajes en algún medio de transporte aéreo particular no regular o en medios de transporte no aptos para el transporte de personas. Se entiende por servicio de transporte aéreo no regular el que se realiza sin sujeción a frecuencias, itinerarios ni horarios prefijados, incluso si el servicio se efectúa por medio de una serie de vuelos. Asimismo, se entiende por medios de transporte no aptos para el transporte de personas los Vehículos de carga."
    </li>
    <li>Guerra externa, invasión u operaciones bélicas (al margen de que exista o no declaración de guerra), actos hostiles de entidades soberanas o del gobierno, guerra civil, rebelión, sedición, motín, tumulto popular, vandalismo, guerrilla, terrorismo, huelga, revolución, insurrección, conmoción civil que adquiera las proporciones de un levantamiento, poder militar o usurpado o ley marcial o confiscación por orden de un gobierno o autoridad pública."
    </li>
    <li>
      Detonación nuclear, reacción, radiación nuclear o contaminación radiactiva; independientemente de la forma en que se haya ocasionado; así como la emisión de radiaciones ionizantes o contaminación por radioactividad de cualquier combustible nuclear o de cualquier desperdicio proveniente de la combustión de dicho combustible."
    </li>
    <li>
      Pena de Muerte; fallecimiento a consecuencia de la participación activa del Asegurado, ya sea como autor, coautor o cómplice, en cualquier acto delictivo o en actos violatorios de leyes o reglamentos; en duelo concertado; peleas o riñas, salvo en aquellos casos en que se acredite legítima defensa; así como participación activa en actos notoriamente peligrosos huelgas, motín, conmoción civil contra el orden público dentro o fuera del país, daño malicioso, vandalismo, actos subversivos y terrorismo. La legítima defensa no requerirá ser acreditada de manera judicial."
    </li>
    <li>
      Fallecimiento del asegurado en ejercicio de sus funciones como militar, policía, serenazgo, agente de seguridad.
    </li>
    <li>
      Fenómenos de la naturaleza: terremotos, meteoritos, tsunami, maremotos, erupción volcánica, inundaciones, huaycos, avalanchas, deslizamientos, tornado, vendaval, huracán, ciclón, granizo y rayos."
    </li>
    <li>Accidentes que se produzcan en la práctica profesional de cualquier deporte y la práctica no profesional de deportes de actividad de riesgo: carreras de automóvil, motocicletas, deportes acuáticos, caminata de montaña, práctica de ciclismo, concursos o prácticas hípicas, equitación, rodeo, rugby, boxeo, esquí acuático, tabla hawaiana, kayac, canotaje, prácticas de surf, paracaidismo, ala delta, parapente, puenting, caza, toreo, andinismo, escalada, pesca submarina, deportes a caballo y artes marciales."
    </li>
    <li>Accidente de trabajo o Enfermedad profesional para los trabajadores de empresas que realizan las actividades descritas en el Anexo 5 del Decreto Supremo N° 009-97-SA, Reglamento de la Ley de Modernización de la Seguridad Social en Salud y/o normas modificatorias o sustitutorias."
    </li>
    <li>
      Accidente provocado dolosamente por el Contratante y/o Beneficiario; acto delictivo contra el Asegurado cometido en calidad de autor, cómplice o participe, por uno o más Beneficiarios."
    </li>
    <li>Accidentes provocados por el Asegurado en vehículos motorizados conducidos por éste sin contar con la licencia correspondiente o en Accidente vehicular no reportado a las autoridades competentes."
    </li>
    <li>Cualquier procedimiento médico sometido de manera voluntaria por el Asegurado y realizado por una persona no licenciada para el ejercicio médico y en la cual el Asegurado conoce o pudo conocer de las circunstancias de la situación irregular de la persona que practica el acto médico."
    </li>
    <li>
      Manipulación de explosivos o armas de fuego efectuada directamente por el Asegurado.
    </li>
    <li>Enfermedades o lesiones por abuso de alcohol o de drogas.
    </li>
    <li>Enfermedades contagiosas que sean declaradas por el MINISTERIO DE SALUD como epidemias y/o pandemias, a excepción del COVID-19.</li>
  </ol>
  },
  {
    title: ' Aplicables a indemnización diaria por hospitalización',
    body: <div>
      <div>
                  <p>
                    Adicionalmente a las exclusiones indicadas en las coberturas anteriores, las prestaciones derivadas de la Coberturas Adicionales no serán reconocida por LA COMPAÑÍA en caso de siniestros ocurridos por las siguientes causas:
                  </p>
                </div>
                <div className="accordion-what-we-do-not__tabs__list-no-order">
                  <ul>
                    <li>Fisión o reacción nuclear, armas biológicas o químicas, o cualquier contaminación radioactiva.</li>
                    <li>Curas de reposo.</li>
                    <li>Tentativa de suicidio y/o heridas auto-infligidas.</li>
                    <li>Aborto.</li>
                    <li>Tratamientos de esterilización o fertilidad</li>
                    <li>Maternidad incluyendo complicaciones del embarazo</li>
                    <li>Hospitalización en un establecimiento que no llena los requisitos descritos de acuerdo al Ministerio de Salud y cuidado de un médico autorizado para el ejercicio de su profesión en Perú.</li>
                    <li>Hospitalización para chequeos médicos de rutina u otros exámenes previos a los cuales no exista indicaciones de trastornos de salud.</li>
                    <li>Tratamientos no reconocidos por las asociaciones médico-científicas a nivel mundial o aquellos de carácter experimental.</li>
                    <li>Cualquier hospitalización derivada de actividades fuera de la ley, incluyendo asalto, siempre y cuando el asegurado sea el causante del acto delictivo.</li>
                    <li>Cualquier hospitalización relacionada con el consumo de drogas, estupefacientes, estimulantes, barbitúricos, etc. Incluyendo alcohol, siempre y cuando el siniestro ocurra a causa del uso de estas sustancias.</li>

                  </ul>
                </div>
    </div>
  },
  {
    title: 'Para la cobertura de gastos de sepelio',
    body: <div>
       <div>
                  <p>
                    Adicionalmente a las exclusiones mencionadas para la cobertura de fallecimiento se incluye:
                  </p>
                </div>
                <div className="section-information-safe__tabs__list-no-order">
                  <ul>
                    <li>Fraude del Asegurado.</li>
                  </ul>
                </div>
    </div>
  },
]