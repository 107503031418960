import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  max-width: 50px;
  width: 20px;
  height: 20px;
  border: 4px solid #EC0000;
  border-top: 4px solid #990000;
  border-radius: 50%;
  animation-name: ${rotate};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-play-state: running;
  animation-iteration-count: infinite;
`;

const SurgirSpinner: React.FC = () => {
  return (
    <Wrapper />
  );
};

export default SurgirSpinner;
