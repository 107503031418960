
import React from 'react';
import styled from "styled-components";

export const Title = styled.h1`
  display: block;
  color: #1d252d;
  font-family: Str-Headline-Bold;
  padding: 60px 16;
  margin-top: 120px;
  margin-left: 16px;
  width: 250px;
  @media (min-width: 1024px) {
    height: 440px;
    width: 100%;
    font-weight: 700;
    font-size: 52px;
    line-height: 64px;
    margin-left: 36px;
  }
`;

const BannerComplaintsBook: React.FC = () => {


  return (
    <div style={{ paddingTop: 25 , paddingBottom: 48 }}>
      <div className="banner-libro">
        <Title>
        ¿Cómo podemos ayudarte?
          <br />
        </Title>
      </div>
    </div>
  );
}

export default BannerComplaintsBook;