import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import React from "react";
import './ResponseForm.scss'

const redirectHome = () => {
  window.location.href = '/';
}

const ResponseForm: React.FC<any> = () => {
  return (
    <div className="response-form">
      <div className="response-form__body">
        <div>
          <img src="images/response-form/ilustracion_form.png" alt="" />
        </div>
        <div>
          <h4>¡Muchas gracias por llenar tu solicitud!</h4>
          <span>Nos contactaremos contigo.</span>
        </div>
        <div>
          <SurgirButton
            onClick={redirectHome}
            variant="danger"
            radius={"50px"}
            width={"166px"}
            height={"48px"}
            marginBottom={"0px"}
            marginTop={"24px"}
            fontFamily={"'Str-Microtext-Bold', sans-serif"}
          >
            Volver al inicio
          </SurgirButton>
        </div>
      </div>
    </div>
  );
}

export default ResponseForm;
