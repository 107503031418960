import { Operation } from "domain/models/SurgirFixedDeposit"
import React from "react";

export const OPERATIONS: Operation[]  = [
  { 
    title: 'Completa nuestro ',
    description: 'Ingresa tus datos personales y nos contactaremos contigo.',
    url:"/abre-tu-deposito-a-plazo-fijo",
    link: 'formulario' },
  { 
    title: 'Descarga la ',
    description: 'Un ejecutivo te llamará y te guiará para completar la solicitud.',
    link: 'Solicitud de Apertura'
  },
  { 
    title: 'Deposita el capital para tu Depósito a Plazo Fijo',
    description: 'Realiza la transferencia a nuestras cuentas de SURGIR y realizaremos la apertura.'
  },
]

export const frequentQuestionsPART1 = [
  {
    title: '¿Qué es un Depósito a Plazo Fijo?',
    body: <p>Es una cuenta en donde podrás obtener una mejor ganancia y rentabilidad por el dinero que deposites de acuerdo a la tasa de interés pactada con SURGIR y al compromiso de cumplir con el periodo de tiempo determinado. <br /> <br /> El Depósito a Plazo Fijo no tiene riesgos, puede pertenecer a una persona natural o jurídica y la rentabilidad puede variar de acuerdo al monto y al plazo acordado. </p>
  },
  {
    title: '¿Puedo ganar mayores intereses para mi dinero de Depósito a Plazo Fijo?',
    body: <p>Sí, puedes elegir mayores plazos para obtener mayores intereses, también, depositar un monto alto te dará la posibilidad de obtener una tasa de interés más atractiva. Puedes abrir tu Depósito a Plazo Fijo con nosotros desde 30 días a más.</p>
  },
  {
    title: '¿Puedo recibir los intereses de mi Depósito a Plazo Fijo antes del vencimiento?',
    body: <p>Puedes recibir los intereses que vayas ganando como tú lo prefieras. SURGIR te ofrece alternativas de pago de interés: mensual, bimestral, trimestral, semestral, anual o al vencimiento del plazo, en la cuenta de ahorros que mantengas con nosotros.</p>
  }
];