import React from 'react';
import {
  Col,
  Toast,
  ToastContainer
} from 'react-bootstrap';
import { ReactComponent as IcCloseBanner } from '../../../src/assets/images/ic_close_banner.svg';
import { ReactComponent as IcCircleError } from '../../../src/assets/images/ic_cicle_error.svg';

interface ToastErrorProps {
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
  messages: string[];
}

const ToastError: React.FC<ToastErrorProps> = ({ show, handleClose, handleShow, messages }) => {
  return (
    <ToastContainer className="toast-error-surgir" position="top-center" containerPosition="fixed">
      <Col md={6} style={{ margin: 'auto' }}>
        <Toast show={show} style={{ backgroundColor: '#FDE5E5', borderWidth: 1, borderStyle: 'solid', borderColor: '#CC0000', width: '100%' }}>
          <Toast.Body>
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'block', fontFamily: 'Str-Headline-Bold', fontSize: 16, color: '#990000', lineHeight: 2.4, fontWeight: 'bold' }}>Pendiente</span>
              <div style={{ width: '100%', textAlign: 'right', borderBlockColor: 'red' }}>
                <button
                  onClick={handleClose}
                  style={{ cursor: 'pointer', border: 'none', background: 'none', padding: 0 }}
                >
                   <IcCloseBanner width={12} />
                </button>
              </div>
            </div>

            <div style={{ textAlign: 'left', paddingRight: 10 }}>
              {messages.map((item) => (
                <div key={item} style={{ display: 'flex', flexDirection: 'row', padding: '2px 0px 2px 0px' }}>
                  <div style={{ display: 'flex', margin: 'auto 12px auto 0px' }}>
                    <IcCircleError style={{ marginBottom: 5 }} />
                  </div>
                  <span style={{ display: 'flex', fontSize: 14, color: '#990000', lineHeight: 1, fontFamily: 'Str-Microtext-Regular' }}>{item}</span>
                </div>
              ))}
            </div>
          </Toast.Body>
        </Toast>
      </Col>
    </ToastContainer>
  );
};

export default ToastError;
