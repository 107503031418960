import {
  getRequest,
  postRequest,
} from './endPoint'

import {
  getTypeDocument,
  getTypeDpfProduct,
  getFindReasoncontact,
  sendContactUsForm,
  getFindUbigeoDep,
  postFindUbigeo,
  postSaveDemand,
  postSaveDpfForm,
  postSaveClaim,
  getCoinType,
  getDialCode,
  getProduct,
  routeGetProblems,
  routeGetReasons,
  routeGetRequirement, 
  routeUploadDocument,
  getDNI,
  getTypeCredit,
  getTypeInsurance,
  getTypeSuperInsurance,
  sendSaveSimulator,
  getTerms,
  postSaveDpfSolicitudeForm,
  getFindDpfSolicitudeForm,
  getListKeybord,
  getListPrincipal,
  getPromotions,
  postSaveFormRequestCredit,
  getCreditProductType
} from './routes'


export const apiGetTypeDocuments = (payload) => getRequest(getTypeDocument(),{params: payload})
export const apiGetListByKeybord = (payload) => getRequest(getListKeybord(),{params: payload})  
export const apiGetListPrincipal = (payload) => getRequest(getListPrincipal(),{params: payload})  
export const apiGetTypeDpfProduct = (payload) => getRequest(getTypeDpfProduct(),{params: payload}) 

export const apiGetFindReasoncontact = (payload) => getRequest(getFindReasoncontact(),{params: payload}) 
export const apiSendContactUsForm = (payload) => postRequest(sendContactUsForm(), payload,false)
export const apiSendCreditRequest = (payload) => postRequest(postSaveFormRequestCredit(), payload,false)
export const apiGetCreditProduct = (payload) => getRequest(getCreditProductType(), {params: payload})
export const apiGetFindUbigeoDep = (payload) => getRequest(getFindUbigeoDep(), {params: payload})
export const apiPostFindUbigeo = (payload) => postRequest(postFindUbigeo(),payload,false)
export const apiPostSaveClaim =(payload) => postRequest(postSaveClaim(), payload,false);
export const apiPostSaveDemand =(payload) => postRequest(postSaveDemand(), payload,false);
export const apiPostSaveDpfForm =(payload) => postRequest(postSaveDpfForm(), payload,false);
export const apiGetCoinsType= (payload) => getRequest(getCoinType(),{params: payload})
export const apiGetDialCode= (payload) => getRequest(getDialCode(),{params: payload}) 
export const apiGetProducts =(payload) => getRequest(getProduct(),{params: payload})
export const apiGetReasons =(payload) => getRequest(routeGetProblems(payload),{params: payload})
export const apiGetSubReasons =(payload) => getRequest(routeGetReasons(payload),{params: payload})
export const apiGetRequirement =(payload) => getRequest(routeGetRequirement(),{params: payload})
export const apiUploadDocument = (payload) => postRequest(routeUploadDocument(),payload,true)
export const apiGetCashPayment = (payload) => getRequest(getDNI(), payload)
export const apiGetTypeCredit = (payload) => getRequest(getTypeCredit(),{params: payload})
export const apiGetTypeInsurance = (payload) => getRequest(getTypeInsurance(),{params: payload})
export const apiGetTypeSuperInsurance = (payload) => getRequest(getTypeSuperInsurance(),{params: payload})
export const apiPostSaveSimulator =(payload) => postRequest(sendSaveSimulator(), payload,false);
export const apiGetTerms=(payload)=> getRequest(getTerms(),{params: payload})
export const apiPostSaveDpfSolicitudeForm = (payload) => postRequest(postSaveDpfSolicitudeForm(),payload)
export const apiGetFindDpfSolicitudeForm = (payload) => getRequest(getFindDpfSolicitudeForm(),{params: payload})
export const apiGetPromotions = (payload) => getRequest(getPromotions(payload), {params: payload})