/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { StylesSurgirTextField } from 'utils/StylesSurgirTextField';

interface SurgirTextFieldProps {
  validateError: (value: string, dependValue?: any) => { error: boolean; message?: any };
  visibleError?: boolean;
  value: string;
  dependValue?: any;
  setValue: (value: string) => void;
  label: string;
  fullWidth?: boolean;
  variant: any;
  type?: string;
  disabled?: boolean;
  FormatCustom?: boolean;
  pattern?: any;
  prefix?: string;
  id?: string;
  name?: string;
  isAmountApertura?: boolean;
}


const NumericFormatCustomInput = ({ label, error, messageErrorAmount, ...props }: any) => (
  <StylesSurgirTextField
    {...props}
    onChange={(e: any) => {
      if (props.onChange) {
        props.onChange(e);
      }
    }}
    fullWidth={true}
    variant={'filled'}
    label={label}
    error={error}
  />
);

const SurgirTextField: React.FC<SurgirTextFieldProps> = ({
  validateError,
  visibleError,
  value,
  dependValue,
  setValue,
  label,
  fullWidth,
  variant,
  type,
  disabled,
  FormatCustom = false,
  pattern,
  prefix,
  id,
  name,
  isAmountApertura = false
}) => {
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(undefined);
  const [messageErrorAmount, setMessageErrorAmount] = useState('');


  useEffect(() => {
    if (visibleError) {
      handleError(value);

    }
  }, [visibleError]);

  useEffect(() => {
    if (dependValue != undefined) {
      setError(false);
    }
  }, [dependValue]);


  const handleError = (valueTarget: string) => {
    const validation = dependValue
      ? validateError(valueTarget, dependValue.value)
      : validateError(valueTarget);

    setError(validation.error);
    setMessageError(validation.message);
    cleanSpace(valueTarget);
  };

  const cleanSpace = (valueTarget: string) => {
    setValue(valueTarget.trim());
  };

  const handleChange = ({ target }: any) => {
    if (value !== null && target.value.length >= value.length) {
      if (pattern) {
        const data = target.value;
        const dataSubstring = data[data.length - 1];
        const cond1 = pattern.test(data);

        pattern.lastIndex = 0;
        const cond2 = pattern.test(dataSubstring);
        pattern.lastIndex = 0;
        if (cond1 && cond2) {
          setValue(target.value);
          setError(false);
        }
      } else {
        setValue(target.value);
        setError(false);
      }
    } else {
      setValue(target.value);
      setError(false);
    }
  };

  const materialUITextFieldProps = {
    id: "filled-multiline-flexible",
    label: label,
    multiline: false,
    fullWidth: true,
  };

  const getErrorMessage = () => {
    if (!error) return "";
    return messageErrorAmount || 'El campo monto está pendiente';
  };

  return (
    <>
      {!FormatCustom ? (
        <StylesSurgirTextField
          autoComplete="off"
          helperText={error ? messageError : ""}
          error={error}
          value={value}
          onChange={handleChange}
          onClick={(e: any) => {
            if (disabled) {
              handleError(e.target.value);
            }
          }}
          label={label}
          fullWidth={fullWidth}
          variant={variant}
          inputProps={{
            maxLength: 100,
            autoComplete: 'off',
            id: id,
            name: name,
            'data-testid': 'sg-txtfld',
          }}
          type={type}
          disabled={disabled}
          onBlur={() => handleError(value)}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
        />
      ) : (
        <NumericFormat
            customInput={NumericFormatCustomInput}
            error={error}
            helperText={error ? getErrorMessage() : ""}
            {...materialUITextFieldProps}
            prefix={prefix}
            value={value}
            thousandSeparator
            allowNegative={false}
            decimalScale={2}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              if(isAmountApertura) {

                const amount = parseFloat(e.target.value.replaceAll(",", "").replace("S/", ""))
                if (amount < 500) {
                  setError(true)
                  setMessageErrorAmount('El monto mínimo de apertura es de S/. 500.00')
                } else if (amount > 999999999999.99) {
                  setError(true)
                  setMessageErrorAmount('El monto máximo de apertura es de S/. 999,999,999,999.99')
                } else if (Number.isNaN(amount)) {
                  setError(true)
                  setMessageErrorAmount('El campo monto está pendiente')
                }else {
                  setError(false)
                }
              }
            }}
          />
      )}
    </>
  );
};

export { SurgirTextField };
