import React from 'react'
import './style.scss'
import IcFacebook from "./../../../../assets/images/ic_facebook.svg";
import Icaedin from "./../../../../assets/images/ic_linkedin.svg";
import IcYoutube from "./../../../../assets/images/ic_youtube.svg";
import IcInstagram from "./../../../../assets/images/ic_instagram.svg";
export default function NetworkIcons() {
    return (
        <div className="footer-icons"  >
            <div>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/SurgirSantanderMicrofinanzas/"
                >
                    <img alt='' src={IcFacebook} />
                </a>
            </div>
            <div>
                <a
                    href="https://www.youtube.com/@SURGIR-SantanderMicrofinanzas/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img alt='' src={IcYoutube} />
                </a>
            </div>
            <div>
                <a
                    href="https://www.instagram.com/surgir_santandermicrofinanzas"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img alt='' src={IcInstagram} />
                </a>
            </div>
            <div>
                <a
                    href="https://www.linkedin.com/company/surgir-santander-microfinanzas"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img alt='' src={Icaedin} />
                </a>
            </div>

        </div>
    )
}