import React from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcBannerSuccess } from "../../../src/assets/images/ic_banner_success.svg";
import { ReactComponent as IcCloseBanner } from "../../../src/assets/images/ic_close_banner.svg";
import SurgirButton from "../../components/ui/SurgirButton/SurgirButton";
interface ModalSuccessProps {
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
  handleReset: () => void;
  dni: any;
}

const ModalSuccess: React.FC<ModalSuccessProps> = ({
  show,
  handleClose,
  dni,
  handleShow,
  handleReset,
}) => {
  const navigate = useNavigate();
  const handleContinue = () => {
    handleReset()
    handleClose()
    //navigate(`/formulario-dpf-extendido?dni=${dni}`); // Navega a la página del segundo formulario con el valor del dni
  };
  const requestCredit = () => {
    navigate("/abre-tu-deposito-a-plazo-fijo");
    window.scrollTo(0, 0);
  }
  return (
    <div className="success-modal">
      <Modal style={{ marginTop: "8%" }} show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button variant="link" onClick={handleClose}>
              {" "}
              <IcCloseBanner />{" "}
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingLeft: 50, paddingRight: 50 }}>
            <IcBannerSuccess />

            <br />
            <span className="parrafo">¡Hemos recibido tu consulta. Un ejecutivo de SURGIR se estará contactando contigo pronto!</span>
            <br />
            {/*   <br /><span className="subtitle_form"> ¿Desea continuar con el siguiente formulario?</span>*/}  <div>


              <Button
                style={{
                  width: "300px",
                  borderRadius: 32,
                  fontFamily: "Str-Microtext-Bold",
                  fontSize: "16",
                  height: 48,
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 10,
                }}
                variant="danger"
                onClick={handleContinue}
              >
                ¡Listo!{" "}
              </Button>
              {/* <br />
              <a
                className="home-link-rojo"
                style={{
                  fontFamily: "Str-Microtext-Regular",
                  color: "#EC0000",
                }}

                href="/historial-de-promociones"
              >
                Ir a inicio
              </a>
              <br /> */} <br /> <br />
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalSuccess;