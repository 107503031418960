import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './SurgirCarousel.scss'

interface SurgirCarouselProps {
  items: any[]
}

interface ItemProps {
  icon: string;
  title: string;
  description: string;
  link: boolean;
  textLink: string;
  hrefLink: string;
  alert: boolean;
  textAlert: string[];
}

const SurgirCarousel: React.FC<SurgirCarouselProps> = ({
  items
}) => {

  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const highlightText = (description: string, textAlerts: string[]) => {
    const regex = new RegExp(`(${textAlerts.join('|')})`, 'gi');
    const parts = description.split(regex);

    return parts.map((part, index) => (
      textAlerts.includes(part) ?
        <span key={index} className="span-alert">{part}</span> :
        part
    ));
  };

  return (
    <div className="carousel" data-testid="swiper-component">

      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={3}
        navigation={isDesktop}
        pagination={{ clickable: true }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          962: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 0
          }
        }}
      >

        {
          items.map((item: ItemProps) => (
            <SwiperSlide key={item.title}>
              <div className="carousel__card">
                <div>
                  <img src={`icons/carousel/${item.icon}`} alt="cuenta de ahorro" />
                  <h4>{item.title}</h4>
                  <div className="carousel__card__description">
                    <p>
                      {
                        item.link ? item.description.split(item.textLink).map((parte, index) => (
                          <React.Fragment key={parte}>
                            {index > 0 && <a href={item.hrefLink} target="_blank">
                              <span className="span-link">{item.textLink}</span></a>
                            }
                            {parte}
                          </React.Fragment>
                        )) : (
                          item.alert ? highlightText(item.description, item.textAlert) : item.description
                        )
                      }
                    </p>

                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }

      </Swiper>
    </div>
  )
};

export default SurgirCarousel;