import React from "react";
import './SurgirTopBanner.scss'
interface SurgirTopBannerProps {
  isTopScroll: boolean
}

const SurgirTopBanner: React.FC<SurgirTopBannerProps> = ({
  isTopScroll
}) => {

  return (
    <div id="topBanner" className={`top-banner ${isTopScroll ? '' : 'transition-top-banner'}`} data-testid={isTopScroll ? '' : 'transition-top-banner'}>
        <div className='top-banner__container'>
          <div className='top-banner__container__logo'>
            <span>Somos parte de</span>
            <img 
              src="/images/top-banner/santander-icon.svg" 
              alt="santander-icon" 
            />
          </div>
          <a href="https://www.linkedin.com/company/surgir-santander-microfinanzas/">
            Únete a SURGIR
          </a>
        </div>
    </div>
  );
}

export default SurgirTopBanner;