import React from "react";
import "../../../index.scss";
import { SurgirStepperProps } from "./SurgirStepperProps";
const SurgirStepper: React.FC<SurgirStepperProps> = ({
  bgcolor,
  progress = 100,
  height,
  step = 1,
  total = 3,
}) => {
  let rows = [],
    i = 0,
    len = total;
  while (++i <= len) rows.push(i);

  const mediaMatch = window.matchMedia('(min-width: 1080px)');

  const Parentdiv = {
    height: height,
    width: mediaMatch ? "180px" : "100px",
    backgroundColor: "#8F8F8F",
    borderRadius: 40,
    margin: 4,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: `${bgcolor}`,
    borderRadius: 40,
  };

  const ChilddivZero = {
    height: "100%",
    width: `0%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ fontFamily: '"Str-Microtext-Regular", sans-serif' }}>
          Paso {step} de {total}
        </div>
      </div>

      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "8px",
          paddingLeft: "0",
          paddingRight: "0",
        }}
        
      >
        
        {rows.map((item) => (
          <div style={Parentdiv} key={item}  data-item={item}>
            {item <= step ? (
              <div style={Childdiv}></div>
            ) : (
              <div style={ChilddivZero}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SurgirStepper;
