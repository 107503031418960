import React from 'react'
import './style.scss'

export default function AddressDescription() {
    return (
        <div data-testid="footerText" className="footer-bottom-text">
           <p>© Santander Financiamientos S.A. es una marca registrada. Todos los derechos reservados.
            <br />
            Av. Rivera Navarrete 475, Piso 3 - San Isidro, Lima, Perú.
            </p> 
            <p>
            SANTANDER FINANCIAMIENTOS S.A. - RUC 20607400351
            </p>
        </div>
    )
}