import React from "react";
import "./SurgirPrincipalTitle.scss";

interface SurgirPrincipalTitleProps {
    text: any;
    subtext?: string;
    alignText?: TextAlign;
  }

type TextAlign = "left" | "center" | "right";

const SurgirPrincipalTitle: React.FC<SurgirPrincipalTitleProps> = ({ 
    text, 
    subtext,
    alignText,
}) => {
    return (
        <div style={{textAlign: alignText ?? "center"}} >
            
                <h1 className="principal-title">
                    {text}
                </h1>
                {   subtext &&
                    <h2 className="principal-subtitle">
                    {subtext}
                </h2>
                }
        
        </div>
    );
}

export default SurgirPrincipalTitle;