import React from 'react';
import "./SurgirListPlaces.scss"

interface ListPlacesProps {
	title?: string;
	text: string;
	subTitle?: string;
	schedule?: React.ReactNode;
}

const SurgirListPlaces: React.FC<ListPlacesProps> = ({
	title,
	text,
	subTitle,
	schedule
}) => {
	return (
		<div className="list-places-width" style={{ paddingLeft: "40px", paddingBottom: "32px" }}>
			{
				title && <div style={{ fontSize: "18px", color: "#FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif" }}>{ title }</div>
			}
			{
				!title && <div style={{ fontSize: "18px", color: "#FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif" }}>&nbsp;</div>
			}
			<div style={{ fontSize: "16px", color: "#FFFFFF", fontFamily: "Str-Headline-Bold, sans-serif" }}>{ subTitle }</div>
			<div>
				<span className="subtext">{ text }</span>
			</div>
			<div className="subtext">{ schedule }</div>
		</div>
	);
}

export default SurgirListPlaces;