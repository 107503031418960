import React from "react";
import styled from "styled-components";

const Circle = styled.div`
  position: relative;
  background: #3A8340;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;
const CheckmarkWrapper = styled.div`
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 25%;
  top: 45%;
  height: 12px;
  width: 8px;
  border-radius: 2px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
`;

const Checkmark = () => {
  return (
    <Circle>
      <CheckmarkWrapper />
    </Circle>
  );
};

export default Checkmark;
