import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

interface SurgirTabsProps {
  tabs: any,
  hash?: string,
  tabHash?: number
}

interface TabPanelProps {
  readonly  children?: React.ReactNode;
  readonly  dir?: string;
  readonly  index: number;
  readonly  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="box-content-tab" 
          sx={{ 
            padding: '24px 16px',
            borderTop: '1px solid #CEDEE7',
          }}>
          <div style={{
            maxWidth: '1392px',
            margin: 'auto'
          }}>
            {children}
          </div>
        </Box>
      )}
    </div>
  );
}

const SurgirTabs: React.FC<SurgirTabsProps> = ({
  tabs, hash, tabHash = 0
}) => {

  const [value, setValue] = React.useState(0);
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.hash == hash) {
      setValue(tabHash) 
    } 
  }, [])

  const CustomTabs = styled(Tabs)`
   
    & .MuiTabs-indicator {
      background-color: #127277;
      border-radius: 4px 4px 0px 0px;
    },
    & .MuiTabs-root {
      position: relative;
      bottom: -1px;
    },
    & .Mui-selected {
      color: #222222 !important;  
      font-family: 'Str-Microtext-Bold', sans-serif !important;  
    }

    & .MuiButtonBase-root {
      color: #222222;
      font-family: 'Str-Microtext-Regular', sans-serif; 
      font-size: 16px;
      text-transform: initial;
      
    }

    & .MuiTouchRipple-root {
      display: none;
    }

  `;


  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '1392px'
        }}
      >
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="label tabs"
          variant="scrollable"
          scrollButtons="auto"  
        >
          {
            tabs.map((tab: any) => {
              return (
                <Tab 
                  key={tab.title} 
                  label={tab.title}
                  className='tab-ripple'
                  TouchRippleProps={{ classes: { ripple: 'tab-ripple' } }}
                />
              )
            })
          }
        </CustomTabs>
      </Box>

      {
          tabs.map((tab: any, index: number) => {
            return (
              <div
                key={tab.title+'contentTabs'}
              >
                <CustomTabPanel
                  value={value}
                  index={index}
                  dir={theme.direction}>
                  {tab.body}
                </CustomTabPanel>
              </div>
            )
          })
        }
    </>
  );
};

export default SurgirTabs;
