import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as IcClose } from '../../../assets/images/ic_close_confirm.svg';

import React from "react";
import SurgirButton from "../SurgirButton/SurgirButton";

const ContentBody = styled.div` 
 @media (min-width: 768px){  
 }
`;

const ContainerActions = styled.div`
   width: 100%;
   display: flex;
   flex-wrap: wrap;

   @media (min-width: 768px){
    justify-content: end;
    align-items: center;
    flex-direction: row-reverse;
    gap: 16px;
     .btn{
        width: 133px !important;
        height: 48px !important;
        margin-top: 0px !important; 
        margin-bottom: 0px !important; 
    }
    .btn:last-child{
        width: 74px !important;
    }  
   }
`;

interface ModalConfirmProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
}

const SurgirModalConfirm: React.FC<ModalConfirmProps> = ({
    show,
    handleClose,
    onConfirm,
    onCancel
}) => {

    return (

        <Modal
            className="modal-confirm"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Body>
                <div className="container-btn-close">
                    <IcClose onClick={handleClose} />
                </div>

                <ContentBody>
                    <div className="title-modal">¿Eliminar archivo adjunto?</div>
                    <div className="description-confirm">
                        Si eliminas el archivo de evidencia, ya no se enviará.
                    </div>

                    <ContainerActions>
                        <SurgirButton

                            color="red "
                            colorText="white "
                            maxWidth="560px "
                            marginTop={"0px"}
                            padding=""
                            disabled={false}
                            variant="danger"
                            radius="24px"
                            width={"100%"}
                            height={"40px"}
                            marginBottom={"8px"}
                            fontFamily='"Str-Microtext-Regular", sans-serif'
                            onClick={onConfirm}
                            border={undefined}
                        >
                            Si, eliminar
                        </SurgirButton>

                        <SurgirButton
                            color="white "
                            maxWidth="560px"
                            disabled={false}
                            variant="outline"
                            radius="24px"
                            width={"100%"}
                            height={"32px"}
                            marginTop={"0px"}
                            marginBottom={"0px"}
                            fontFamily='"Str-Microtext-Regular", sans-serif'
                            colorText="#c4c4c4"
                            padding={"0px"}
                            onClick={onCancel}
                            border={undefined}
                        >
                            Cancelar
                        </SurgirButton>
                    </ContainerActions>
                </ContentBody>
            </Modal.Body>
        </Modal>

    );
};

export { SurgirModalConfirm };

