import React from 'react';
import { Snackbar } from '@mui/material';
import styled from 'styled-components';
import IcCloseBanner from '../../../assets/images/ic_close_banner.svg';
import IcCloseCircleBanner from '../../../assets/images/ic-error-circle.svg';
import { SurgirSnackbarProps } from './SurgirSnackbarProps';

const ComponentSnackbar = styled.div`
  width: 100%;
  max-width: 288px;
  background: #FDE5E5;
  border: 1px solid #CC0000;
  box-shadow: 0px 4px 8px rgba(29, 37, 45, 0.08);
  border-radius: 8px;
  padding: 12px 16px;

  .header-snack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .title-snack {
    color: #990000;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Str-Microtext-Regular", sans-serif;
  }

  .content-msg {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .msg {
      width: 100%;
      color: #990000;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: "Str-Microtext-Regular", sans-serif;

      div {
        width: 230px;
      }

      svg {
        margin-top: 2px;
      }
    }
  }

  @media (min-width: 600px) {
    & {
      max-width: 552px;
      padding: 12px 21px 16px 16px;
    }
    .content-msg {
      .msg {
        div {
          width: auto;
        }
      }
    }
  }
`;

const snackStyle: React.CSSProperties = {
  width: '100%',
  left: '2px',
};

const SurgirSnackbarContentWrapper: React.FC<SurgirSnackbarProps> = ({
  open,
  onopen,
  messages,
}) => {
  const handleClose = () => {
    onopen(false);
  };

  return (
      <Snackbar
        open={open}
        autoHideDuration={25000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={snackStyle}
        className="snack-mod"
      >
        <ComponentSnackbar>
          <div className='header-snack'>
            <div className='title-snack'>Pendiente</div>
            {/* <IcCloseBanner onClick={handleClose} style={{ cursor: 'pointer' }} /> */}
            {/* <img src={IcCloseBanner} alt="" onClick={handleClose} style={{ cursor: 'pointer' }} /> */}
            <button onClick={handleClose} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
              <img src={IcCloseBanner} alt="" />
            </button>
          </div>

          <div className='content-msg'>
            {messages?.map((msg: {text: string}) => (
              <div className='msg' key={msg.text}>
                <img src={IcCloseCircleBanner} alt="" />
                <div>{msg.text}</div>
              </div>
            ))}
          </div>
        </ComponentSnackbar>
      </Snackbar>
  );
};

export { SurgirSnackbarContentWrapper };