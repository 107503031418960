import React from 'react';

interface ConditionalLinkProps {
    show: boolean;
}

const ConditionalLink: React.FC<ConditionalLinkProps> = ({ 
    show = false 
}) => {
    if (show) {
        return (
            <a
                className="home-link-rojo"
                style={{
                    fontFamily: "Str-Microtext-Regular",
                    color: "#EC0000",
                }}
                href="/simulador"
            >
                Volver a simulador de crédito
            </a>
        );
    }

    return null;
};

export default ConditionalLink;