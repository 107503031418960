import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";

import { StylesSurgirTextField } from "utils/StylesSurgirTextField";
import  SurgirDropDown  from "../../../../../components/ui/SurgirDropDown/SurgirDropDown";
import { styled } from '@mui/material/styles';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/Icons - System - ic_add.svg'
import { ReactComponent as MinusIcon } from '../../../../../../src/assets/images/button_minus.svg'
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./style.scss";
import {
  apiGetTypeInsurance,
  apiGetTypeSuperInsurance,
  apiPostSaveSimulator
} from "../../../../../service/apiEndPoint";


const FormularioSimulacion = ({ visible = true, product, productDetail, onSimularCredito }) => {
  const [monto, setMonto] = useState(1000);
  const [disbursementDate, setDisbursementDate] = useState('');
  const [firstPayDate, setFirstPayDate] = useState('');
  const [numberQuota, setNumberQuota] = useState('');
  const [superInsurance, setSuperInsurance] = useState('');
  const [typeInsurance, setTypeInsurance] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [multiRisk, setMultiRisk] = useState('');
  const [listInsuranceType, setListInsuranceType] = useState([]);
  const [listSuperInsuranceType, setListSuperInsuranceType] = useState([]);
  const [enableButton, setEnableButton] = useState(false)
  useEffect(() => {
    apiGetTypeInsurance()
      .then((response) => {
        return response.json();
      })
      .then((data) =>
        setListInsuranceType(
          data.response.map((item) => {
            return {
              value: item.codeTypeInsurance,
              name: item.typeInsurance,
            };
          })
        )
      )
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    apiGetTypeSuperInsurance()
      .then((response) => {
        return response.json();
      })
      .then((data) =>
        setListSuperInsuranceType(
          data.data.map((item) => {
            return {
              value: item.codeOption,
              name: item.option,
            };
          })
        )
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const listError = validateForm();
    if (listError.length != 0) {
      setEnableButton(false)
    } else {
      setEnableButton(true)
    }
  }, [
    monto,
    disbursementDate,
    firstPayDate,
    numberQuota,
    interestRate,
    superInsurance,
    typeInsurance,
    multiRisk
  ])



  const dataSource = [
    { value: 1, name: 'Sí' },
    { value: 2, name: 'No' },
  ];

  const dataNumberQuota = [
    { value: 1, name: 4 },
    { value: 2, name: 6 }
  ];
  
  const handleMontoChange = (event) => {
    setMonto(event.target.value);
  };

  const handleDisbursementDateChange = (event) => {
    setDisbursementDate(event.target.value);
  };
  const handleFirstPayDateChange = (event) => {
    setFirstPayDate(event.target.value);
  };
  const handleIncrementar = () => {
    setMonto((prevMonto) => prevMonto + 500);
  };

  const handleDecrementar = () => {
    setMonto((prevMonto) => Math.max(0, prevMonto - 500));
  };
  const validateForm = () => {
    const ListError = []
    if (monto == undefined || monto == 0) {
      ListError.push("Se debe ingresar un monto válido")
    }
    if (disbursementDate == undefined || disbursementDate == '') {
      ListError.push("Se debe ingresar un fecha válida")
    }
    if (firstPayDate == undefined || firstPayDate == '') {
      ListError.push("Se debe ingresar un fecha válida")
    }
    if (numberQuota == undefined || numberQuota == '') {
      ListError.push("Se debe ingresar un fecha válida")
    }

    return ListError;
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let request = {}
    if (product.value == '01') {
      request = {
        product: product.name,
        requiredAmount: monto,
        productFamily: productDetail.typeCredit,
        disbursementDate: dayjs(disbursementDate).format("YYYY-MM-DD"),
        firstPaymentDate: dayjs(firstPayDate).format("YYYY-MM-DD"),
        numberQuota: numberQuota?.name,
        interestPeriodPorcentage: productDetail.interestRate,
        typeInsurance: typeInsurance.name,
        multiRisk: multiRisk.value == 1
      };
    } else {
      request = {
        product: product.name,
        requiredAmount: monto,
        productFamily: productDetail.typeCredit,
        disbursementDate: dayjs(disbursementDate).format("YYYY-MM-DD"),
        firstPaymentDate: dayjs(firstPayDate).format("YYYY-MM-DD"),
        numberQuota: parseInt(numberQuota),
        interestPeriodPorcentage: parseFloat(interestRate),
        typeInsurance: typeInsurance.name,
        multiRisk: multiRisk.value == 1
      };
    }

    apiPostSaveSimulator(request)
      .then(response => {
        if (response.status < 300) {
          return response.json()
        } else {
          console.log("Error del servicio")
        }
      })
      .then(data => {
        onSimularCredito({ ...data, formCredit: request });
      })
      .catch(error => {
        console.log("error")
      })


  };


  const PREFIX = 'ComponentAmount'
  const classes = {
    inputWrapper: `${PREFIX}-inputWrapper`
  }
  const DatePickerSurgir = styled(DatePicker)({
    '& label': {
      fontFamily: "Str-Microtext-Regular, sans-serif",
      '&.Mui-focused': {
        color: '#257FA4',
      }
    },
    '& .MuiSvgIcon-root': {
      color: '#EC0000'
    },
    '& .Mui-disabled': {
      backgroundColor: 'rgba(255,255,255,1) !important',
      '&:before': {
        border: '1px solid #CEDEE7 !important',
        lineHeight: 3.3,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        background: 'rgba(255,255,255,0)',
      },
    },
    '& .Mui-focused': {
      backgroundColor: 'rgba(255,255,255,0) !important',
      '&:hover': {
        background: 'rgba(255,255,255,0)',
        '&:after': {
          borderBottom: '2px solid #257FA4',
        },
        '&:before': {
          background: 'rgba(255,255,255,0)',
          borderBottom: '2px solid #257FA4',
        },
        '&:not(.Mui-disabled):before': {
          borderBottom: '1px solid #257FA4',
        },
      },
      '&:active': {
        background: 'rgba(255,255,255,0) !important',
      }
    },
    '& .MuiFilledInput-root': {
      background: 'rgba(255,255,255,0)',
      fontFamily: 'Str-Microtext-Regular,sans-serif',
      '&:hover': {
        background: 'rgba(255,255,255,0)',
        '&:after': {
          borderBottom: '1px solid #257FA4'
        },
        '&:before': {
          background: 'rgba(255,255,255,0)',
          borderBottom: '1px solid #257FA4'
        },
        '&:not(.Mui-disabled):before': {
          borderBottom: '1px solid #257FA4'
        }
      },
      '&:active': {
        background: 'rgba(255,255,255,0) !important',
      },

      '& .Mui-focused': {
        backgroundColor: 'rgba(255,255,255,0) !important',

        '&:hover': {
          background: 'rgba(255,255,255,0)',

          '&:after': {
            borderBottom: '2px solid #257FA4',

          },
          '&:before': {
            background: 'rgba(255,255,255,0)',
            borderBottom: '2px solid #257FA4',

          },
          '&:not(.Mui-disabled):before': {
            borderBottom: '1px solid #257FA4',

          },

        },
        '&:active': {
          background: 'rgba(255,255,255,0) !important',

        }

      },
      '&:before': {
        border: '1px solid #CEDEE7',
        lineHeight: 3.3,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        background: 'rgba(255,255,255,0)',
        borderBottomStyle: 'solid'
      },
      '&:after': {
        borderBottomColor: '#257fa4'
      },

    },
  })
  const ComponentAmount = styled('div')(({ theme }) => ({
    [`&.${classes.inputWrapper}`]: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      border: '1px solid #fff',
      borderRadius: 0,
      background: 'white',
      '& .MuiButton-root': {
        borderRadius: 0,
        width: '40px',
        height: '100%',
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      '& .MuiButton-text': {
        padding: 0,
      },
      '& .MuiInputBase-root': {
        flexGrow: 1,
      }
    }
  })
  )


  return (

    <div className="container__FormularioSimulacion" style={{ display: visible ? 'block' : 'none' }} >
      {product != null && product.value == '01' && <div>
        <ComponentAmount style={{ marginBottom: 16 }} className={classes.inputWrapper}>
          <Button variant="danger" style={{ width: 60, height: 52 }} onClick={handleDecrementar}><MinusIcon /></Button>
          <StylesSurgirTextField
            id="filled-basic"
            value={monto}
            setValue={handleMontoChange}
            label="Monto de Prestamo"
            fullWidth={true}
            variant="filled"
            size="small"
            FormatCustom={true}
            prefix="S/"

          />
          <Button variant="danger" style={{ width: 60, height: 52 }} onClick={handleIncrementar}> <AddIcon /></Button>

        </ComponentAmount>
        <div className="formulario__FormularioSimulacion">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
                <DatePickerSurgir
                  disablePast={true}
                  format={"YYYY-MM-DD"}
                  label="Fecha de desembolso"
                  value={disbursementDate}
                  onChange={(date) => { setDisbursementDate(date) }}
                  slotProps={{ textField: { variant: 'filled' } }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
                <DatePickerSurgir
                  format={"YYYY-MM-DD"}
                  shouldDisableDate={(date) => {
                    const diffDays = dayjs(date).diff(dayjs(disbursementDate), 'days', true)
                    return !(date['$D'] >= 2 && date['$D'] <= 24 && (diffDays <= 45 && diffDays >= 23))
                  }}
                  label="Fecha  de Primer pago"
                  value={firstPayDate}
                  onChange={setFirstPayDate}
                  slotProps={{ textField: { variant: 'filled' } }} />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <SurgirDropDown
              hint={"Cantidad de Cuotas"}
              dataSource={dataNumberQuota}
              value={numberQuota}
              onChange={setNumberQuota}
              label="Apellidos"
              fullWidth={true}
              variant="filled"

            />

          </div>

          <div>
            <SurgirDropDown
              hint={"Tipo de Seguro de Degravamen "}
              dataSource={listInsuranceType}
              value={typeInsurance}
              onChange={setTypeInsurance}
              label="Apellidos"
              fullWidth={true}
              variant="filled"

            />

          </div>
          <div
          ><SurgirDropDown
              hint={"Plan Super Seguro "}
              dataSource={listSuperInsuranceType}
              label="Apellidos"
              value={superInsurance}
              onChange={setSuperInsurance}
              fullWidth={true}
              variant="filled"

            />
          </div>
          <div >
            <SurgirDropDown
              hint={"Agregar Seguro Multirriesgo "}
              dataSource={dataSource}
              value={multiRisk}
              onChange={setMultiRisk}
              label="Apellidos"
              fullWidth={true}
              variant="filled"

            />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>}
      {product != null && product.value != '01' && <div>
        <ComponentAmount style={{ marginBottom: 16 }} className={classes.inputWrapper}>
          <Button variant="danger" style={{ width: 60, height: 52 }} onClick={handleDecrementar}><MinusIcon /></Button>
          <StylesSurgirTextField
            id="filled-basic"
            value={monto}
            setValue={handleMontoChange}
            label="Monto de Prestamo"
            fullWidth={true}
            variant="filled"
            size="small"
            FormatCustom={true}
            prefix="S/"

          />
          <Button variant="danger" style={{ width: 60, height: 52 }} onClick={handleIncrementar}> <AddIcon /></Button>

        </ComponentAmount>
        <div className="formulario__FormularioSimulacionIndividual">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
                <DatePickerSurgir
                  disablePast={true}
                  label="Fecha de desembolso"
                  value={disbursementDate}
                  onChange={(date) => { setDisbursementDate(date) }}
                  slotProps={{ textField: { variant: 'filled' } }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
                <DatePickerSurgir
                  shouldDisableDate={(date) => {
                    const diffDays = dayjs(date).diff(dayjs(disbursementDate), 'days', true)
                    return !(date['$D'] >= 2 && date['$D'] <= 24 && (diffDays <= 45 && diffDays >= 23))
                  }}
                  label="Fecha  de Primer pago"
                  value={firstPayDate}
                  onChange={setFirstPayDate}
                  slotProps={{ textField: { variant: 'filled' } }} />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <StylesSurgirTextField
              id="filled-basic"
              value={numberQuota}
              setValue={setNumberQuota}
              label="Cantidad de Cuotas"
              fullWidth={true}
              variant="filled"
              pattern={/[\d]/}
              validateError={(value) => {
                if (value > 0 && value < 36) {
                  return {
                    error: false,
                    message: ""
                  }
                } else {
                  return {
                    error: true,
                    message: "Cantidad de cuotas no permitidas"

                  }
                }
              }}

            />
        {/*     <div style={S.divTextOptional}>
              <span style={S.spanDiv}>Rango mínimo de 3 a máximo 36 cuotas</span>
            </div> */}
          </div>

          <div>
            <StylesSurgirTextField
              id="filled-basic"
              value={interestRate}
              setValue={setInterestRate}
              label="Tasa de interés"
              fullWidth={true}
              variant="filled"
              validateError={(value) => { return { error: false } }}
            />
           {/*  <br/>
            <div style={S.divTextOptional}>
              <span style={S.spanDiv}>Rango mínimo 15% y máximo 87.4%</span>
            </div> */}
          </div>

          <div>
            <SurgirDropDown
              hint={"Tipo de Seguro de Degravamen "}
              dataSource={listInsuranceType}
              value={typeInsurance}
              onChange={setTypeInsurance}
              label="Apellidos"
              fullWidth={true}
              variant="filled"

            />

          </div>
          <div
          ><SurgirDropDown
              hint={"Plan Super Seguro "}
              dataSource={listSuperInsuranceType}
              label="Apellidos"
              value={superInsurance}
              onChange={setSuperInsurance}
              fullWidth={true}
              variant="filled"

            />
          </div>

        </div>
        <br />
        <div >
          <SurgirDropDown
            hint={"Agregar Seguro Multirriesgo "}
            dataSource={dataSource}
            value={multiRisk}
            onChange={setMultiRisk}
            label="Apellidos"
            fullWidth={true}
            variant="filled"

          />
        </div>
        <br />
        <br />
        <br />
      </div>}
      
      <div>
        <center>

          <Button
            style={{
              width: 180,
              borderRadius: 32,
              fontFamily: "Str-Microtext-Bold",
              fontSize: "16",
              height: 48,
              textAlign: "center",
              marginBottom: 50,
            }}
            variant="danger"
            onClick={(event) => { handleSubmit(event) }}
            disabled={!enableButton}
          >
            {" "}
            Simular crédito{" "}
          </Button>

        </center>
      </div>

    </div >
  );
};

export default FormularioSimulacion;