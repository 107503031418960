import React, { useEffect, useState } from "react";
import { RadioGroup, Radio, FormControl, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { SurgirCardRadioGroupProps } from "./SurgirCardRadioGroupProps";


const CardRadioGroup = styled.div`
  display: flex;
  min-width: 288px;
  width: 100%;
  max-width: 552px;
  height: 64px;
  background: #ffffff;
  border-radius: 16px;
  padding: 1rem 1rem;
  cursor: pointer;
`;

const styleRadioGroup: React.CSSProperties = {
  alignItems: "center",
  padding: "0 16px",
  gap: "16px",
};

const FormControlstyle: React.CSSProperties = {
  width: "100%",
};

const styleControlRadio: React.CSSProperties = {
  margin: 0,
};

const SurgirCardRadioGroup: React.FC<SurgirCardRadioGroupProps> = ({
  selected,
  radioData,
  defaultValue,
}) => {
  const [value, setValue] = useState<string>("1");

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    const dataselect = {
      id: parseInt(event.target.value),
      name: event.target.name,
    };
    selected(dataselect);
  };

  const radioclick = (id: number) => {
    const element = document.getElementById(`${id}`);
    element?.click();
  };

  return (
    <FormControl style={FormControlstyle}>
      <RadioGroup
        aria-label="radio"
        name="radio"
        value={value}
        onChange={handleChange}
        style={styleRadioGroup}
      >
        {radioData.map((radio) => (
          <CardRadioGroup
            data-testid={`container-${radio.id}`}
            id={`container-${radio.id}`}
            key={radio.id}
            style={{
              border:
                value === radio.id.toString()
                  ? "2px solid #257fa4"
                  : "1px solid #CEDEE7",
            }}
            onClick={() => radioclick(radio.id)}
          >
            <FormControlLabel
              value={radio.id.toString()}
              name={radio.name}
              control={<Radio id={radio.id.toString()} />}
              label={radio.name}
              style={styleControlRadio}
              className="radio-label-mo"
            />
          </CardRadioGroup>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export { SurgirCardRadioGroup };
