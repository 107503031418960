import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import './SurgirTabsHome.scss'

interface SurgirTabsProps {
  tabs: any
}

interface TabPanelProps {
  readonly  children?: React.ReactNode;
  readonly  dir?: string;
  readonly  index: number;
  readonly  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="box-content-tab-home" sx={{ padding: '64px 0px', borderBottom: '1px solid #CEDEE7' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const SurgirTabsHome: React.FC<SurgirTabsProps> = ({
  tabs
}) => {

  const [value, setValue] = React.useState(0);

  const [fadeIn, setFadeIn] = React.useState(true); 

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFadeIn(true);
    setValue(newValue);
  };


  const CustomTabs = styled(Tabs)`
    & .MuiTabs-indicator {
      background-color: #EC0000;
      border-radius: 4px 4px 0px 0px;
    },
    & .MuiTabs-root {
      position: relative;
      bottom: -1px;
    },
    & .Mui-selected {
      color: #222222 !important;  
      font-family: 'Str-Microtext-Bold', sans-serif !important;  
    },
    & .Mui-selected svg path {
      fill: #EC0000;
    }

    & .MuiButtonBase-root {
      color: #222222;
      font-family: 'Str-Microtext-Regular', sans-serif; 
      font-size: 16px;
      text-transform: capitalize;
      width: 160px;
    }

    & .MuiTouchRipple-root {
      display: none;
    }

  `;

 

  const theme = useTheme();


  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: '#CEDEE7',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '40px'
        }}
      >
        <CustomTabs
          className='custom-tabs-home'
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs">
          {
            tabs.map((tab: { iconTab: any, titleTab: string }) => {
              return (
                <Tab 
                  key={tab.titleTab+'iconTab'}
                  icon={tab.iconTab} 
                  label={tab.titleTab}
                  className='tab-ripple'
                  TouchRippleProps={{ classes: { ripple: 'tab-ripple' } }}
                />
              )
            })
          }
        </CustomTabs>
      </Box>

      {
          tabs.map((tab: { contentTab: any, titleTab: string, iconTab: string }, index: number) => {
            return (
              <div 
                key={tab.titleTab+'contentTab'}
                className={`content-tab-home ${fadeIn && value === index ? 'fade-in' : ''}`}  
              >
                <CustomTabPanel
                  value={value}
                  index={index}
                  dir={theme.direction}>
                  {tab.contentTab}
                </CustomTabPanel>
              </div>
            )
          })
        }
    </>
  );
};

export default SurgirTabsHome;
