import React from "react";
import "./SurgirItemCard.scss";

interface SurgirItemCardProps {
  position?: string;
  text: string ;
  title?: any;
  image: any;
  width: number;
}


const SurgirItemCard: React.FC<SurgirItemCardProps> = ({ 
  position, 
  text, 
  title, 
  image, 
  width 
}) => {
  return (
    <div style={{ width: `${width}%`, margin: "0 auto" }}>
      <div style={{ textAlign: "center" }}>
        <span className="position-item-card">
          {position && (
            <span>{position}</span>
          )}
          <span>{image}</span>
        </span>
        <span className="title-item-card">{title}</span>
      </div>
      <div className="paragraph">
        {text}
      </div>
    </div>
  );
};

export default SurgirItemCard;