import React from "react";

interface TrashIconProps {
  size?: number;
  color?: string;
}

const TrashIcon: React.FC<TrashIconProps> = ({ size = 18, color = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 20"
      fill= "none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      fillRule="evenodd" clipRule="evenodd" 
        d="M14.5547 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H15V16.024C15 18.2182 13.2211 19.997 11.027 19.997H4.973C2.77886 19.997 0.999999 18.2182 0.999999 16.024V4H0.5C0.223858 4 0 3.77614 0 3.5C0 3.22386 0.223858 3 0.5 3H1.44528C1.46325 2.99804 1.48151 2.99704 1.5 2.99704C1.51849 2.99704 1.53675 2.99804 1.55472 3H4V1.998C4 0.894064 4.89365 0 5.997 0H10.003C11.1063 0 12 0.894064 12 1.998V3H14.4453C14.4633 2.99804 14.4815 2.99704 14.5 2.99704C14.5185 2.99704 14.5367 2.99804 14.5547 3ZM14 4H11.5H4.5H2V16.024C2 17.6659 3.33114 18.997 4.973 18.997H11.027C12.6689 18.997 14 17.6659 14 16.024V4ZM5 3V1.998C5 1.44625 5.44604 1 5.997 1H10.003C10.554 1 11 1.44625 11 1.998V3H5ZM4 6.5V16.5C4 16.7761 4.22386 17 4.5 17C4.77614 17 5 16.7761 5 16.5V6.5C5 6.22386 4.77614 6 4.5 6C4.22386 6 4 6.22386 4 6.5ZM7.5 16.5V6.5C7.5 6.22386 7.72386 6 8 6C8.27614 6 8.5 6.22386 8.5 6.5V16.5C8.5 16.7761 8.27614 17 8 17C7.72386 17 7.5 16.7761 7.5 16.5ZM11 6.5V16.5C11 16.7761 11.2239 17 11.5 17C11.7761 17 12 16.7761 12 16.5V6.5C12 6.22386 11.7761 6 11.5 6C11.2239 6 11 6.22386 11 6.5Z"
        fill= {color}
      />
    </svg>
  );
}

export default TrashIcon;
