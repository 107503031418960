export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

export const goTopTab = (idTab) => {
  const bodyHeight = document.documentElement.offsetHeight;
  const footerHeight = document.getElementById('foot-srg')?.offsetHeight;
  const sectionTabHeight = document.getElementById(idTab)?.offsetHeight;
  (sectionTabHeight && footerHeight) && (window.scrollTo({top: bodyHeight - (sectionTabHeight + footerHeight)}));
}