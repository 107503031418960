import React, { FC, SVGProps } from "react";
import { Accordion } from "react-bootstrap";
import IcArrowRight from "./../../../../assets/images/Icono flecha derecha.svg";
import "./AccordionChannelsSurgir.scss";
import SurgirListIconChannelBlack from "components/ui/SurgirListIconChannelBlack/SurgirListIconChannelBlack";

interface AccordionChannelsSurgirProps {
	items: {
	  icon: FC<SVGProps<SVGSVGElement>>;
	  title: string;
	  titleLink: string;
	  link: string;
	  list: string[];
	  subList: string[];
	  id: number;
	}[];
	children?: React.ReactNode;
  }
  
  const AccordionChannelsSurgir: React.FC<AccordionChannelsSurgirProps> = ({ items, children }) => {
	return (
	  <Accordion className="box-accordion">
		{items.map((item, index:any) => {
				return (
					<Accordion.Item key={item.id} className="box-accordion__item" eventKey={index}>
						<Accordion.Header  className="box-accordion__item__header">
							<SurgirListIconChannelBlack Icon={item.icon} name={item.title} />
						</Accordion.Header>

						<Accordion.Body className="box-accordion__item__body">
							{
								item.titleLink != '' && 
									<a target="_blank" href={item.link} rel="noopener noreferrer">
										<span > {item.titleLink}</span>
										<img src={IcArrowRight} className="box-accordion__item__body__arrow" alt=""/>
									</a>
							}

							<ul className="box-accordion__item__body__list">
								{
									item.list.map((itemList:any ) => {
										return (
												<div key={itemList}>
													<li key={itemList+item.id} className="box-accordion__item__body__list__item">{itemList}</li>
													<ul className="box-accordion__item__body__sub-list">
														{ itemList === 'Atención:' && item.subList.map((subItem:any) => {
															return (
																<li key={subItem+item.id} className="box-accordion__item__body__sub-list__sub-item">{subItem}</li>
															);

														})}
													</ul>
												</div>
										)
									})
								}
							</ul>

							
						</Accordion.Body>


					</Accordion.Item>
				)
				
			})}
		</Accordion>
	);
};


export default AccordionChannelsSurgir