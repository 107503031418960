import React from "react";
import { useEffect, useState } from "react";
import { apiGetDialCode, apiGetFindUbigeoDep, apiPostFindUbigeo } from "service/apiEndPoint";
import { Col, Row } from "react-bootstrap";
import { ContactData, titleRadioAdress, titleRadioClaim, titleRadioRequirements } from "views/complaints-book/ComplaintsBook.fixture";
import SurgirDropDown from "components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirTextField } from "components/ui/SurgirTextField/SurgirTextField";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import SurgirAutocomplete from "components/ui/SurgirAutocomplete/SurgirAutocomplete";
import { validateEmail, validateFieldObject, validatePhone, validateRequired } from "utils/ValidateField";
import './FormContactInformation.scss'

interface FormContactInformationProps {
  formData: any,
  setformData: any,
  showValidation: any,
  setShowValidation: any,
} 
const FormContactInformation: React.FC<FormContactInformationProps> = ({
  formData,
  setformData,
}) => {
  const [listCodeCountry, setListCodeCountry] = useState([]);
  const [listDeparments, setListDeparments] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [visibleValidationDepartment, setVisibleValidationDepartment] =
    useState(false);
  const [visibleValidationProvincia, setVisibleValidationProvincia] =
    useState(false);
  const [visibleValidationDistrito, setVisibleValidationDistrito] =
    useState(false);

  const [visibleValidation] = useState(false);
  const typeForm = sessionStorage.getItem("typeForm");

  useEffect(() => {
    apiGetFindUbigeoDep()
      .then((response) => {
        return response.json();
      })
      .then((data) =>
        setListDeparments(
          data.result.map((item: {codeDep: string, departament: string}) => {
            return {
              value: item.codeDep,
              name: item.departament,
            };
          })
        )
      )
      .catch((err) => console.log(err));

    DialsCode();

    return () => {};
  }, []);

  const DialsCode = () => {
    apiGetDialCode()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setListCodeCountry(
          data.data.map((item: any) => {
            return {
              value: item.code,
              name: `${item.name_es} ${item.dial_code}`,
            };
          })
        );
        setformData({
          ...formData,
          dialCode: { value: "PE", name: "Perú (+51)" },
        });
      })

      .catch((err) => console.log(err));
  };

  const onChangeDepartament = (e: any) => {
    setformData({ ...formData, department: e, province: null, district: null });
    setListProvince([]);
    setListDistrict([]);
    const request = {
      codeDep: e.value,
      codeProv: "",
      codeDist: "",
    };
    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListProvince(
          data.result.responseProvince.map((item: {codeProv : string, province: string}) => {
            return {
              value: item.codeProv,
              name: item.province,
            };
          })
        )
      )
      .catch((error) => console.log(error));
    setVisibleValidationProvincia(false);
  };

  const onChangeProvince = (value: any) => {
    setformData({ ...formData, province: value, district: null });
    setListDistrict([]);
    const request = {
      codeDep: "",
      codeProv: value.value,
      codeDist: "",
    };
    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListDistrict(
          data.result.responseDistrict.map((item : {codeDist: string, district: string}) => {
            return {
              value: item.codeDist,
              name: item.district,
            };
          })
        )
      )
      .catch((error) => console.log(error));
    setVisibleValidationDistrito(false);
  };

  const onClickProvincia = () => {
    if (
      formData.department === undefined ||
      formData.department === null ||
      formData.department === ""
    ) {
      setVisibleValidationProvincia(true);
    }
  };

  const onClickDistrito = () => {
    if (
      formData.province === undefined ||
      formData.province === null ||
      formData.province === ""
    ) {
      setVisibleValidationDistrito(true);
    }
  };

  const handleChangeContactAddress = (event: any) => {
    if(event.target.value==0){
      setformData({ ...formData, localCustomer: parseInt(event.target.value) , responseChannel: "Correo electrónico"  });
    }else{
      setformData({ ...formData, localCustomer: parseInt(event.target.value) });

    }
  };

  return (
    <div>
      <div className="container" style={{ padding: 0 }}>
        <div className="container-form-request">
          <div className="container-static-request ">
            <Row>
              <span className="title">{ContactData}</span>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, width: "100%" }}>
              <Col style={{ padding: "0px 10px 0px 0px" }}>
                <SurgirDropDown
                  id="selectCountryCode"
                  name="select-country-code"
                  hint={"Código de país"}
                  dataSource={listCodeCountry}
                  error={
                    visibleValidation &&
                    validateFieldObject(formData.dialCode).error
                  }
                  messageError={validateFieldObject(formData.dialCode).message}
                  value={formData.dialCode}
                  onChange={(e) => setformData({ ...formData, dialCode: e })}
                />
              </Col>
              <Col style={{ padding: "0px 0px 0px 10px" }}>
                <SurgirTextField
                  id="txtPhone"
                  name="input-phone"
                  value={formData.phone}
                  label="Número de celular"
                  fullWidth={true}
                  variant="filled"
                  type="text"
                  pattern={/^(\d{0,13})$/g}
                  validateError={validatePhone}
                  setValue={(e) => setformData({ ...formData, phone: e })}
                />
              </Col>
            </Row>

            <SurgirTextField
              id="txtOtherPhone"
              name="input-other-phone"
              value={formData.otherPhone}
              label="Otro teléfono"
              fullWidth={true}
              variant="filled"
              type="text"
              pattern={/^(\d{0,13})$/g}
              validateError={() => {
                return { error: false, messageError: '' };
              }}
              setValue={(e) => setformData({ ...formData, otherPhone: e })}
            />

            <div className="text-optional">
              <span >Opcional</span>
            </div>

            <SurgirTextField
              id="txtEmail"
              name="input-email"
              value={formData.email}
              label="Correo electrónico"
              fullWidth={true}
              variant="filled"
              type="text"
              validateError={validateEmail}
              setValue={(e) => {
                setformData({ ...formData, email: e });
              }}
              pattern={/[^\s]/}
            />

            <div className="mt-paso2-radio-button container-radio-button-request">
              <span id="titleRadio">{titleRadioAdress}</span>

              <RadioGroup
                id="rbPeruAddress"
                name="radiobutton-peru-address"
                aria-label="radio"
                value={formData.localCustomer}
                onChange={handleChangeContactAddress}
                row
              >
                <FormControlLabel
                  value={1}
                  name="Sí"
                  control={<Radio />}
                  label="Sí"
                  className="radio-label-regular"
                />

                <FormControlLabel
                  value={0}
                  name="No"
                  control={<Radio />}
                  label="No"
                  className="radio-label-regular"
                />
              </RadioGroup>
            </div>

            {formData.localCustomer === 1 && (
              <>
                <div className="column-request">
                  <SurgirAutocomplete
                    error={
                      visibleValidationDepartment &&
                      validateFieldObject(formData.department).error
                    }
                    messageError={
                      validateFieldObject(formData.department).message
                    }
                    hint={"Departamento"}
                    value={formData.department}
                    onChange={onChangeDepartament}
                    fullWidth={true}
                    dataSource={listDeparments}
                    required={false}
                    search={"name"}
                    id={"selectDepartment"}
                    name={"select-department"}
                  />
                </div>
                <div className="column-request">
                  <SurgirAutocomplete
                    error={
                      (visibleValidationDepartment ||
                        visibleValidationProvincia) &&
                      validateFieldObject(formData.province).error
                    }
                    messageError={
                      visibleValidationProvincia
                        ? "Elige primero un departamento."
                        : validateFieldObject(formData.province).message
                    }
                    hint={"Provincia"}
                    value={formData.province}
                    onChange={onChangeProvince}
                    onClick={onClickProvincia}
                    dataSource={listProvince}
                    required={false}
                    search={"name"}
                    disabled={listProvince.length == 0}
                    id={"selectProvince"}
                    name={"select-province"}
                  />
                </div>
                <div className="column-request">
                  <SurgirAutocomplete
                    error={
                      (visibleValidationDepartment ||
                        visibleValidationDistrito) &&
                      validateFieldObject(formData.district).error
                    }
                    messageError={
                      visibleValidationDistrito
                        ? "Elige primero una provincia."
                        : validateFieldObject(formData.district).message
                    }
                    hint={"Distrito"}
                    value={formData.district}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        district: e,
                      });
                    }}
                    onClick={onClickDistrito}
                    dataSource={listDistrict}
                    required={false}
                    disabled={listDistrict.length == 0}
                    search={"name"}
                    id={"selectDistrict"}
                    name={"select-district"}
                  />
                </div>
                <SurgirTextField
                  id="txtAddress"
                  name="input-address"
                  value={formData.addressName}
                  label="Dirección"
                  fullWidth={true}
                  variant="filled"
                  type="text"
                  validateError={validateRequired}
                  setValue={(e) => {
                    setformData({ ...formData, addressName: e });
                  }}
                />
                <SurgirTextField
                  id="txtReference"
                  name="input-reference"
                  value={formData.reference}
                  label="Referencia"
                  fullWidth={true}
                  variant="filled"
                  type="text"
                  validateError={validateRequired}
                  setValue={(e) => {
                    setformData({ ...formData, reference: e });
                  }}
                />
              </>
            )}

            <div className="container-radio-button-request">
              <span id="titleRadio">
                {typeForm === "1" ? titleRadioClaim : titleRadioRequirements}
              </span>
              <RadioGroup
                id="rbReceiveResponse"
                name="radiobutton-receive-response"
                aria-label="radio"
                value={formData.responseChannel}
                onChange={(e) =>
                  setformData({ ...formData, responseChannel: e.target.value })
                }
                row
              >
                <FormControlLabel
                  value="Correo electrónico"
                  name="correo"
                  control={<Radio />}
                  label="Correo electrónico"
                  id="optionEmail"
                  className="radio-label-regular"
                />

                <FormControlLabel
                  value="Envío a dirección de contacto"
                  name="direccionContrato"
                  control={<Radio />}
                  label="Envío a dirección de contacto"
                  disabled={!formData.localCustomer}
                  className="radio-label-regular"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormContactInformation