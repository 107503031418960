import React from 'react';
import './SurgirHeadline.scss'

interface SurgirHeadlineProps {
  title: string;
  align?: 'left' | 'center' | 'right';
  subtitle?: string;
}

const SurgirHeadline: React.FC<SurgirHeadlineProps> = ({
  title, align = 'center', subtitle
}) => {
  return (
    <div style={{ textAlign: align }}>
      <div className='surgir-title'
      >{title}</div>
      <div data-testid='surgir-subtitle' className='surgir-subtitle'>{subtitle}</div>
    </div>
  );
};

export default SurgirHeadline;