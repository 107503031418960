import { Autocomplete, Chip, FormHelperText } from "@mui/material";
import React from "react";
import ChevronDown from '../../../assets/images/ic_arrowDown.svg';
import  ChevronDownDisabled from '../../../assets/images/ic_arrowDown_disabled.svg';
import { StylesSurgirTextField } from "utils/StylesSurgirTextField";

interface SurgirAutocompleteProps {
  error: boolean;
  messageError: string;
  required: boolean;
  dataSource: any;
  hint: string;
  value: any;
  onChange: (value: any) => void;
  onClick?: () => void;
  search: string;
  disabled?: boolean; 
  modeDropdow?: boolean,
  onBlur?: any;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  classContainer?: string;
  classAutocomplete?: string;
}

const SurgirAutocomplete : React.FC<SurgirAutocompleteProps> = ({ 
  error,
  messageError,
  required,
  dataSource,
  hint,
  value,
  onChange,
  onClick,
  search,
  disabled, 
  modeDropdow=false,
  onBlur,
  id,
  name,
  classContainer,
  classAutocomplete
}) => {
  return (
    <div className={classContainer}>
      <Autocomplete 
        style={{ margin: '8px 0 8px 0' }}
        id={id}
        size="medium"
        className={classAutocomplete}
        autoComplete={false}
        options={dataSource}
        noOptionsText={''}
        disabled={disabled}
        isOptionEqualToValue={(option: any,value) => {
          for(let key in option){
            const check = option[key] == value[key]
            if(!check){
              return check;
            }
          }
          return true;
        }}
        getOptionLabel={(option) => option[`${search}`]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option[`${search}`]}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        value={value}
        disableClearable
        popupIcon={
          !disabled ? <img alt="" src={ChevronDown} style={{margin:'0px 10px'}}/> : <img alt="" src={ChevronDownDisabled} style={{margin:'0px 10px'}}/>
        }
        onChange={(e, value) => {if(!modeDropdow) {onChange(value)}}}
        onBlur={onBlur}
        renderInput={(params) => (
          <StylesSurgirTextField
            {...params}
            required={required}
            variant="filled"
            label={hint}
            error={error}
            disabled={disabled}
            onClick= { onClick}
            name={name}
          />
        )}
      />
        {error && <FormHelperText error style={{marginLeft:'0.813rem'}} >{messageError}</FormHelperText>}

      </div>

  )
}

export default SurgirAutocomplete