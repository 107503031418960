import React from 'react';
import { createLink } from "utils/urls";

interface SurgirItemListWithIconProps {
    icon: any;
    document: any;
    name: any;
    downloadName: string;
}
const SurgirItemListWithIcon: React.FC<SurgirItemListWithIconProps> = ({ icon, document, name, downloadName }) => {
    return (
        <div style={{ margin: "20 0 20 0", display: 'flex', alignItems: 'center' }} >
            <div>
                <img alt='icon' src={icon}  />
            </div>
            <div style={{ paddingLeft: 10 }}>
                <a className="Descarga"
                    download={downloadName}
                    onClick={() => createLink(document ?? "", "_blank")}
                >
                    {name}
                </a>
            </div>
        </div>
    );
}
export default SurgirItemListWithIcon;