import SurgirItemListWithIcon from "components/ui/SurgirItemListWithIcon/SurgirItemListWithIcon";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DownloadIcon from '../../assets/images/Icons_descargar.svg'
import './Legal.scss'

const Legal: React.FC = () => {

  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const [tabName, setTabName] = useState('profile')

  const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};

  useEffect(() => {
    if(location.hash == '#promociones') {
      setTabName('promociones')
    } else {
      setTabName('profile')
    }

    const searchParams = new URLSearchParams(location.search);
    const scrollToId = searchParams.get('scrollToId');

    if (scrollToId) {
      const element = document.getElementById(scrollToId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }, [location.state])

  const tratamientoRef = useRef<HTMLDivElement>(null); 

  useEffect(() => {
    const handleScrollToTratamiento = () => {
      if (window.location.hash === "#tratamiento-datos" && tratamientoRef.current) { 
        setTimeout(() => {
          const yOffset = tratamientoRef.current!.getBoundingClientRect().top + window.pageYOffset+100;
          window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }, 100);
      }
    };

    handleScrollToTratamiento();
    window.addEventListener('hashchange', handleScrollToTratamiento);

    return () => {
      window.removeEventListener('hashchange', handleScrollToTratamiento);
    };
  }, []);


  const terminosycondicionesRef = useRef<HTMLDivElement>(null);
  const listaProveedoresRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const hash = window.location.hash;

    if (hash === "#terminos-y-condiciones" && terminosycondicionesRef.current) {
      setTimeout(() => {
        const terms = document.getElementById('termsConditions')?.offsetTop ?? 0;
        const topBanner = document.getElementById('topBanner')?.scrollHeight ?? 0;

        window.scrollTo({top: (terms - (56 + (scrollPosition === 0 ? topBanner : 0)))})
      }, 200);
    }

    if (hash === "#lista-proveedores" && listaProveedoresRef.current) {
      setTimeout(() => {
        const terms = document.getElementById('listProviders')?.offsetTop ?? 0;
        const topBanner = document.getElementById('topBanner')?.scrollHeight ?? 0;

        window.scrollTo({top: (terms - (56 + (scrollPosition === 0 ? topBanner : 0)))})
      }, 200);
    }
  }, []);

  return (
    <div className="contentLegal">  
      <div className="fq-header wrapper" style={{ paddingTop: 93 }}>
        <span className="fq-title">Legales </span>
      </div>

      <div style={{maxWidth: 1392, margin: 'auto', padding: 16}}>

        <div className="title-legal">
          Protección de datos
        </div>
        <Row>
              <Col md={4}>
                <p className="Columna">Política de Privacidad</p>
              </Col>
              <Col md={8}>
                <a
                  style={{
                    fontFamily: "Str-Headline-Bold",
                    color: "#444444",
                  }}
                >
                  {" "}
                  I. SANTANDER FINANCIAMIENTOS S.A{" "}
                </a>{" "}
                (en adelante "SURGIR"), con RUC N° 20607400351, con domicilio en Avenida Rivera Navarrete N° 475 - Piso 3, distrito de San Isidro, provincia y departamento de Lima, será responsable del tratamiento y seguridad de los datos personales que USTED proporcione, la cual incluye lo ingresados a través del sitio web www.surgir.com.pe, los que estarán almacenados con todas las medidas de seguridad y confidencialidad en el banco de datos personales de titularidad de SURGIR.
                <br />
                La información personal privada no incluye datos que están disponibles a través de fuentes accesibles al público. Se entiende por tales, los medios de comunicación electrónica y de otra tecnología concebidos para facilitar información al público y abiertos a la consulta general, las guías telefónicas, los diarios y revistas, los medios de comunicación social, las listas profesionales, los repertorios de jurisprudencia anonimizados, los Registros Públicos administrados por la Superintendencia Nacional de Registros Públicos así como todo otro registro o banco de datos calificado como público conforme a ley, y la información que ostentan las entidades de la Administración Pública y que deba ser entregada a los administrados en aplicación de la Ley N° 27806 - Ley de Legales y Acceso a la Información Pública.
                <br />
                <br />
                <span className="subtitulos">II. OBJETIVO DE LA INFORMACIÓN</span>
                <br />
                <br />
                Para SURGIR es importante la privacidad de sus datos personales, es por ello que, para protegerlos, les brindamos esta Política de Privacidad, la cual tiene por objetivo dar a conocer la manera en que se recogen, se tratan y se protegen los datos personales, así como las acciones que usted puede tomar con relación a ellos


                <br />
                <br />
                <br />
                <span className="subtitulos">
                  III. FINALIDADES NECESARIAS PARA LA RELACIÓN CONTRACTUAL O
                  PRE-CONTRACTUAL
                </span>
                <br />
                <br />
                Los datos personales que recopila SURGIR estarán relacionados con una finalidad determinada, explícita y lícita. El tratamiento de los datos personales realizado por SURGIR no se extenderá a otra finalidad que no haya sido la establecida de manera inequívoca como tal al momento de su recopilación, excluyendo los casos de actividades de valor histórico, estadístico o científico cuando se utilice un procedimiento de disociación o anonimización.
                <br />
                Los datos personales proporcionados por USTED serán utilizados para las siguientes finalidades: (a) preparación, celebración y ejecución de la relación contractual derivada de la solicitud y/o contratación de nuestros productos; (b) verificar, confirmar y validar su identidad; y, (c) cumplimiento de requerimientos legales o regulatorios, conforme a la legislación de la materia.
                <br />
                <br />
                <br />
                <span className="subtitulos">
                  IV. FINALIDADES ADICIONALES (FINES COMERCIALES – REQUIERE
                  CONSENTIMIENTO EXPRESO)
                </span>
                <br />
                <br />
                De otorgar su consentimiento, sus datos personales podrán ser utilizados por SURGIR para las siguientes finalidades:
                <br /><span style={{ color: "#ec0000", fontStyle: "bold" }}>
                  -{" "}
                </span>	Contactarlo y ofrecerle nuestros diverso(s) producto(s) y/o servicio(s), y, por tanto, pudiéndole enviar ofertas y/o beneficios comerciales, publicidad e información general que tenga SURGIR y/o de cualquier tercero (socios estratégicos y/o socios comerciales), a través de cualquier mecanismo (telefónico, mensaje de texto, correo electrónico, aplicativos de mensajes instantáneos, redes sociales, página web, directamente u otros). Asimismo, su consentimiento nos otorgará la posibilidad de enviar y/o contactarlo por los medios de comunicación antes mencionados la medición o monitoreo de las mejoras en el servicio, control de calidad de llamadas y/o encuestas, sobre producto(s) y/o servicio(s) ofrecidos por SURGIR.
                <br /> <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                  -{" "}
                </span>	Efectuar la evaluación de su perfil crediticio para la colocación de cualquier nuevo producto(s) y/o servicio(s) ofrecido por SURGIR.
                <br /><span style={{ color: "#ec0000", fontStyle: "bold" }}>
                  -{" "}
                </span>
                Transferir sus datos personales a terceras empresas (personas jurídicas) vinculadas o no a SURGIR, sean estas sus subsidiarias, afiliadas, socios comerciales integrantes de su grupo económico, nacionales o extranjeros, públicos o privados (ejemplo: compañías de SURGIR, empresas comerciales, imprentas, empresas de mensajería, notarías, auditores, empresas de recuperación y cobranza, de estudio de mercado y encuestadoras, etc.), quienes realizarán el tratamiento de sus datos para las finalidades antes mencionadas y/o asociadas a ellas en caso haya dado su consentimiento.

                <br /><span style={{ color: "#ec0000", fontStyle: "bold" }}>
                  -{" "}
                </span>	Permitir el tratamiento de la información, por un tercero distinto a SURGIR, subcontratado por esta para determinados productos y/o campañas, quienes realizarán el tratamiento de sus datos para las finalidades antes mencionadas.
                <br />
                En caso no autorice las finalidades adicionales, sus datos personales sólo serán utilizados para las finalidades necesarias antes señaladas. Con lo cual, la autorización del uso de los datos para las finalidades adicionales no es condicionante para contratar ningún producto o servicio que ofrezca el SURGIR.
                <br />
                <br />
                <br />
                <span className="subtitulos">
                  V. DATOS PERSONALES QUE SERÁN TRATADOS (FINALIDADES NECESARIAS Y
                  ADICIONALES)
                </span>
                <br />
                <br />
                Para llevar a cabo las finalidades necesarias descritas en la presente Política de Privacidad y dependiendo del tipo de servicio o producto a contratarse, es obligatorio que nos proporcione los siguientes datos personales: Nombres y apellidos; tipo y número de documento de identidad; dirección de domicilio y/o negocio/emprendimiento; teléfonos; correo electrónico; imagen consignada en mi documento de identidad; voz; firma y firma electrónica; estado civil; fecha de nacimiento; nacionalidad; sexo; profesión u ocupación; edad; datos de persona de contacto; Créditos, préstamos y avales, datos bancarios; historial de créditos; información tributaria; seguros; deudas; gastos adicionales (información de hábitos sociales, personales y de consumo); características de vivienda.
                <br />
                <br />
                SURGIR tratará los datos personales a los que pueda acceder de forma física, oral o electrónica, a través de fuentes accesibles al público o de terceros y/o entidades de consulta de bases de datos domiciliadas en Perú o en el exterior, sean personas naturales o jurídicas, privadas o públicas, conforme a la legislación de la materia
                <br /><br />
                En caso, USTED otorgue el consentimiento al tratamiento de los datos mediante línea telefónica, SURGIR deberá informar que utilizará los datos para las finalidades informadas a través de dicho canal, es decir, además de las finalidades necesarias indicadas, también serán utilizados para la evaluación y calificación de algún producto de SURGIR, y para que se pueda informar la oferta.
                <br /><br />
                De manera adicional, precisamos que SURGIR podrá requerirle a USTED el llenado de cuestionarios (por mecanismo físico, electrónico, telefónico u otro) con la finalidad de evaluar y determinar su perfil. Dicha información declara por USTED en dichos cuestionarios es considerada como verdadera; y, otorga a SURGIR la facultad de hacer tratamiento de toda la información ingresada a través de dichos cuestionarios.
                <br />
                <br />
                Los datos personales facilitados se almacenarán en el banco de datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483 y serán tratados para poder llevar a cabo las finalidades expuestas anteriormente.

                <br />
                <br />
                Los datos personales almacenados sólo podrán ser conocidos y manejados por el personal de SURGIR que necesite conocer dicha información para poder contestar las solicitudes del titular de los datos personales. Estos datos personales serán tratados de forma leal y lícita, y no serán utilizados para otras finalidades incompatibles con las especificadas.
                <br />
                <br />
                <span className="subtitulos">VI. DESTINATARIOS</span>
                <br />
                <br />SURGIR podrá dar tratamiento a sus datos personales de manera directa o través de proveedores de servicio que serán considerados como encargados de tratamiento de los datos personales, con quienes SURGIR suscribirá los documentos pertinentes para que tomen las medidas de seguridad y guarden la confidencialidad de la información proporcionada. Dicho listado podrá ser apreciado en www.surgir.com.pe. En caso sea necesario, esta lista será actualizada de manera oportuna.
                <br />
                <br />Del mismo modo, SURGIR podrá transferir los datos personales, en el Perú o en el exterior, por cualquier medio, a sus empresas vinculadas, incluyendo a su matriz, y aliados comerciales para los fines propios de la relación contractual, en caso de corresponder; y, además, para las finalidades adicionales, en caso las autorice. Al respecto, la relación completa de los destinatarios que podrán recibir los datos como encargados de tratamiento o como terceros receptores por transferencia se podrá apreciar a través de www.surgir.com.pe.
                <br />
                <br />SURGIR respeta la privacidad de sus clientes, usuarios, colaboradores y aliados estratégicos; no compartiremos su información con terceros si usted no desea expresamente que lo hagamos.
                <br />
                <br />Asimismo, se informa que los datos personales de sus clientes, usuarios, colaboradores y aliados estratégicos podrán ser comunicados a las entidades administrativas, autoridades judiciales y/o policiales, siempre y cuando esté establecido por Ley.
                <br />
                <br />
                <span className="subtitulos">VII. TRANSFERENCIAS DE DATOS</span>
                <br />
                <br />De otorgar su consentimiento, podrán ser transferidos la información que sea necesaria a nuestros socios comerciales o empresas vinculada incluyendo a la matriz y aliados comerciales, a efectos que se puedan ofrecerle nuestros productos de acuerdo con lo establecido en el punto IV que antecede.
                <br />
                <br />Esta transferencia de la información incluye todos los datos, operaciones o referencias, que SURGIR pudiera acceder en el curso normal de sus operaciones, ya sea por haber sido proporcionada directamente por USTED o por terceros, en forma física, oral o electrónica.
                <br />
                <br />Es necesario resaltar que toda transferencia de sus datos personales requiere de su consentimiento, salvo en los siguientes casos: (a) Los requeridos por las diversas Autoridades jurisdiccionales y/u organismos supervisores, para el ejercicio de sus funciones en el ámbito de sus competencias; (b) Los obtenidos de las fuentes de acceso al público; y, (c) Los desarrollados por SURGIR en la relación contractual y/o comercial.
                <br />
                <br />Con la finalidad de ejecutar las prestaciones que correspondan y que están relacionadas con el servicio ofrecido por SURGIR, y previa declaración ante la Autoridad Nacional de Protección de Datos Personales, SURGIR transfiere fuera de territorio peruano los datos personales de sus clientes, usuarios y colaboradores, en tal sentido cumple con los controles establecidos en la Directiva de Seguridad de la Información, aprobada por la Resolución Directoral N° 019-2013-JUS/DGPDP.
                <br />
                <br />{" "}
                <span className="subtitulos">
                  VIII. CONFIDENCIALIDAD DE LOS DATOS PERSONALES
                </span>
                <br />
                <br />
                SURGIR, de conformidad con lo establecido en la Ley N° 29733, Ley de Protección de Datos Personales (en adelante, LPDP), tratará los datos personales facilitados con total confidencialidad. Se compromete a guardar secreto profesional indefinidamente respecto de estos y garantiza el deber de guardarlos adoptando todas las medidas de seguridad necesarias.

                <br />
                <br />
                <span className="subtitulos">
                  IX. SEGURIDAD DE INFORMACIÓN EN LOS DATOS PERSONALES
                </span>
                <br />
                <br />  
                SURGIR ha implementado mecanismos de seguridad indicados en la Política de Seguridad de la Información y al Reglamento del Sistema de Gestión de Seguridad de la Información. Además, ha desplegado una serie de documentos y actividades a nivel interno para garantizar el correcto funcionamiento de los esquemas de seguridad técnica; no obstante, a pesar de la debida diligencia adoptada, SURGIR no se responsabiliza por cualquier consecuencia derivada del ingreso indebido o fraudulento por parte de terceros a la base de datos y/o por alguna falla técnica en el funcionamiento.            
                <br />
                <br />
                SURGIR solicita los datos necesarios para créditos, servicios e interacción con USTED. En algunos casos, puede solicitar información adicional y sensible la cual es de libre y voluntaria entrega por parte del titular del dato. Una vez suministrados sus datos personales, de manera voluntaria y libre, los mismos son almacenados en la base de datos pertinente de acuerdo con el servicio o producto adquirido. Las bases de datos se encuentran detrás de un Web Application firewall para mayor seguridad, para el aislamiento de datos se cuenta con una virtual private cloud (VPC) que soporta la creación de subnets privadas y se utiliza características de múltiple factor de autenticación (MFA). Sólo personal autorizado que ha firmado acuerdos de confidencialidad de la información puede acceder a él y por tanto a los datos personales de nuestros clientes, usuarios y/o colaboradores.
                <br />
                <br /> En cumplimiento de la normativa vigente, en SURGIR hemos adoptado las medidas técnicas de seguridad apropiadas a la categoría de los datos personales necesarias para mantener el nivel de seguridad requerido, con el objetivo de evitar la alteración, pérdida o el tratamiento o accesos no autorizados que puedan afectar a la integridad, confidencialidad y disponibilidad de la información.             
                <br />
                <br />Asimismo, tenemos implementadas todas las medidas de índole legal, técnica y organizativa necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya sea que provengan de la acción humana, del medio físico o natural, tal y como establece la legislación peruana vigente de protección de datos personales, Directiva de Seguridad, emitida por la Autoridad Nacional de Protección de Datos Personales, aprobada por Resolución Directoral N° 019-2013-JUS/DGPDP.
                <br />
                <br />              
                
                <span className="subtitulos">X. EJERCICIO DE DERECHOS ARCO</span>
                <br />              
                <br />En todo momento USTED pueden dirigirse a SURGIR con el fin de poder ejercer sus derechos de información, de acceso, de actualización, inclusión, rectificación y supresión, de impedir el suministro de sus datos personales, de oposición al tratamiento o de tratamiento objetivo de los datos, en los términos recogidos en la legislación peruana vigente.
                <br />
                <br />Para el ejercicio de sus derechos, USTED podrá presentar una solicitud acompañada de una copia del DNI en la oficina principal de SURGIR en la dirección indicada en el numeral I o al correo electrónico protecciondedatos@surgir.com.pe, de conformidad con lo establecido en los procedimientos que se encuentran disponibles en la página web: www.surgir.com.pe en la sección "Legales / Protección de Datos / Políticas de Privacidad ".              
                <br />
                <br />Asimismo, informamos que puede presentar un reclamo ante la Autoridad Nacional de Protección de Datos Personales en la Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle Scipión Llona N° 350, Miraflores, Lima, Perú, de considerar que no ha sido atendido adecuadamente en el ejercicio de sus derechos.
                <br />              
                <br />
                <br />
                <span ref={tratamientoRef} id="tratamiento-datos" className="subtitulos">XI. MENORES DE EDAD</span>
                <br /><br />
                SURGIR, en el marco de la prestación de sus servicios, puede conocer datos personales de menores de edad, los cuales son tratados de acuerdo con lo dispuesto en el Reglamento de la LPDP. En el supuesto de que se tenga conocimiento que los datos personales recogidos corresponden a un menor de edad sin autorización de los padres o tutor responsable, se adoptarán las medidas oportunas para eliminar estos datos tan pronto como sea posible.
                <br />
                <br />
                <span  className="subtitulos" >
                  XII. TRATAMIENTO DE DATOS PERSONALES PROPORCIONADOS POR LA
                  PÁGINA WEB DE SURGIR
                </span>
                <br />              
                <br />Se informa a las personas que los datos personales que introduzcan en la Web serán tratados por SURGIR, con la finalidad de poder identificarlos y contactarlos. 
                <br />
                <br />Asimismo, los datos personales proporcionados en el registro de la Web y de las aplicaciones móviles de SURGIR serán tratados para efectos de gestión de productos y/o servicios solicitados y/o contratados (incluyendo evaluaciones financieras, procesamiento de datos, formalizaciones contractuales, cobro de deudas, gestión de operaciones financieras y remisión de correspondencia, entre otros).              
                <br />
                <br />Los datos personales e información que faciliten los titulares de datos personales en el formulario denominado “Trabaja con nosotros” de la Web serán tratados por SURGIR para cubrir los puestos de trabajo vacantes. Asimismo, se le informa a “EL USUARIO” de la web que los datos solicitados serán recogidos en un banco de datos de su titularidad, denominado “TRABAJADORES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21484. Los datos personales contenidos en dicho banco de datos son necesarios para la postulación a los puestos vacantes y la negativa a suministrarlos supone la imposibilidad de acceder al mismo. 
                <br />              
                <br />Los datos personales brindados por “EL USUARIO” serán recopilados y tratados con la finalidad de gestionar los procesos de selección de personal que se desarrollen en SURGIR. Asimismo, “EL USUARIO” autoriza a que sus datos personales puedan mantenerse en el banco de datos con el objetivo de participar en procesos de selección que sean convocados en el futuro.
  Los datos personales facilitados se almacenarán en los bancos de datos que forman parte del sistema de información de SURGIR y serán tratados para poder llevar a cabo las finalidades expuestas anteriormente.

                <br />
                <br/>El tratamiento de los datos personales recopilados se regirá por lo expuesto en esta política de privacidad.
                <br />
                <br />
                <span className="subtitulos">XIII. PLAZO DE CONSERVACIÓN</span>
                <br />       
                <br />Sus datos personales serán conservados mientras sea necesario para ejecutar la relación contractual que mantengan con SURGIR.
                <br />
                <br /> SURGIR, como de titular del banco de datos, se abstendrá de conservar datos personales en los siguientes eventos (i) Cuando tenga conocimiento de su carácter inexacto o incompleto; y, (ii) Cuando los datos personales objeto de tratamiento hayan dejado de ser necesarios o pertinentes para el cumplimiento de las finalidades previstas en la presente política privacidad, salvo medie procedimiento de disociación o anonimización.
        
                <br />
                <br />La supresión no procede cuando los datos personales deban ser conservados en virtud de un mandato legal o en virtud de razones históricas, estadísticas o científicas de acuerdo con la legislación aplicable o, en su caso, en las relaciones contractuales entre el responsable y el titular de los datos personales, que justifiquen el tratamiento de estos, tal como lo dispone el Reglamento de LPDP.
                <br />       
                <br />
              
                <span className="subtitulos">
                  XIV. CONSECUENCIA DE PROPORCIONAR LOS DATOS PERSONALES O SU
                  NEGATIVA A HACERLO
                </span>
                <br />
                <br />El suministro de los datos personales identificados en la presente Política de Privacidad y la obtención expresa e inequívoca del consentimiento contenido en la misma, conducirá al tratamiento de los datos personales suministrados por el titular de datos personales, según las finalidades determinadas.
                <br />
                <br />El titular de los datos personales puede negarse a proporcionar sus datos personales a SURGIR, por lo que este último se abstendrá de realizar el tratamiento de sus datos personales. En este sentido, como consecuencia de dicha acción, el titular de los datos personales no podrá acceder al servicio proporcionado por SURGIR toda vez que para el desarrollo del servicio resulta imprescindible contar con los datos personales del titular.
                <br />
                <br />
                <span className="subtitulos">
                  XV. VIGENCIA Y MODIFICACIONES DE LA POLÍTICA DE PRIVACIDAD
                </span>
                <br /> <br />
                SURGIR se reserva el derecho a modificarla en el supuesto que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por criterios propios empresariales. Si se introdujera algún cambio en esta política, deberá ser previamente aprobado por el Directorio de SURGIR y el nuevo texto se publicado en esta la página web {" "}
                <a
                  style={{ textDecoration: "none", color: "#257fa4" }}
                  target="_blank"
                  href="/."
                  rel="noopener noreferrer"
                >
                  www.surgir.com.pe
                </a>
                , por lo que se recomienda revisar periódicamente esta política de
                privacidad.
                <br />
                <br />{" "}
                <span className="subtitulos">
                  XVI. OTROS PUNTOS A TENER EN CUENTA{" "}
                </span>
                <br />
                <br />
                Para realizar cualquier tipo de consulta respecto a esta política de privacidad puede dirigirse a la siguiente dirección de correo electrónico: proteccióndedatos@surgir.com.pe. 
                <br /> <br />
                <hr />
              </Col>
        </Row>
        <Row>
          <Col md={4}>
          <br />
            <p className="Columna">Política de Cookies</p>
          </Col>
          <Col md={8}>
          <br />
            <span className="subtitulos">I. INTRODUCCIÓN</span>
            <br />
            <br />SANTANDER FINANCIAMIENTOS S.A. (en adelante, “SURGIR”) describe en este documento la “Política de Cookies” que regula este sitio web (www.surgir.com.pe) con el objetivo de garantizar la privacidad del usuario del sitio web (en adelante “EL USUARIO”). El sitio web es propiedad de SURGIR, con domicilio en Avenida Rivera Navarrete N° 475 - Piso 3 - San Isidro, Lima - Perú.
            <br />
            <br />Estas cookies permiten a SURGIR facilitar el uso y navegación, garantizar el acceso a determinadas funcionalidades y adicionalmente, nos ayudan a mejorar la calidad del sitio web de acuerdo con los hábitos y estilos de navegación de “EL USUARIO”. 
            <br />
            <br />SURGIR utiliza cookies estrictamente necesarias y esenciales para que usted utilice el sitio web y que el sitio web funcione correctamente. Asimismo, SURGIR informa “EL USUARIO” que otras cookies sirven para mejorar el rendimiento y su experiencia en el sitio web. 
            <br />
            <br />SURGIR informa a “EL USUARIO” que se va a proceder a la recopilación y almacenamiento de los datos personales. Dichos datos estarán incorporados en el Banco de Datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483 de titularidad de SURGIR. 
            <br />
            <br />Adicionalmente, informamos a “EL USUARIO” que en nuestro sitio web utilizamos cookies propias como de terceros. De acuerdo con ello y la finalidad de ejecutar su funcionalidad, SURGIR transfiere dentro y fuera de territorio peruano los datos personales de “EL USUARIO” a terceras empresas. La relación de empresas antes mencionadas podrá́ ser consultada a través de la página web www.surgir.com.pe.
            <br />
            <br />La recopilación de los datos personales identificados en la presente política, y la obtención expresa e inequívoca del consentimiento de esta, conducirá́ al tratamiento de los datos personales suministrados por “EL USUARIO”, según las finalidades determinadas. “EL USUARIO” puede negarse a proporcionar sus datos personales antes señalados; por lo que SURGIR se abstendrá́ de realizar el tratamiento de estos.
            <br />
            
            <br />
            <span className="subtitulos">II. FINALIDAD DE LOS COOKIES</span>
            <br />
            <br />
            Las cookies sirven para reconocer el dispositivo de “EL USUARIO”
            cuando vuelven a visitar el sitio web, facilitándole su uso
            recordando, sus preferencias y configuración de navegación (por
            ejemplo: idioma, país, etc.). Asimismo, sirven para mejorar los
            servicios que ofrecemos y para poder recopilar información
            estadística que nos permite entender cómo “EL USUARIO” utiliza
            este sitio y permite mejorar su estructura y contenidos.
            <br />
            Algunas cookies son estrictamente necesarias para que el sitio web
            funcione correctamente y otras sirven para mejorar el rendimiento
            y su experiencia como usuario.
            <br />
            Las cookies se asocian únicamente a un usuario anónimo y su
            dispositivo de acceso a internet, y no proporcionan referencias
            que permitan deducir datos personales de “EL USUARIO”. Las cookies
            no pueden dañar su dispositivo y además son muy útiles, puesto que
            nos ayudan a identificar y resolver errores.
            <br />
            Mostramos información sobre nuestra Política de Cookies en la
            parte superior o inferior cada vez que se accede a nuestro sitio
            web.
            <br />
            Ante esta información, “EL USUARIO” puede realizar las siguientes
            acciones:
            <br />  <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "} Aceptar todas las cookies: No se volverá́ a visualizar este
            aviso durante la presente sesión; y/o
            <br />  <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}Configurar Cookies: Podrá́ modificar la configuración de su
            navegación, pero ello no evitará que se muestre el aviso sobre
            cookies al acceder a nuevas páginas del sitio web.
            <br />
            En caso el "USUARIO" decida no elegir entre la aceptación o
            modificación de la configuración, la información indicada se
            seguirá́ mostrando durante su navegación a fin de facilitar su
            acceso y decisión.
            <br />
            <br />
            <span className="subtitulos">III. ENLACES A REDES SOCIALES</span>
            <br /><br />
            En el sitio web también se incluyen indicadores que “EL USUARIO” puede utilizar de forma sencilla e intuitiva, para enlazar y compartir ciertos contenidos del sitio web con redes sociales. Esta funcionalidad conlleva el uso de cookies que enlazan con los sitios web de estas redes sociales, almacenando la información únicamente en ellas. 
            <br />
            <br />
            <span className="subtitulos">
              IV. TIPOS DE COOKIES UTILIZA LA PÁGINA WEB DE SURGIR
            </span>
            <br />
            <br />
            Las cookies siguientes no identifican personalmente a “EL
            USUARIO”, solo proporcionan información estadística anónima sobre
            la navegación en este sitio web; sin embargo, puede
            deshabilitarlas directamente configurando su navegador. A
            continuación, se listan las cookies que son utilizadas en este la
            página web de SURGIR:
            <br /><br />  <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}Cookies funcionales: Son las que permiten la navegación
            optima y el acceso a áreas seguras de la página web, por lo cual
            la página web no podrá́ funcionar adecuadamente sin estas cookies.
            Estas cookies no almacenan ninguna información de identificación
            personal.
            <br /> <br />  <span style={{ color: "#ec0000", fontStyle: "bold" }}>
              -{" "}
            </span>{" "}Cookies analítica y optimización: Son aquellas que
            permiten al editor de estas, el seguimiento y análisis del
            comportamiento de los usuarios de los sitios web a los que están
            vinculados. La información recogida mediante este tipo de cookies
            se utiliza en la medición de la actividad de los sitios web,
            aplicación o plataforma, y para la elaboración de perfiles de
            navegación, con el fin de introducir mejoras en función del
            análisis de los datos de uso que hacen los usuarios del servicio.
            <br /><br />
            “El USUARIO” podrá́ conocer esta y otras cookies que SURGIR utiliza
            cuando ingresa al sitio web.
            <br />
            <br />
            <span className="subtitulos">V. GESTIÓN DE LAS COOKIES</span>
            <br />
            <br />
            SURGIR asume un compromiso sobre el uso de cookies, en
            consecuencia, otorga a “EL USUARIO” acceso a información para que
            pueda comprender qué tipo de cookies se utiliza en este sitio web
            y la razón de ello. Con esto, SURGIR pretende proporcionar a “EL
            USUARIO” transparencia en cuanto a los datos tratados acerca de la
            navegación realizada desde su equipo en el sitio web.
            <br /><br />
            Si “EL USUARIO” acepta dichas cookies, permite la mejora del sitio
            web de SURGIR para ofrecerle un acceso optimo y darle un servicio
            más eficaz y personalizado. Además, “EL USUARIO” puede configurar
            su navegador para establecer que solo los sitios web de confianza
            o las páginas por las que está navegando en este momento puedan
            gestionar cookies, lo que le permite seleccionar sus preferencias.
            <br /><br />
            Si “EL USUARIO” decide no autorizar el tratamiento, solo usaríamos
            las cookies técnicas puesto que son imprescindibles para la
            navegación por nuestro sitio web. En este caso, no almacenaríamos
            ninguna cookie. En caso “EL USUARIO” rechace o borre las cookies
            de navegación del sitio web, SURGIR no mantendrá́ sus preferencias;
            y algunas características de las páginas no estarán operativas,
            así́ como no podremos ofrecerle servicios personalizados y cada vez
            que vaya a navegar por este sitio web se tendrá́ que solicitar a
            “EL USUARIO” de nuevo su autorización para el uso de cookies. Si,
            aun así́, “EL USUARIO” decide modificar la configuración de su
            acceso al sitio web, debe saber que es posible eliminar las
            cookies o impedir que se registre esta información en su equipo en
            cualquier momento mediante la modificación de los parámetros de
            configuración de su navegador.
            <br /><br />
            Para bloquear o deshabilitar las cookies, “EL USUARIO” deberá́
            activar la configuración del navegador, de manera que se rechace
            la instalación de todas las cookies o de algunas de ellas. En la
            práctica la mayoría de los navegadores permiten advertir la
            presencia de cookies o rechazarlas automáticamente. Si son
            rechazadas se podrá́ seguir usando este sitio web, aunque el uso de
            algunos de sus servicios podrá́ ser limitado y por tanto la
            experiencia de “EL USUARIO” puede ser menos satisfactoria.
            <br /><br />
            En caso de que “EL USUARIO” desee revocar el consentimiento por
            medio de los navegadores que utiliza, deberá configurarlo en los
            términos previstos a continuación:
            <br /><br />
            a. Microsoft Internet Explorer: en la opción de menú
            “Herramientas”, seleccionando “Opciones de Internet” y accediendo
            a “Privacidad”.
            <br /><br />
            b. Firefox, para Mac en la opción de menú “Preferencias”,
            seleccionando “Privacidad”, accediendo al apartado “Mostrar
            Cookies”, y para Windows en la opción de menú “Herramientas”,
            seleccionando “Opciones”, accediendo a “Privacidad” y luego a
            “Usar una configuración personalizada para el historial”.
            <br /><br />
            c. Safari, en la opción Safari, seleccionar “Preferencias”, y
            “Privacidad”.
            <br /><br />
            d. Google Chrome, en la opción de menú Herramientas o pestaña de
            Chrome (para Mac), seleccionando “Opciones” (“Preferencias” en
            Mac), accediendo a “Configuración avanzada” y luego en la opción
            “Privacidad y seguridad" y "Configuración del sitio web”, y
            finalmente marcando “Cookies y datos de sitios".
            <br /><br />
            e. Microsoft Edge, en la opción de menú, seleccionando “Opciones”,
            accediendo a “Configuración”, y finalmente accediendo a “Cookies y
            permisos del sitio”.
            <br />
            <br />
            <span className="subtitulos">VI. CONSULTAS</span>
            <br /><br />
            Para realizar cualquier tipo de consulta respecto a esta política puede dirigirse a la siguiente dirección de correo electrónico: proteccióndedatos@surgir.com.pe o a la señalada en el punto 1 de la presente Política (Avenida Rivera Navarrete N° 475 - Piso 3 - San Isidro, Lima – Perú)
            <br />
            <br />
            <span className="subtitulos">
              VII. VIGENCIA Y MODIFICACIÓN DE LA PRESENTE POLÍTICA DE COOKIES
            </span>
            <br /><br />
            SURGIR puede modificar esta Política de Cookies en función de
            exigencias legislativas, reglamentarias, jurisprudenciales o con
            la finalidad de adaptar dicha política a las instrucciones
            dictadas por la Autoridad Nacional de Protección de Datos
            Personales, así́ como por criterios propios institucionales.
            <br /><br />
            Si se introdujera algún cambio en esta política, deberá ser
            previamente aprobado por el Directorio de SURGIR y el nuevo texto
            se publicado en esta la página web{" "}
            <span ref={terminosycondicionesRef} id="terminos-y-condiciones"></span>
            <a
              id="terminosYCondiciones"
              style={{ textDecoration: "none", color: "#257fa4" }}
              target="_blank"
              href="/."
              rel="noopener noreferrer"
            >
              www.surgir.com.pe
            </a>
            
            , por lo que se recomienda revisar periódicamente esta política de
            privacidad.
            <br />
            <br />
            <hr />
            
          </Col>
        </Row>
        
        <Row id="termsConditions">

          <Col  md={4}>
          <br />
            <p  className="Columna">Términos y Condiciones</p>
          </Col>
          <Col md={8}>
          <br />
            <span  className="subtitulos"> CONDICIONES DE USO:</span>
            <br /><br />
            Al ingresar a la presente Página Web de SANTANDER FINANCIAMIENTOS S.A (en adelante "SURGIR"), EL USUARIO (cliente de SURGIR) se compromete a leer la totalidad de los Términos y Condiciones generales que se detallan a continuación. En caso EL USUARIO no se encontrase de acuerdo con los mismos o de no aceptarlos, deberá abstenerse de navegar en la misma y/o manipular cualquier tipo de información vertida en él, bajo su responsabilidad.
  En consecuencia, todas las visitas que realice EL USUARIO, en esta página, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a la legislación. 

            <br /><br />
            En consecuencia, todas las visitas que realice EL USUARIO, en esta página, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a la legislación. 
            <br /><br />
            La aceptación de los presentes Términos y Condiciones es imprescindible para el registro, acceso, uso, operación, reserva, búsqueda y toda actividad que realice EL USUARIO, quien declara haber leído y aceptado lo estipulado en el presente documento.
            <br /><br />
            Se informa que los datos personales proporcionados a SURGIR quedan incorporados al Banco de Datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483 de titularidad de SURGIR, quien utilizará dicha información para efectos de la gestión de los productos y/o servicios solicitados y/o contratados (incluyendo evaluaciones financieras, procesamiento de datos, formalizaciones contractuales, cobro de deudas, gestión de operaciones financieras y remisión de correspondencia, entre otros), la misma que podrá ser realizada a través de terceros.
            <br /><br />
            SURGIR protege estos bancos de datos y sus tratamientos con todas las medidas necesarias para garantizar su seguridad y evitar la alteración, pérdida, tratamiento o acceso no autorizado.
            <br /> <br />A continuación, se exponen dichos Términos y Condiciones:
            <br /> <br />
            1. El Usuario:
            <br /> <br />
            A. De su Registro:
            <br />
            Será necesaria para el uso de los productos ofrecidos por SURGIR
            la aceptación de las presentes condiciones y el registro por parte
            de EL USUARIO de los diversos datos personales, tales como:
            nombres y apellidos, tipo y documento nacional de identidad,
            correo electrónico, número de celular y otros según corresponda.
            Asimismo, EL USUARIO declara que información brindada es veraz,
            actual, suficiente y autentica.
            <br />
            <br />
            B. Derechos al acceder a la Página Web
            <br />
            SURGIR garantiza que EL USUARIO gozará de todos los derechos que
            le reconoce la legislación sobre protección al consumidor y
            protección de datos personales vigentes en el Perú.
            <br />
            La sola visita a esta página no impone a EL USUARIO a la
            adquisición de los diversos productos, a menos que haya aceptado
            las condiciones ofrecidas por SURGIR.
            <br />
            SURGIR garantiza en particular que la información sobre las
            características y condiciones de los productos ofrecidos a través
            de esta Página Web será oportuna, suficiente, veraz, apropiada y
            de fácil acceso a EL USUARIO. Dicha información podrá ser modifica
            por SURGIR, incluyendo la relacionada a las características y
            condiciones de cada servicio, en cualquier momento y sin previo
            aviso.
            <br />
            <br />
            C. Obligaciones al acceder a la Página Web
            <br />
            EL USUARIO se obliga a acceder a la Página Web, con la mayor
            diligencia, debiéndose entender que realizará un uso correcto, y
            lícito, evitando en todo momento realizar acciones que puedan
            atentar contra el buen funcionamiento y la imagen de SURGIR o
            cualquier acto que vaya en contra de sus fines.
            <br />
            Por ello, EL USUARIO se obliga a: (i) No utilizar el Página Web
            con fines fraudulentos o no llevar a cabo una conducta que,
            involucrando a la Página Web, dañe o genere un daño a la imagen,
            los intereses y los derechos de SURGIR o de terceros; (ii) No
            suprimir, eludir o manipular el "copyright" y demás datos
            identificativos de los derechos de sus titulares incorporados a
            los contenidos en la Página Web; y, (iii) No realizar cualquier
            acto que involucre dañar, inutilizar o sobrecargar el Página Web,
            o que impidiera, de cualquier forma, la normal utilización y
            funcionamiento del mismo.
            <br /><br />
            Esta lista debe ser entendida como enunciativa más no limitativa,
            pudiendo extenderse a cualquier acto realizado por EL USUARIO que
            conlleve a lograr el perjuicio a SURGIR, a sus clientes u otros
            Usuarios o proveedores.
            <br />
            En caso SURGIR detecte que EL USUARIO realizó cualquiera de las
            prohibiciones nombradas en el párrafo anterior o incluso cuando
            existan indicios o sospechas razonables de ellas, limitará,
            suspenderá o dará por concluido el acceso de EL USUARIO a la
            Página Web. Aunado a ello, SURGIR adoptará las acciones legales y
            técnicas necesarias.
            <br />
            <br />
            2. Limitación de responsabilidad
            <br /> <br />
            EL USUARIO reconoce y acepta que el acceso a la Página Web se
            realiza en todo momento bajo su riesgo y responsabilidad, por lo
            que SURGIR no se responsabiliza del mal uso o acceso indebido que
            pueda realizarse del mismo.
            <br />
            Por tanto, SURGIR no será responsable de lo siguiente:
            <br />
            <br />• El contenido de las páginas enlazados a través de los
            enlaces (links) incluidos dentro del Página Web.
            <br /><br />• De los contenidos publicitarios que estén albergados en la
            Página Web.
            <br />
            En ningún caso SURGIR será responsable de la inexactitud,
            falsedad, irregularidades y/o actos que puedan considerarse
            contrarios a la moral, usos y buenas costumbres que puedan
            contener los materiales publicitarios de los anunciantes; quienes
            asumen directamente toda responsabilidad.
            <br /><br />• Los daños y perjuicios de cualquier tipo causados en los
            equipos informáticos de EL USUARIO por virus, gusanos
            informáticos, troyanos o cualquier otro elemento dañino.
            <br /><br />• Los daños y perjuicios de cualquier tipo producidos por
            fallos o desconexiones en las redes de telecomunicaciones que
            produzcan la suspensión, cancelación o interrupción del acceso al
            Página Web. EL USUARIO reconoce que el acceso al Página Web
            requiere de servicios suministrados por terceros ajenos al control
            de SURGIR.
            <br />
            <br />
            3. Fuerza mayor
            <br />
            <br />
            SURGIR no será responsable de cualquier incumplimiento o retraso
            en el cumplimiento de las diversas obligaciones señaladas en los
            presentes documento, cuando ello sea causado por un hecho que
            escape de su control, caso fortuito, hecho determinante de
            terceros o causado por fuerza mayor.
            <br />
            Se debe entender por “fuerza mayor” a cualquier acto o hecho fuera
            del control razonable de SURGIR, incluyendo, sin limitación, a
            conmoción civil, disturbios, invasión, ataque terrorista o amenaza
            de ataque terrorista, guerra (declarada o no) huelgas u otras
            medidas realizadas por terceros, o acciones realizadas por un
            proveedor generando retraso, incendio, explosión, tormenta,
            inundación, terremoto, hundimiento, epidemia, pandemia o cualquier
            otro desastre natural, o el fracaso de las redes de
            telecomunicaciones públicas o privadas.
            <br />
            De darse un evento de fuerza mayor que afecte al cumplimiento de
            las obligaciones de SURGIR, las obligaciones pactadas y plazos
            serán automáticamente suspendidos hasta la culminación de los
            hechos referidos. Una vez superado el evento de fuerza mayor los
            plazos continuarán con su cómputo.
            <br />
            <br />
            4. Enlaces (links)
            <br />
            <br />
            La Página Web puede incluir enlaces (links) que permitan a EL
            USUARIO a acceder a otras páginas y portales de Internet. En estos
            casos, SURGIR actúa como un prestador de servicios a terceros. En
            el supuesto de que EL USUARIO considere que existe una página
            enlazado con contenidos inadecuados o ilícitos, deberá comunicarlo
            a SURGIR al siguiente correo electrónico:
            atencionalusuario@surgir.com.pe, con la finalidad de evaluar el
            caso y definir las acciones.
            <br />
            SURGIR no se hace responsable del contenido de las páginas
            enlazados, ni de sus condiciones de uso y/o políticas de
            privacidad ni similares, siendo EL USUARIO el único responsable de
            comprobarlos y aceptarlos cada vez que acceda y haga uso de los
            mismos.
            <br />
            SURGIR se reserva el derecho a prohibir o inutilizar en cualquier
            momento los enlaces publicados en el Página Web.
            <br />
            <br />
            5. Validez de la publicidad contenida en el este Página Web
            <br />
            <br />
            La publicidad de los productos que realice SURGIR se rige por las
            disposiciones del Código de Protección de Defensa del Consumidor y
            otras normas que resulten aplicables.
            <br />
            En cada anuncio promocional se indicará de manera clara la
            duración y condiciones del servicio promocionado.
            <br />
            SURGIR se reserva el derecho de poder modificar el contenido de la
            Página Web, incluyendo aquella información relacionada a los
            productos, en cualquier momento y sin mediar previo aviso. Por
            tanto, en caso EL USUARIO haya seleccionado un producto o servicio
            sujeto a alguna promoción y/u oferta, y la misma quedará sin
            efecto antes de la conclusión de la transacción, la selección de
            dicho producto o servicio también concluirá.
            <br />
            La facultad de modificar las condiciones por parte de SURGIR no se
            aplicará a aquellas transacciones que se hayan realizado con éxito
            y que hayan sido confirmadas por la Página Web, a menos que se
            trate de un error obvio e inequívoco, en cuyo caso se aplicará lo
            dispuesto por el párrafo anterior.
            <br />
            <br />
            6. Propiedad intelectual
            <br />
            <br />
            Los derechos de propiedad intelectual sobre la disposición de los
            contenidos de la Página Web (incluidos los derechos conexos), su
            diseño gráfico, los signos distintivos (marcas y nombres
            comerciales), así como los programas de ordenador subyacentes
            (incluidos los códigos fuente) son de exclusiva titularidad de
            SURGIR, por tanto, el derecho de uso y explotación de los mismos,
            son protegidas por la legislación de propiedad intelectual e
            industrial vigentes.
            <br />
            Respecto a los distintos elementos que integran el portal
            (gráficos, fotografías, videos, etc.) el derecho de uso y
            explotación de los mismos corresponden al Grupo Santander, del
            cual es parte SURGIR, y son protegidas por la legislación de
            propiedad intelectual e industrial vigentes.
            <br />
            Queda expresamente prohibido la reproducción, transformación,
            distribución, comunicación pública, puesta a disposición,
            extracción y/o reutilización de la Página Web, sus contenidos y/o
            los signos distintivos de SURGIR.
            <br />
            <br />
            7. Imágenes referenciales de los productos o servicios financieros
            <br /><br />
            EL USUARIO debe tener en cuenta que las fotografías, videos,
            representaciones graficas de los productos mostrados en la Página
            Web, tienen una finalidad orientadora y referencial.
            <br />
            <br />
            <span ref={listaProveedoresRef} id="lista-proveedores"></span> 
            <span className="subtitulos">PROTECCIÓN DE DATOS:</span> <br />
            <br />SURGIR cumple con informar que, de acuerdo con la Ley N°29733 - Ley de Protección de Datos Personales y su Reglamento aprobado mediante Decreto Supremo N° 003-2013-JUS y las demás disposiciones complementarias, está legalmente autorizada para tratar la información que EL USUARIO proporcionará mediante el presente Página Web, con la finalidad de ejecutar la relación contractual.
            <br />
            <br />Asimismo, SURGIR informa que para dar cumplimiento a las obligaciones y/o requerimientos que se generen en virtud de las normas vigentes en el ordenamiento jurídico peruano y/o en normas internacionales que le sean aplicables, incluyendo, pero sin limitarse a las vinculadas al sistema de prevención de lavado de activos y financiamiento del terrorismo, SURGIR podrá dar tratamiento y eventualmente transferir su información a autoridades y terceros autorizados por ley
            <br />
            <br />EL USUARIO reconoce que estarán incluidos dentro de su información todos aquellos datos, operaciones y referencias a los que SURGIR pudiera acceder en el curso normal de su relación, ya sea por haber sido proporcionados por Usted o por terceros o por haber sido desarrollados por SURGIR, tanto en forma física, oral, electrónica y cualquier otro mecanismo, y que pudieran calificar como Datos Personales conforme a la legislación de la materia. 
            <br />
            <br />En virtud de lo señalado, SURGIR a incorporar su información al Banco de Datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483, así como almacenará, dará tratamiento, procesar y/o transferir su información a sus socios comerciales que figuran en la Página Web www.surgir.com.pe, conforme a los procedimientos que SURGIR determine en el marco de sus operaciones habituales, hasta diez (10) años después de que finalice su relación contractual. 
            
            <br />
            <br />
            <hr />
            <br />
            
          </Col>
        
        </Row>
        
        <Row id="listProviders">
          <Col md={4}>
            <p className="Columna">Listado de Proveedores</p>
          </Col>
          <Col md={8}>
            <table>
              <thead>
                <tr>
                  <th>EMPRESA VINCULADA A SURGIR</th>
                  <th>PAÍS</th>
                  <th>ACTIVIDAD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EXPERIAN PERU S.A.C PERÚ </td>
                  <td>PERÚ</td>
                  <td>CENTRALES DE RIESGO</td>
                </tr>
                <tr>
                  <td>COBISCORP MÉXICO S.A. DE C.V.</td>
                  <td>MÉXICO</td>
                  <td>SOLUCIONES DE TECNOLOGÍA</td>
                </tr>
                <tr>
                  <td>MICROSOFT CORPORATION</td>
                  <td>ESTADOS UNIDOS DE AMÉRICA</td>
                  <td>SERVICIO DE CLOUD</td>
                </tr>
                <tr>
                  <td>PRAXIS, IT PERU S.A.C</td>
                  <td>PERÚ</td>
                  <td>SOLUCIONES DE TECNOLOGÍA</td>
                </tr>
                <tr>
                  <td>MOVENTI CORPORATE S.A.C.</td>
                  <td>PERÚ</td>
                  <td>SOLUCIONES DE TECNOLOGÍA</td>
                </tr>
                <tr>
                  <td>PSA TECH PERU S.A.C</td>
                  <td>PERÚ </td>
                  <td>SOLUCIONES DE TECNOLOGÍA</td>
                </tr>
                <tr>
                  <td>GESINTEL S.A.C.</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE AMLUPDATE</td>
                </tr>

                <tr>
                  <td>MAPFRE PERÚ COMPAÑÍA DE SEGUROS Y REASEGUROS S.A.</td>
                  <td>PERÚ </td>
                  <td>SEGUROS</td>
                </tr>
                <tr>
                  <td>BANCO DE CRÉDITO DEL PERU (BCP)</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE RECAUDACIÓN</td>
                </tr>
                <tr>
                  <td>BANCO INTERNACIONAL DEL PERU (INTERBANK)</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE RECAUDACIÓN</td>
                </tr>
                <tr>
                  <td>BANCO INTERAMERICANO DE FINANZAS (BANBIF)</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE DESEMBOLSO Y RECAUDACIÓN</td>
                </tr>
                <tr>
                  <td>GLOBOKAS PERU S.A.</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE RECAUDACIÓN</td>
                </tr>
                <tr>
                  <td>EXTERNA S.A.C</td>
                  <td>PERÚ </td>
                  <td>ADMINISTRACIÓN Y RECUPERACIÓN DE CARTERA</td>
                </tr>
                <tr>
                  <td>CR COBRANZAS RECUPERACIONES ABOGADOS S.R.L.</td>
                  <td>PERÚ </td>
                  <td>ADMINISTRACIÓN Y RECUPERACIÓN DE CARTERA</td>
                </tr>
                <tr>
                  <td>IBR PERU S.A.</td>
                  <td>PERÚ </td>
                  <td>ADMINISTRACIÓN Y RECUPERACIÓN DE CARTERA</td>
                </tr>
                <tr>
                  <td>GESTIÓN COBRANZAS CALL S.A.C.</td>
                  <td>PERÚ </td>
                  <td>ADMINISTRACIÓN Y RECUPERACIÓN DE CARTERA</td>
                </tr>
                <tr>
                  <td>ESTRATEGIAS MÓVILES PERÚ S.A.C.</td>
                  <td>PERÚ </td>
                  <td>SERVICIO DE TELECOMUNICACIONES</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <hr/>
            <br/>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <p className="Columna">Derechos Arco </p>
          </Col>
          <Col md={8}>
            
          <Row style={{marginTop:20}}>
          <div className="card-style-transparence"  > 
            <div style={{width:"100%"}}>
              <span className="parrafo">
              Procedimiento de Derechos Arco
              </span>
            </div>
            <div style={{width:"40%"}}>
                <SurgirItemListWithIcon icon={DownloadIcon} document={process.env.REACT_APP_PROCEDIMIENTO_EJERCICIO_DERECHOS_ARCO} 
                name={<span className="linkrojo" style={{color: '#EC0000'}}> Descargar</span>} downloadName={"Terminos"}/>
            </div>
          </div>
        </Row>
        <Row style={{marginTop:20}}>
          <div className="card-style-transparence"  > 
            <div style={{width:"100%"}}>
              <span className="parrafo">
              Solicitud de Atención de Derechos Arco
              </span>
            </div>
            <div style={{width:"40%"}}>
                <SurgirItemListWithIcon icon={DownloadIcon} document={process.env.REACT_APP_SOLICITUD_ATENCION_DERECHOS_ARCO} 
                name={<span className="linkrojo" style={{color: '#EC0000'}}> Descargar</span>} downloadName={"Terminos"}/>
            </div>
          </div>
        </Row>
            <br />
          </Col>
        </Row>
        </div>
    </div>
  );
}

export default Legal