import React, { useEffect, useState } from 'react';
import SurgirDropDown  from '../../../../../components/ui/SurgirDropDown/SurgirDropDown';
import {
  apiGetTypeCredit
} from "../../../../../service/apiEndPoint";
import "./style.scss"

interface ProductoDropdownProps {
	onProduct: any;
	setParametersProduct: any
}

const ProductoDropdown: React.FC<ProductoDropdownProps> = ({ 
  onProduct, setParametersProduct
}) => {
  const [product, setProduct] = useState(null);
  const [listProductType, setListProductType] = useState([]);
  const handleProduct = (producto: any) => {
    setProduct(producto);
    onProduct(producto);
  };
  useEffect(() => {
    apiGetTypeCredit()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setListProductType(
          data.response.map((item: any) => {
            return {
              value: item.product.productCode,
              name: item.product.productType,
            };
          })
        )
        setParametersProduct(
          data.response.map((item: any) => {
            return {
              value: item.product.productCode,
              typeCredit: item.typeCredit,
              quotaType: item.quotaType,
              interestRate: item.interestRate
            };
          })
        )
      }
      )
      .catch((err) => console.log(err));
  }, [])


  return (
    <div className="container__ProductDropdown">
      <div >
        <center> <span className="simulator-title" >
          Simulador de ahorros y créditos
        </span>
          <br />
          <span className="parrafo">
            Ingresa el monto y te simulamos el valor de las cuotas que pagarías.
          </span>
        </center>
      </div>
      <div className="simulator-col" style={{ marginTop: 80, paddingBottom: 35,width:"100%"}}>
        <label htmlFor="dropdownProducto" className="parrafo">Producto a simular</label>
        <SurgirDropDown
          dataSource={listProductType}
          hint="Elige un producto"
          value={product}
          onChange={handleProduct}
       
        /></div>
    </div>
  );
};

export default ProductoDropdown;

