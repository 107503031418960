import React, { useEffect, useRef, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { ButtonBase, Typography } from '@mui/material';
import './SurgirAccordion.scss';

interface SurgirAccordionProps {
  sections: {
    title: string;
    body: React.ReactNode;
  }[];
  showIcon?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid #CEDEE7`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  borderLeft: 'none',
  borderRight: 'none'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <img src="/icons/chevron-down-gray.svg" alt="accordion-icon" />
    }
    {...props}
    component={(props) => 
      <ButtonBase {...props} 
      TouchRippleProps={{ classes: { rippleVisible: 'rippleVisible' } }}
    />}
  />
))(() => ({
  backgroundColor: '#FFFFFF',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px',
  },
  '&.MuiButtonBase-root': {
    width: '100%',
    '&:focus': {
      border: '2px solid #3366FF',
      borderRadius: '8px',
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'rgba(34, 34, 34, 0.04)',
    },
    
  },
  '&.MuiAccordionSummary-root': {
    height: '48px',
    padding: '0px 4px',
    '&.Mui-expanded': { 
      backgroundColor: '#FFFFFF',
    }
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 4px 24px 4px',
  borderTop: 'none',
  fontFamily: '"Str-Microtext-Regular", sans-serif',
  color: '#222222',
  fontSize: '14px'
}));

const SurgirAccordion: React.FC<SurgirAccordionProps> = ({
  sections, showIcon = false
}) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const [indexSelected, setIndexSelected] = useState<number>(0)

  const scrollRef = useRef<HTMLDivElement>(null);


  const handleChange = (panel: string, index:number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setIndexSelected(index)
  };

    useEffect(() => {

      if(expanded !== 'panel0') {
        const bodyAccordion = document.getElementById('bodyAccordionNew')
        const accordionPosition = bodyAccordion?.offsetTop ?? 0

        if(indexSelected >  Math.floor(sections.length / 2)) {

          window.scrollTo({
            top: Math.max((accordionPosition + (48*sections.length / 2))),
            behavior: 'smooth'
          });
        } else {
          bodyAccordion?.scrollIntoView()
        }

      }
    }, [expanded]);


  return (
    <div id='bodyAccordionNew' style={{paddingTop: '54px', height: 'auto'}} ref={scrollRef}>
      {sections.map((section, index) => (
        <Accordion
          key={section.title}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`, index)}
          id={`panel${index}`}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
          >
            {showIcon && <img style={{ marginRight: '8px' }} src="/icons/accordion.svg" alt="more-icon" />}
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: '"Str-Headline-Bold", sans-serif'
              }}
            >
              {section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {section.body}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SurgirAccordion;
