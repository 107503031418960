
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StylesSurgirTextField = styled(TextField)({
    '& label': {
        fontFamily: "Str-Microtext-Regular, sans-serif",
        '&.Mui-focused': {
            color: '#257FA4',
        }
    },
    '& .Mui-disabled': {
        backgroundColor: 'rgba(255,255,255,1) !important',
        '&:before': {
            border: '1px solid #CEDEE7 !important',
            lineHeight: 3.3,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            background: 'rgba(255,255,255,0)',
        },
    },
    '& .Mui-focused': {
        backgroundColor: 'rgba(255,255,255,0) !important',

        '&:hover': {
            background: 'rgba(255,255,255,0)',

            '&:after': {
                borderBottom: '2px solid #257FA4',

            },
            '&:before': {
                background: 'rgba(255,255,255,0)',
                borderBottom: '2px solid #257FA4',

            },
            '&:not(.Mui-disabled):before': {
                borderBottom: '1px solid #257FA4',

            },

        },
        '&:active':{
            background: 'rgba(255,255,255,0) !important',

        }
    
    },
    '& .MuiFilledInput-root': {
        background: 'rgba(255,255,255,0)',
        fontFamily: 'Str-Microtext-Regular,sans-serif',
        '&:hover': {
            background: 'rgba(255,255,255,0)',
            '&:after': {
                borderBottom: '1px solid #257FA4'
            },
            '&:before': {
                background: 'rgba(255,255,255,0)',
                borderBottom: '1px solid #257FA4'
            },
            '&:not(.Mui-disabled):before': {
                borderBottom: '1px solid #257FA4'
            }
        },
        '&:active':{
            background: 'rgba(255,255,255,0) !important',

        },
    
        '& .Mui-focused': {
            backgroundColor: 'rgba(255,255,255,0) !important',

            '&:hover': {
                background: 'rgba(255,255,255,0)',

                '&:after': {
                    borderBottom: '2px solid #257FA4',

                },
                '&:before': {
                    background: 'rgba(255,255,255,0)',
                    borderBottom: '2px solid #257FA4',

                },
                '&:not(.Mui-disabled):before': {
                    borderBottom: '1px solid #257FA4',

                },

            },
            '&:active':{
                background: 'rgba(255,255,255,0) !important',

            }
        
        },
        '&:before': {
            border: '1px solid #CEDEE7',
            lineHeight: 3.3,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            background: 'rgba(255,255,255,0)',
            borderBottomStyle: 'solid'
        },
        '&:after': {
            borderBottomColor: '#257fa4'
        },

    },

});

export { StylesSurgirTextField }
