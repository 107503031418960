import { SurgirhoverListProps } from "../SurgirhoverList/SurgirhoverList";

export const menuItems: SurgirhoverListProps['menuItems'] = [
  {
      "title" : "Nosotros",
      "children": true,
      "link": "",
      "sections": [
          {
              "icon": "surgir-01",
              "title": "¿Quiénes somos?",
              "options": [
                  {
                      "title": "SURGIR",
                      "description": "¡Conócenos!",
                      "link": "/quienes-somos",
                      "targetBlank": false,
                      "keywords": "qué es surgir, quiénes somos"
                  }
              ]
          },
          {
              "icon": "shape",
              "title": "Grupo Santander",
              "options": [
                  {
                      "title": "Banco Santander Perú",
                      "description": "Banca Corporativa y Créditos Vehiculares.",
                      "link": "https://www.santander.com.pe/",
                      "targetBlank": false,
                      "keywords": "Banco Santander Perú"
                  },
                  {
                      "title": "Santander Consumer",
                      "description": "Créditos Vehiculares para personas naturales.",
                      "link": "https://www.santanderconsumer.com.pe/",
                      "targetBlank": false,
                      "keywords": "Santander Consumer"
                  }
              ]
          }
      ]
  },
  {
      "title" : "Productos",
      "children": true,
      "link": "",
      "sections": [
          {
              "icon": "investment",
              "title": "Ahorros",
              "options": [
                  {
                      "title": "Depósito a Plazo Fijo",
                      "description": "",
                      "link": "/deposito-a-plazo-fijo",
                      "targetBlank": false,
                      "keywords": "Depósito a Plazo Fijo"
                  },
                  {
                      "title": "Depósito a Plazo Fijo Flexible",
                      "description": "",
                      "link": "/deposito-a-plazo-fijo-flexible",
                      "targetBlank": false,
                      "keywords": "Depósito a Plazo Fijo Flexible"
                  },
                  {
                      "title": "Cuenta de Ahorros",
                      "description": "",
                      "link": "/cuenta-de-ahorros",
                      "targetBlank": false,
                      "keywords": "Cuenta de Ahorros"
                  }
              ]
          },
          {
              "icon": "financing",
              "title": "Créditos",
              "options": [
                  {
                      "title": "Crédito Mujeres Unidas",
                      "description": "",
                      "link": "/prestamo-solidario",
                      "targetBlank": false,
                      "keywords": "Crédito Mujeres Unidas"
                  },
                  {
                      "title": "Crédito Prospera",
                      "description": "",
                      "link": "/credito-prospera",
                      "targetBlank": false,
                      "keywords": "Crédito Prospera"
                  },
                  {
                      "title": "Crédito Construyendo Sueños",
                      "description": "",
                      "link": "/credito-prospera#construyendo-sueños",
                      "targetBlank": false,
                      "keywords": "Crédito Construyendo Sueños"
                  }
              ]
          },
          {
              "icon": "civil-liability",
              "title": "Seguros",
              "options": [
                  {
                      "title": "Super Seguro",
                      "description": "",
                      "link": "/super-seguro",
                      "targetBlank": false,
                      "keywords": "Super Seguro"
                  },
                //   {
                //     "title": "Seguro Desgravamen",
                //     "description": "",
                //     "link": "/seguro-desgravamen",
                //     "targetBlank": false,
                //     "keywords": "Seguro Desgravamen"
                //  },
                  {
                      "title": "Seguro Desgravamen Porcentual",
                      "description": "",
                      "link": "/seguro-desgravamen-porcentual",
                      "targetBlank": false,
                      "keywords": "Seguro Desgravamen Porcentual"
                  }
              ]
          }
      ]
  },
  {
      "title" : "Canales de Pago",
      "children": true,
      "link": "",
      "sections": [
          {
              "icon": "payment-dollar",
              "title": "Pagar mis cuotas",
              "options": [
                  {
                      "title": "Canales de Pago",
                      "description": "",
                      "link": "/canales",
                      "targetBlank": false,
                      "keywords": "Dónde pago, canales de pago, Pagar mis cuotas"
                  }
              ]
          }
      ]
  },
  {
      "title" : "Ayuda y contacto",
      "children": true,
      "link": "",
      "sections": [
          {
              "icon": "call-support",
              "title": "Te atendemos",
              "options": [
                  {
                      "title": "Contáctanos",
                      "description": "",
                      "link": "/contactanos",
                      "targetBlank": false,
                      "keywords": "Contáctanos, atención"
                  },
                  {
                      "title": "Zona de Cobertura",
                      "description": "",
                      "link": "/donde-estamos#zona-cobertura",
                      "targetBlank": false,
                      "keywords": "Zona de Cobertura"
                  },
                  {
                      "title": "¿Dónde estamos?",
                      "description": "",
                      "link": "/donde-estamos",
                      "targetBlank": false,
                      "keywords": "Dónde están ubicados"
                  }
              ]
          },
          {
              "icon": "deal-handshake",
              "title": "Te ayudamos",
              "options": [
                  {
                      "title": "Preguntas Frecuentes",
                      "description": "",
                      "link": "/preguntas-frecuentes",
                      "targetBlank": false,
                      "keywords": "Preguntas Frecuentes"
                  },
                  {
                      "title": "Reclamos y Requerimientos",
                      "description": "",
                      "link": "/reclamos-y-requerimientos",
                      "targetBlank": false,
                      "keywords": "Reclamos y Requerimientos, libro de reclamaciones"
                  }
              ]
          }
      ]
  },
  {
      "title" : "Promociones",
      "children": true,
      "link": "/historial-de-promociones",
      "sections": []
  }
]