import React from "react";
import "./SurgirSlideSantander.scss";
import { ReactComponent as SantanderWhite } from '../../../assets/logos/santander-white.svg';
import { Container } from "react-bootstrap";
import SurgirButton from "../SurgirButton/SurgirButton";

interface SurgirSlideSantanderProps {
  isBorderRadius?: boolean;
  textButtonSlide?: string;
  widthButtonSlide?: string;
  radiusButtonSlide?: string;
  linkButton?: string;
}
const SurgirSlideSantander: React.FC<SurgirSlideSantanderProps> = ({
  isBorderRadius,
  textButtonSlide = '¡Conócenos más!',
  widthButtonSlide = '167px',
  radiusButtonSlide = '32px',
  linkButton = 'https://www.santander.com/es/home'
}) => {

  const redirectAbout = () => {
    window.open(linkButton, '_blank', "noopener");
  };

  return (
    <div 
      className="slide-santander"
      style={{borderRadius: isBorderRadius ? '12px' : '0px'}}
    >
      <Container>
        <div className="slide-santander__content">
          <div className="slide-santander__content__logo">
            <div data-testid="santander-logo">
              <div><span>Somos una empresa de</span></div>
              <SantanderWhite  />
            </div>
          </div>

          <div className="slide-santander__content__separador-vertical"></div>

          <div className="slide-santander__content__description">
            <p>
              Uno de los bancos más grandes del mundo con más de 160 años de experiencia y ubicados en 9 países, abarcando Europa, Norteamérica y Sudamérica.
            </p>
            <div>
              <SurgirButton 
                onClick={() => redirectAbout()}
                radius={radiusButtonSlide}
                fontFamily={'Str-Microtext-Bold'} 
                width={widthButtonSlide} 
                height='48px' 
                marginTop= "24px"
                marginBottom="0px"
                variant="" 
                color="white"
                colorText="#EC0000"
              >
                {textButtonSlide}
              </SurgirButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
};

export default SurgirSlideSantander;