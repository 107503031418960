import React from "react";
import { Autocomplete, Chip, FormHelperText } from "@mui/material";
import ChevronDown from './../../../assets/images/ic_arrowDown.svg';
import { StylesSurgirTextField } from "utils/StylesSurgirTextField";

interface SurgirDropDownProps {
  error?:boolean ;
  messageError?:string ;
  dataSource:any ;
  hint:string ;
  value:any;
  onChange: (value: any) => void;
  id?: string;
  name?:string;
  classAutocomplete?: string;
}

const SurgirDropDown: React.FC<SurgirDropDownProps> = ({
  error,
  messageError,
  dataSource,
  hint,
  value,
  onChange,
  id,
  name,
  classAutocomplete
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        autoComplete={false}
        size="medium"
        className={`${classAutocomplete}`}
        options={dataSource}
        noOptionsText={''}
        disableClearable
        isOptionEqualToValue={(option: any, value) => {
          for (let key in option) {
            const check = option[key] == value[key]
            if (!check) {
              return check;
            }
          }
          return true;
        }}
        getOptionLabel={(option) => option['name']}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option['name']}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        value={value}
        onChange={(e, value) => onChange(value)}
        popupIcon={
          <img alt="" src={ChevronDown} style={{ marginRight: 10 }}/>
        }
        style={{ cursor: 'none' }}
        renderInput={(params) => (
          <StylesSurgirTextField
            {...params}
            variant="filled"
            label={hint}
            error={error}
            name={name}
          />
        )}
          
      />
      {error && <FormHelperText error style={{ marginLeft: 16 }} >{messageError}</FormHelperText>}
    </>
  )
}

export default SurgirDropDown
