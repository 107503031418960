import React from "react";

export const frequentQuestions = [
    {
        title: '¿Qué es el Crédito Prospera?',
        body: <p> Es la modalidad e crédito destinada a financiar
        todas las necesidades de tu negocio en capital de
        trabajo y activo fijo, otorgamos hasta el 100% de tu
        proyecto de inversión y de acuerdo a tu capacidad de
        pago.</p>
    },
    {
        title: '¿Hasta que monto se te puede otorgar?',
        body: <p>Los montos se prestan de acuerdo a tu proyecto y tu
        capacidad para pagarlo.</p>
    },
    {
        title: '¿Dónde se puede solicitar un Crédito Prospera?',
        body: <div>
                                        <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                              -{" "}
                            </span>Solicítalo a través de nuestra web  <a
                              className="linkturquesa"
                              style={{ color: "#257fa4" }}
                              href="/contactanos"
                            >Clic aquí</a>.<br /> <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                              -{" "}
                            </span>En nuestras oficinas de atención al público en Los Olivos y San Juan de Lurigancho.
                            <br /><span style={{ color: "#ec0000", fontStyle: "bold" }}>
                              -{" "}
                            </span>A través de nuestros asesores en los distritos de Puente Piedra, Comas, San Juan de Lurigancho, Los Olivos, Independencia, Ate, Villa El Salvador, Villa Maria del Triunfo, Ventanilla, San Juan de Miraflores, Huachipa y Lurín.
        </div>
    },
    {
        title: '¿Existe algún requisito particular que debo cumplir?',
        body: <div>
                                        <span style={{ color: "#ec0000", fontStyle: "bold" }}>
                              -{" "}
                            </span>{" "} Al momento de realizar tu evaluación crediticia deberás: Estar bien calificado en el sistema financiero. Tener un mínimo de 6 meses de experiencia en gestión de su negocio. Ser propietario del negocio en el que se va a invertir.
                            <br /><span style={{ color: "#ec0000", fontStyle: "bold" }}>
                              -{" "}
                            </span>{" "}   Tener documentos del negocio como: recibo de servicios, comprobantes comerciales, documento que acredite propiedad o arrendamiento de local comercial o activo fijo.
        </div>
    },
    {
        title: '¿Toman en cuenta la calificación de mi cónyuge al momento de evaluar el crédito?',
        body: <p>Si, tú y tu cónyuge deben estar al día en el pago de
        sus créditos y cumplir nuestras condiciones de
        admisión.</p>
    },
    {
        title: '¿Es obligatoria la firma de mi conyugue en los documentos contractuales?',
        body: <p>Dependiendo de tu calificación y del monto que
        solicites podrá ser obligatoria la firma de tu
        cónyuge.</p>
    },
    {
        title: '¿Cuándo se considera a un cliente como “Mal Calificado”?',
        body: <div>
            Todo cliente que tenga una calificación diferente a
                            “Normal” se considera como “Mal calificada” (“CPP”,
                            “Deficiente”, “Dudoso”, “Pérdida”).
                            <table>
                              <tr>
                                <th>Calificación</th>
                                <th>Días de atraso</th>
                              </tr>
                              <tr>
                                <td>Normal </td>
                                <td>0 a 8 días</td>
                              </tr>
                              <tr>
                                <td>CPP</td>
                                <td>9 a 30 días </td>
                              </tr>
                              <tr>
                                <td>Deficiente</td>
                                <td>31 a 60 días</td>
                              </tr>
                              <tr>
                                <td>Dudoso</td>
                                <td>61 a 120 días</td>
                              </tr>
                              <tr>
                                <td>Pérdida</td>
                                <td>121 días a más </td>
                              </tr>
                            </table>
                            De acuerdo con la Resolución SBS 11356, existen las
                            siguientes categorías. *Días calendario
        </div>
    },
    {
        title: '¿Se requiere contar con un aval para acceder a un Crédito Prospera?',
        body: <p>No, la evaluación es siempre sobre el titular y su
        cónyuge.</p>
    },
    {
        title: '¿Es obligatorio adquirir un seguro para acceder a un crédito?',
        body: <p> No, la adquisición del seguro optativo es voluntaria. Solo el seguro desgravamen es obligatorio.</p>
    },
    {
        title: 'Trabajo en una empresa y también tengo un pequeño negocio ¿puedo acceder a este crédito?',
        body: <p> Sí, haremos una evaluación integral de todos tus
        ingresos para proyectar cual es el monto al que
        tendrías acceso.</p>
    },
    {
        title: 'Mi cónyuge tiene tarjetas y deudas en otras entidades ¿también se evalúa?',
        body: <p>Sí, al momento de evaluarlos se toma a ambos como una unidad familiar y se revisa el comportamiento y endeudamiento de ambos.</p>
    },
    {
        title: '¿Puedo solicitar un crédito nuevo, si cuento con un crédito vigente en SURGIR?',
        body: <div> Si, siempre y cuando pagues de forma puntual las cuotas de tu crédito y cumplas con nuestras políticas de admisión, te podemos otorgar un nuevo crédito:
        <ul style={{ margin: '20px' }}>
          <li style={{ listStyleType: 'disc', color: 'black' }}>Paralelo: Crédito adicional al vigente, el cual tendrá su propio plazo y día de pago.</li>
          <li style={{ listStyleType: 'disc', color: 'black', marginTop: '10px' }}>Ampliación: Nuevo crédito, que te permite cancelar el total de tu crédito anterior y disfrutar del monto restante en tu negocio, con un nuevo cronograma, fecha y cuotas.</li>
        </ul></div>
    },
    {
        title: '¿Puedo ampliar mi crédito vigente?',
        body: <div>
            Sí, para ello deberás cumplir con nuestras políticas de admisión, demostrando tener la capacidad y voluntad de pago. Podrás comunicarte con tu Asesor de Negocio para ser evaluado.
                            <p style={{ marginTop: '5px' }}> Ampliar tu crédito implica un nuevo desembolso con la cancelación del crédito anterior pudiendo disponer de la diferencia de esta liquidación.</p>
        </div>
    },
]