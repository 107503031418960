import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as IcCloseBanner } from "../../../assets/images/ic_close_banner.svg";
import SurgirItemListWithIcon from "../SurgirItemListWithIcon/SurgirItemListWithIcon";

interface DocumentItem {
  name: string;
  Icon: any;
  document: any;
  downloadName: string;
}

interface ModalDownloadProps {
  title: string;
  description: string;
  show: boolean;
  handleClose: () => void;
  documentList: DocumentItem[];

}

const SurgirModalDownload: FC<ModalDownloadProps> = ({ description, title, show, handleClose, documentList }) => {
  return (
    <div className="success-modal">
      <Modal className="margen-modal" show={show} onHide={handleClose}>
        <Modal.Header style={{ paddingLeft: 30 }}>
          <span className="title_modal">
            { title }
          </span>
          <div style={{ width: "10%", textAlign: "right" }}>
            <Button variant="link" onClick={handleClose}>
              {" "}
              <IcCloseBanner />{" "}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 15 }}>
            <span className="parrafo">
              { description }
            </span>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>
              {documentList.map(item => {
                return (
                  <SurgirItemListWithIcon key={item.name} icon={item.Icon} document={item.document} downloadName={item.downloadName} name={item.name} />
                )
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { SurgirModalDownload };