import { ProductType } from "views/requestCredit/RequestCredit";
import { PATTER_ALPH, PATTER_STRING, PATTER_WHITE_SPACE } from "./constants";

const messageP1 = "Pendiente completar este campo.";
const messageObject = "Pendiente seleccionar una opción";
const emptyTypeDoc = "Elije primero un tipo de documento";
const messageDNI = "El número debe contener 8 dígitos.";
const messageEmail = "Ingresa un correo válido: “ejemplo@correo.com”.";
const messageText = "Solo letras (y signos que van con letras como tilde, diéresis, ñ, etc.)";
const messagePhone = "Debe ser menor o igual a 13 dígitos";
const messageNumber = "Solo números";
const messageDecimal = "Formato ejemplo: 150,350,000.00 (decimales separados por punto “.” y grupos de 3 cifras separadas por coma “,”)";
const messagePasaporteOther = "El número debe contener 12 dígitos.";

interface ValidationResult {
  error: boolean;
  message: string;
}
interface Request {
  typeDocument?: string;
  document?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  amount?: string;
  department?: string;
  province?: string;
  district?: string;
  queryType?: string;
  message?: any;
  fullName?: string;
  departament?: string;
  typeCreditProduct?: ProductType;
}

export const isEmptyString = (value: any): boolean => {
  return value == undefined || value == null || value === "" || value === "";
};

export const isEmptyObject = (value: any): boolean => {
  return value == null || value == undefined || Object.keys(value).length === 0;
};

export const validateFieldString = (value: any): ValidationResult => {
  return {
    error: isEmptyString(value),
    message: messageP1,
  };
};


export const validateLimitLetter = (value: any, limit?: number): ValidationResult => {
  let validationResult = validateFieldString(value);
  let limit_ = limit ?? 4;
  const text = value.split('').filter((x: string) => x.trim());
  let letterPrev = '';
  let exceeded = 0;
  let listExceeded: number[] = [];

  for(const letter of text) {
     if(letterPrev === ''){
      exceeded+=1;
      letterPrev = letter;
     }else{
      if(letter === letterPrev) {
        exceeded+=1;
      }else{
        listExceeded.push(exceeded);
        exceeded = 1;
      }
      letterPrev = letter;
     }
  }
  exceeded > limit_ && listExceeded.push(exceeded);

  if(listExceeded.some(x => x > limit_) ){
    return {
      error: true,
      message: `Solo se permiten hasta ${limit_} letras iguales`
    }
  }

  if (value.length && !PATTER_STRING.test(value)) {
    return {
      error: true,
      message: 'Texto no válido'
    }
  }

  if (value.length && !PATTER_WHITE_SPACE.test(value.trim())) {
    return {
      error: true,
      message: 'Texto no debe de contener más de un espacio'
    }
  }

  return validationResult;
};

export const validateFieldObject = (value: any): ValidationResult => {
  return {
    error: isEmptyObject(value),
    message: messageObject,
  };
};

export const validateDocumentNumber = (value: string, typeDocument: string | undefined): ValidationResult => {
  if (typeDocument === null || typeDocument === undefined || typeDocument === "") {
    return {
      error: true,
      message: emptyTypeDoc,
    };
  }

  if (isEmptyString(value)) {
    return {
      error: true,
      message: messageP1,
    };
  }

  if (typeDocument === '01') {
    return {
      error: value.length !== 8,
      message: messageDNI,
    };
  }

  if (typeDocument === '02' || typeDocument === '03' || typeDocument === '06' || typeDocument === '07' || typeDocument === '04') {
    return {
      error: value.length !== 12,
      message: messagePasaporteOther,
    };
  }
  
  return {
    error: false,
    message: '',
  };
  
};

export const validateEmail = (value: string): ValidationResult => {
  const regex = /^[a-zA-Z0-9._+-]{0,50}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    return {
      error: !regex.test(value),
      message: messageEmail,
    };
  }
};

export const validateEmailv2 = (value: string): ValidationResult => {
  let resultValidation = validateEmail(value);
  let emailUser = value.split('@')[0];
  let emailUserEnd = value.split('@')[1];
  let specialSymbolsEmail = ['.','-',',','_','+'];

  if(specialSymbolsEmail.includes(emailUser.split('').shift() ?? '')){
    return {
      error : true,
      message: 'No se permite caracteres especiales al inicio'
    }
  }

  if(emailUserEnd){
      const validation = emailUserEndValidation(emailUserEnd, resultValidation, emailUser, specialSymbolsEmail);
      if(validation) {
        return validation
      }
  }
  
  return resultValidation;
};

const emailUserEndValidation = (emailUserEnd :string, resultValidation: ValidationResult, emailUser: string, specialSymbolsEmail: string[]) => {
  let mailServer = emailUserEnd.split('.').filter((x, i) => emailUserEnd.split('.').length !== (i + 1) && x).toString().replace(/,/g, '.');
  let domain = emailUserEnd.split('.').pop() ?? '';
  let matchPoints: number[] = [];
  let listDomains = ['com','es','edu','pe','net'];
  let regexServer = /^[a-zA-Z0-9.]+$/g;
  let repeatsCharactersServer = detectRepeatedCharacters(emailUserEnd, ['.']);

   if(!resultValidation.error){
     
    matchPoints = detectRepeatedCharacters(emailUser, specialSymbolsEmail);

     if(matchPoints.length && matchPoints.some(x => x >= 2)) {
      return {
        error : true,
        message: 'No se permite caracteres consecutivos'
      }
     }

     if(specialSymbolsEmail.includes(`${emailUser.split('').pop()}`)){
      return {
        error : true,
        message: `No se permite ${emailUser.split('').pop()} antes del @`
      }
     }

     if(!regexServer.test(mailServer) || mailServer.split('.').length > 3){
      return {
        error : true,
        message: 'Servidor de correo no valido'
      }
     }

     if(['.'].includes(`${emailUserEnd.split('').shift()}`)){
      return {
        error : true,
        message: 'No se permite . despues del @',
      }
    }

     if(repeatsCharactersServer.some(x => x >= 2)){
      return {
        error : true,
        message: 'No se permite caracteres consecutivos en el servidor del correo'
      }
     }

     if(!listDomains.includes(domain.toLocaleLowerCase())){
      return {
        error : true,
        message: 'Dominio no valido'
      }
     }
     
   }
}

const detectRepeatedCharacters = (val: string, characteresInvalid: string[]) => {
  let points = 0;
  let prevLetter = '';
  let matchPoints: number[] = [];

  for(const letter of val.split('')){
    if(prevLetter === ''){
      characteresInvalid.includes(letter) && (points+=1);
      prevLetter = letter;
    }else if(characteresInvalid.includes(letter)) {
        points+=1
      }else{
        matchPoints.push(points);
        points=0;
      }      
   }

   points > 0 && matchPoints.push(points);

   return matchPoints
}

export const completeForm = (request: Request): boolean => {
  if (isEmptyString(request.typeDocument)) {
    return false;
  }
  if (isEmptyString(request.document)) {
    return false;
  }
  if (isEmptyString(request.firstName)) {
    return false;
  }
  if (isEmptyString(request.lastName)) {
    return false;
  }
  if (isEmptyString(request.phone)) {
    return false;
  }
  if (validateEmail(request.email!).error) {
    return false;
  }
  if (isEmptyString(request.department)) {
    return false;
  }
  if (isEmptyString(request.province)) {
    return false;
  }
  if (isEmptyString(request.district)) {
    return false;
  }
  if (isEmptyString(request.queryType)) {
    return false;
  }
  if (isEmptyObject(request.message)) {
    return false;
  }
  return true;
};

export const completeFormRequestCredit = (request: Request): boolean => {
  if (isEmptyString(request.typeDocument)) {
    return false;
  }
  if (isEmptyString(request.document)) {
    return false;
  }
  if (isEmptyString(request.fullName)) {
    return false;
  }
  if (isEmptyString(request.lastName)) {
    return false;
  }
  if (isEmptyString(request.phone) || `${request.phone}`.length < 9) {
    return false;
  }
  if (validateEmail(request.email!).error) {
    return false;
  }
  if (isEmptyString(request.department)) {
    return false;
  }
  if (isEmptyString(request.province)) {
    return false;
  }
  if (isEmptyString(request.district)) {
    return false;
  }
  if (Object.values(request.typeCreditProduct ?? {}).every(x => !x)) {
    return false
  }
  return true;
};

export const validateForm = (request: Request): { complete: boolean } => {
  const complete = completeForm(request);

  return {
    complete: complete,
  };
};

export const validateFormRequestCredit = (request: Request): { complete: boolean } => {
  const complete = completeFormRequestCredit(request);

  return {
    complete: complete,
  };
};

export const completeFormDPF = (request: Request): boolean => {
  if (isEmptyString(request.fullName)) {
    return false;
  }

  if (isEmptyString(request.lastName)) {
    return false;
  }

  if (isEmptyString(request.document)) {
    return false;
  }

  if (isEmptyString(request.phone)) {
    return false;
  }
  if (validateEmail(request.email!).error) {
    return false;
  }
  if (isEmptyString(request.department)) {
    return false;
  }
  if (isEmptyString(request.province)) {
    return false;
  }
  if (isEmptyString(request.district)) {
    return false;
  }

  if (isEmptyString(request.amount)) {
    return false;
  }
  return true;
};

export const validateFormDPF = (request: Request): { complete: boolean } => {
  const complete = completeFormDPF(request);

  return {
    complete: complete,
  };
};

export const validateText = (value: string): ValidationResult => {
  const regex = PATTER_ALPH;

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    return {
      error: !regex.test(value),
      message: messageText,
    };
  }
};

export const validatePhone = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d*$/);

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  }

  if (!regex.test(value)) {
    return {
      error: !regex.test(value),
      message: messageNumber,
    };
  }

  return {
    error: value.toString().split("").length > 13,
    message: messagePhone,
  };
};

export const validateCellPhone = (value: string): ValidationResult => {
   let resultValid = validatePhone(value);
   let repeatDigits = 0;
   let prevNumber = '';
   let listRepeats: number[] = [];

   for(const number of value.split('')) {
     if(prevNumber === ''){
       prevNumber = number;
     }

     if(number === prevNumber){
        repeatDigits+=1;
     }else {
      repeatDigits > 1 && listRepeats.push(repeatDigits);
      repeatDigits = 1;
     }
     prevNumber = number;
   }

   repeatDigits > 1 && listRepeats.push(repeatDigits);

   if(listRepeats.some(x => x >= 9)) {
    return {
      error: true,
      message: 'Hay demasiados dígitos iguales'
    }
   }
   if(!resultValid.error && value.trim().length !== 9) {
    return {
      error: true,
      message: 'Ingrese 9 dígitos'
    }
   }
   return resultValid;
}


export const validatePhoneOptional = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d*$/);

  if (!regex.test(value)) {
    return {
      error: !regex.test(value),
      message: messageNumber,
    };
  } else {
    return {
      error: value.toString().split("").length > 13,
      message: messagePhone,
    };
  }
};

export const validateDecimal = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d{1,3}(?:\d{1,3})?(?:\d{1,3})?(?:\.\d{1,2})?$/);
  if (value) {
    return {
      error: !regex.test(value),
      message: messageDecimal,
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export const validateRequired = (value: string): ValidationResult => {
    if (isEmptyString(value)) {
        return {
          error: isEmptyString(value),
          message: messageP1,
        };
      } else {
        return {
          error: false,
          message: "",
        };
      }
    };
    
    export const patternTypeDocument = (typeDocument: { name: string } | undefined): RegExp => {
      if (typeDocument?.name === "DNI") {
        return /^(\d{0,8})$/g;
      }
    
      if (typeDocument?.name === "Pasaporte") {
        return /^([a-zA-Z]|\d){0,12}$/g;
      }
    
      if (typeDocument?.name === "Carné de Extranjería" || typeDocument?.name === "CEX" || typeDocument?.name === "Carné de Permiso Temporal de Permanencia" || typeDocument?.name === "PTP") {
        return /^(\d){0,12}$/g;
      }
    
      return /^.*$/g;
    };
