import React from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { ReactComponent as IcBannerSuccess } from "../../../src/assets/images/ic_banner_success.svg";
import { ReactComponent as IcCloseBanner } from "../../../src/assets/images/ic_close_banner.svg";

interface ModalSuccessProps {
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
  handleReset: () => void;
}

const ModalSuccess: React.FC<ModalSuccessProps> = ({
  show,
  handleClose,
  handleShow,
  handleReset,
}) => {
  return (
    <div className="success-modal">
      <Modal style={{ marginTop: "8%" }} show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button variant="link" onClick={handleClose}>
              {" "}
              <IcCloseBanner />{" "}
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingLeft: 50, paddingRight: 50 }}>
            <IcBannerSuccess />
            <br />
            <span className="subtitle_form"> Gracias por contactarte con nosotros</span>
            <br />
            <span className="parrafo">Nos estaremos comunicando contigo en el menor tiempo posible.</span>

            <Col>
              <Button
                style={{
                  width: "99%",
                  borderRadius: 32,
                  fontFamily: "Str-Microtext-Bold",
                  fontSize: "16",
                  height: 48,
                  textAlign: "center",
                  marginTop: 30,
                  marginBottom: 50,
                }}
                variant="danger"
                onClick={handleClose}
              >
                ¡Listo!{" "}
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalSuccess;