import React, { useEffect } from "react";
import "./result-search-view.scss";
import { useState } from "react";
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import { CategoryPrincipal } from "../../../types/search";
import { apiGetListByKeybord } from "service/apiEndPoint";
import { createLink } from "utils/urls";

interface dataFilter {
  value_search: string;
  data: CategoryPrincipal[];
}

interface Categories {
  name: string;
  quantity: number;
}

const tagName = {
  iconPdf: 'PDF',
  iconExcel: 'EXCEL'
}

function ResultSearchView() {
  const [dataFilter, setDataFilter] = useState<dataFilter>({
    value_search : '',
    data: []
  });
  const [categories, setCategories] = useState<Categories[]>([]);
  const [categorySelect, setCategorySelect] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [valSearch, setValSearch] = useState('');
  const [options, setOptions] = useState<CategoryPrincipal[]>([]);
  const [results, setResults] = useState<CategoryPrincipal[]>([]);
  const [loading, setLoading] = useState(false);
 
  const changeSearch = (event: any) => {
    setValSearch(event.target.value);
  };

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch();
    }
  }

  const initValues = (localData: string) => {
    const valueInitial = JSON.parse(localData);
    setDataFilter(valueInitial);
    setValSearch(valueInitial.value_search);
    setOptions(valueInitial.data);
    mapCategories(valueInitial.data);
    setTimeout(() => {
     localStorage.removeItem('search_data');
    }, 1000);
  }

  const mapCategories = (data: CategoryPrincipal[]) => {
    const categories = data.map(x => x.category);
    let totalCategories: Categories[] = [];
    for(const category of categories){
       const index = totalCategories.findIndex(x => x.name.toLowerCase() === category.toLowerCase());
       if(index < 0 ){
        totalCategories.push({
          name : category,
          quantity: 1
        }) 
       }

       if(index >=0){
        totalCategories.splice(index,1,{
          name : category,
          quantity: totalCategories[index].quantity + 1
        })
       }
       
    }
    setCategories(totalCategories);
  }

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  const handleCategory = (categoryName: string) => {
     const cate = categorySelect;
     const filter = dataFilter?.data.filter(x => removeAccents(x.category.toLocaleLowerCase()) === removeAccents(categoryName.toLocaleLowerCase()));
     if(!cate.includes(categoryName)){
      cate.push(categoryName);
      setResults(val => ([...val, ...filter]));
      const results_ = [...results, ...filter];
      filter?.length && setOptions(results_);
     }
  }

  const ClearFilters = () => {
    dataFilter?.data && setOptions(dataFilter.data);
    setCategorySelect([]);
    setResults([]);
  }

  const handleSearch = () => {
    setLoading(true);
    setOptions([]);
    setCategories([]);
    setCategorySelect([]);
    setResults([]);
    if(valSearch.length < 3){
      setLoading(false);
    }else{
      callListByKey(valSearch);
    }
  }

  const callListByKey = (key: string) => {
    apiGetListByKeybord({ keyword: key })
      .then((response) => {
        return response.json();
      })
      .then((keydata) => {
        setDataFilter({
          ...dataFilter,
          data: keydata.data
        });
        setOptions(keydata.data);
        setLoading(false);
        mapCategories(keydata.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(!localStorage.getItem('search_data')){
      createLink('/');
      return
    }
    
    initValues(localStorage.getItem('search_data') as string);
  },[])

  return (
    <div className="container-result-search">
      <div className="container-result-search-fixed">
         <div className="container-search-static">
         <div className="spacing-desktop"></div>
        <div className="container-fixed-center">
          <div className="detail-search">
            <span>Resultados de búsqueda para:</span>
            <span>"{valSearch}"</span>
          </div>

          <div className="container-head-search">
            <div className="input-search">
              <img className="ic-search" alt="icon-search" />
              <input
                type="text"
                placeholder="Buscar en SURGIR"
                onChange={changeSearch}
                onKeyUp={onKeyUp}
                value={valSearch} 
              />
            </div>
            <SurgirButton
              radius={"50px"}
              onClick={handleSearch}
              fontFamily={"Str-Microtext-Bold"}
              width="136px"
              height="48px"
              marginTop="0px"
              marginBottom="0px"
              variant="danger"
            >
              Buscar
            </SurgirButton>
          </div>

          <div className="filters-search">
            <div onClick={() => setShowFilter(!showFilter)}>
              <img alt="icon-search" className={`${showFilter && 'rotate-180'}`}/>
              <span>Filtra tus resultados</span>
            </div>
            <span onClick={ClearFilters} className={`${categorySelect.length && 'active-clear' }`}>Limpiar</span>
          </div>

          {
            showFilter && <div className="container-filters-chips">
            {categories.map((category, i) => (
              <div key={i} className={`chip-filter ${categorySelect.includes(category.name) && 'chip-active'}`}
                   onClick={() => handleCategory(category.name)}>
                {category.name} ({category.quantity})
              </div>
            ))}
          </div>
          }
        </div>
         </div>
      </div>
      <div className="container-fixed-results">
        <div className="container-center-result">
          <div className="filter-search-desktop">
            <div className="filters-search">
              <div>
                <span>Filtra tus resultados</span>
              </div>
              <span onClick={ClearFilters} className={`${categorySelect.length && 'active-clear' }`}>Limpiar</span>
            </div>

            <div className="container-filters-chips">
               {categories.map((category, i) => (
                 <div key={i} className={`chip-filter ${categorySelect.includes(category.name) && 'chip-active'}`}
                 onClick={() => handleCategory(category.name)}>{category.name} ({category.quantity})</div>
               ))}
            </div>
          </div>
          <div className="container-results">
             {loading && (
                <div className="container-loader">
                  <div>
                    <img alt="icon-loader" />
                  </div>
                </div>
              )}
            {
              !loading && <>
              <span className="text-head">
              <p>Hemos encontrado </p>
              <p>{options.length} resultados</p>
            </span>
            {
              !options.length && <div className="container-hidden-data">
              <img alt="icon-search-incognito" />
              <div>
               <span>Lo sentimos, no conseguimos lo que buscas</span>
               <span>Prueba con una búsqueda diferente</span>
              </div>
           </div>
            }
              </>
            }
            {options.map((option, i) => (
              <div key={i} className="item-option" onClick={() => createLink(option.moreLink, '_blank')}>
                <div className="detail-option">
                  <div>
                    <span>
                      <div className="tag-result">
                        { option.isDownload ? tagName[option.iconClass as keyof typeof tagName] : option.category }
                        </div>    
                      <p>{option.title}</p>
                    </span>
                    <span>{option.subTitle}</span>
                  </div>
                </div>
                <img className="ic-arrow-right" alt="icon-right" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultSearchView;
