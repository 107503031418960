import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import SurgirAutocomplete from "components/ui/SurgirAutocomplete/SurgirAutocomplete";
import SurgirCheckOptions from "components/ui/SurgirCheckOptions/SurgirCheckOptions";
import SurgirFileUpload from "components/ui/SurgirFileUpload/SurgirFileUpload";
import { SurgirTextArea } from "components/ui/SurgirTextArea/SurgirTextArea";
import { SurgirTextField } from "components/ui/SurgirTextField/SurgirTextField";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from "react";
import { apiGetCoinsType, apiGetProducts, apiGetReasons, apiGetRequirement, apiGetSubReasons } from "service/apiEndPoint";
import { datacheck, datacheckRequerimiento, descripTooltipClaim, descripTooltipRequirement, subTitleClaim, subTitleRequirements, titleRadioSurgirClient } from "views/complaints-book/ComplaintsBook.fixture";
import './FormInformationComplaints.scss'
import { SurgirSnackbarContentWrapper } from "components/ui/SurgirSnackbarContentWrapper/SurgirSnackbarContentWrapper";
import { validateFieldObject, validateFieldString } from "utils/ValidateField";

interface FormInformationComplaintsProps {
  formData: any,
  setformData: any,
  showValidation: any,
  setShowValidation: any,
} 

const FormInformationComplaints: React.FC<FormInformationComplaintsProps> = ({
  formData,
  setformData,

}) => {
  const typeForm = sessionStorage.getItem("typeForm");
  const [listProducts, setListProducts] = useState([]);
  const [listReasons, setListReasons] = useState([]);
  const [subListReasons, setSubListReasons] = useState([]);
  const [listRequirements, setListRequirements] = useState([]);
  const [listCoins, setListCoins] = useState([]);
  const [visibleValidationProduct, setVisibleValidationProduct] =
    useState(false);
  const [visibleValidationClaim, setVisibleValidationClaim] = useState(false);
  const [visibleValidationTrouble, setVisibleValidationTrouble] =
    useState(false);
  const [visibleValidation] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {//el use effect se ejecuta cuando se crea la vista
    typeForm === "1" && getCoins();
    (formData.compliance || formData.policy) &&
      setformData({ ...formData, compliance: false, policy: false });
    getProduct();
    getRequirement();
    if (formData.productCode) {
      const payload = {
        productCode: formData.productCode.value
      }
      getReason(payload);
    }
    if (formData.reasonCode) {
      const payload = {
        reasonCode: formData.reasonCode.value
      }
      getSubReason(payload);
    }

  }, []);

  const getCoins = () => {
    apiGetCoinsType()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setListCoins(
          data.response.map((item: { coinCode: string, coinType: string }) => {
            return {
              value: item.coinCode,
              name: item.coinType,
            };
          })
        );
      })
      .catch((err) => console.log(err));

    return () => { };
  };
  const getProduct = () => { 
    apiGetProducts().then((response) => {
      return response.json()
    })
      .then((data) => {
        setListProducts(data.response.filter((item: {productCode: string}) => 
        typeForm == '1' || item.productCode!='09').map((item: { productCode: string, productType: string }) => {
          return {
            value: item.productCode,
            name: item.productType,
          }
        }))
      })
      .catch((error) => console.log(error))
  }
  const getRequirement = () => { 
    apiGetRequirement()
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setListRequirements(data.data.map((item: { requirementCode: string, typeRequirement: string }) => {
          return {
            value: item.requirementCode,
            name: item.typeRequirement,
          }
        }))
      })
      .catch((error) => console.log(error))
  }
  const getReason = (payload: any) => { 

    apiGetReasons(payload).then((response) => {
      return response.json()
    })
      .then((data) => {
        setListReasons(data.response.map((item: {
          reasonCode: string,
          reasonSBS: string,
          reasonType: string,
        }) => {
          return {
            value: item.reasonCode,
            valueSBS: item.reasonSBS,
            name: item.reasonType
          }
        }))
      })
      .catch((error) => console.log(error))
  }
  const getSubReason = (payload: any) => { 

    apiGetSubReasons(payload).then((response) => {
      return response.json()
    })
      .then((data) => {

        setSubListReasons(data.response.map((item: {
          subreasonCode: string,
          subreasonSBS: string,
          subreasonType: string,
        }) => {
          return {
            value: item.subreasonCode,
            valueSBS: item.subreasonSBS,
            name: item.subreasonType,
          }
        }))


      })
      .catch((error) => console.log(error))
  }
  const getValuesCheck = (values: any) => {
    setformData({
      ...formData,
      compliance: values[0].value,
      policy: values[1].value
    });
  };

  const handleChangeProduct = (e: any) => {
    if(e.value!='09'){
      setformData({ ...formData, productCode: e, reasonCode: null, subReasonCode: null })
      setListReasons([])
      setSubListReasons([])
      const payload = {
        productCode: e.value
      }
      getReason(payload);
    }else{
      setformData({ ...formData, productCode: e, reasonCode: {value:"00", name:"No Aplica"}, subReasonCode: {value:"00", name:"No Aplica"} })
      setSubListReasons([])
      setListReasons([])
    }
    
  }
  const handleChangeProblem = (e: any) => {
    setformData({ ...formData, reasonCode: e, subReasonCode: null })
    const payload = {
      reasonCode: e.value
    }
    getSubReason(payload);
  }
  const onChangeCaptcha = (val: string | null) => {
    setformData({ ...formData, captchaRef: val });
 };
  useEffect(() => {
    if (!formData.activeClient) {
      setformData({ ...formData, productCode: { value: '08', name: 'Atención al público' }, reasonCode: null, subReasonCode: null })
      const payload = {
        productCode: "06"
      }
      getReason(payload);
    }else{
      setformData({ ...formData, productCode: null , reasonCode: null, subReasonCode: null })
    }
  }, [formData.activeClient])

  useEffect(() => {
    if(subListReasons.length==0 && formData.reasonCode !==null ){
      setformData({ ...formData, subReasonCode: {value:"00", name:"No Aplica"} })

    }
  },[subListReasons])
  
  return (
    <div>
      <div className="container" style={{ padding: 0 }}>
        <div className="container-form-information">
          <form>
            <div className="container-static-information">
              {typeForm === "1" ? (
                <span className="title">{subTitleClaim}</span>
              ) : (
                <>
                  <span className="title">{subTitleRequirements}</span>
                </>
              )}

              <div className="container-radio-button-information">
                <span id="titleRadio">{titleRadioSurgirClient}</span>

                <RadioGroup
                  id="rbSurgirCustomer"
                  name="radiobutton-receive-response"
                  aria-label="client"
                  value={formData.activeClient}
                  onChange={(e) =>
                    setformData({
                      ...formData,
                      activeClient: parseInt(e.target.value),
                    })
                  }
                  row
                >
                  <FormControlLabel
                    value={1}
                    name="Sí"
                    control={<Radio />}
                    label="Sí"
                    className="radio-label-regular"
                  />

                  <FormControlLabel
                    value={0}
                    name="No"
                    control={<Radio />}
                    label="No"
                    className="radio-label-regular"
                  />
                </RadioGroup>
              </div>

              <div className="container-date-information">
                <TextField
                  id="txtIncidentDate"
                  name="input-incident-date"
                  label={
                    typeForm === "1"
                      ? "Fecha de la incidencia"
                      : "Fecha del evento"
                  }
                  type="date"
                  value={formData.date}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: '"Str-Microtext-Regular", sans-serif',
                      color: "#444444",
                    },
                  }}
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      date: new Date(e.target.value)
                        .toISOString()
                        .substring(0, 10),
                    });
                  }}
                  InputProps={{
                    inputProps: {
                      max: new Date().toISOString().substring(0, 10),
                    },
                  }}
                  className="text-date-surgir"
                />
              </div>
              <div className="text-optional-information">
                <span>Opcional</span>
              </div>
              <div 
                className="col-information"
                style={{ pointerEvents: formData.activeClient == 0 ? 'none' : 'inherit' }}>
                <SurgirAutocomplete
                  id={"selectProduct"}
                  name={"select-product"}
                  error={
                    visibleValidationProduct &&
                    validateFieldObject(formData.productCode).error
                  }
                  messageError={validateFieldObject(formData.productCode).message}
                  hint={"Producto"}
                  value={formData.productCode}
                  onChange={handleChangeProduct}
                  dataSource={listProducts}
                  required={true}
                  search={"name"}
                  disabled={formData.activeClient == 0}
                />
              </div>
              {typeForm === "1" ? (
                <>
                  <div className="col-information">
                    <SurgirAutocomplete
                      id={"selectReason"}
                      name={"select-reason"}
                      error={
                        visibleValidationTrouble &&
                        validateFieldObject(formData.reasonCode).error
                      }
                      messageError={
                        validateFieldObject(formData.reasonCode).message
                      }
                      hint={"Motivo"}
                      value={formData.reasonCode}
                      onChange={handleChangeProblem}
                      dataSource={listReasons}
                      required={true}
                      search={"name"}
                      disabled={listReasons.length == 0}
                    />
                  </div>
                  <div 
                    className="col-information"
                    style={{ pointerEvents: subListReasons.length == 0 ? 'none' : 'inherit' }}>
                    <SurgirAutocomplete
                      id={"selectSubReason"}
                      name={"select-subreason"}
                      error={
                        visibleValidationClaim &&
                        validateFieldObject(formData.subReasonCode).error
                      }
                      messageError={
                        validateFieldObject(formData.subReasonCode).message
                      }
                      hint={"Submotivo"}
                      value={formData.subReasonCode}
                      onChange={(e) =>
                        setformData({ ...formData, subReasonCode: e })
                      }
                      dataSource={subListReasons}
                      required={false}
                      search={"name"}
                      disabled={subListReasons.length == 0}
                    />
                  </div>
                  <div className="col-information">
                    <SurgirAutocomplete
                      id={"selectMoney"}
                      name={"select-money"}
                      error={
                        visibleValidation &&
                        validateFieldObject(formData.coin).error
                      }
                      messageError={validateFieldObject(formData.coin).message}
                      hint={"Moneda"}
                      value={formData.coin}
                      onChange={(e) => {
                        setformData({ ...formData, coin: e });
                      }}
                      dataSource={listCoins}
                      required={false}
                      search={"name"}
                    />
                  </div>
                  <div className="text-optional-information">
                    <span>Opcional</span>
                  </div>
                    <SurgirTextField
                      id="txtReclaimedAmount"
                      name="input-reclaimed-amount"
                      value={formData.amount}
                      label="Monto reclamado"
                      fullWidth={true}
                      variant="filled"
                      type="text"
                      validateError={validateFieldString}
                      FormatCustom={true}
                      setValue={(e) => {
                        setformData({ ...formData, amount: e });
                      }}
                      disabled={formData.coin == null}
                      prefix={formData.coin != null && formData.coin.name != 'Dolar' ? 'S/ ' : "$ "}
                    />

                  <div className="text-optional-information">
                    <span>Opcional</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-information">
                    <SurgirAutocomplete
                      id={"selectRequirementType"}
                      name={"select-requirement-type"}
                      error={
                        visibleValidation &&
                        validateFieldObject(formData.codeReasonRequest).error
                      }
                      messageError={
                        validateFieldObject(formData.codeReasonRequest).message
                      }
                      hint={"Tipo de requerimiento"}
                      value={formData.codeReasonRequest}
                      onChange={(e) =>
                        setformData({ ...formData, codeReasonRequest: e })
                      }
                      dataSource={listRequirements}
                      required={false}
                      search={"name"}
                    />
                  </div>
                </>
              )}
              <SurgirTextArea
                id="tareaDetail"
                name="textarea-detail"
                fullWidth={true}
                value={formData.claimMessage}
                onChange={(e) => {
                  setformData({ ...formData, claimMessage: e.target.value });
                }}
                label={
                  typeForm === "1"
                    ? "Detalles del reclamo o queja"
                    : "Detalles del requerimiento"
                }
                variant='filled'
                error={visibleValidation && validateFieldObject(formData.claimMessage).error}
                messageError={validateFieldObject(formData.claimMessage).message}
                maxLength={4000}
              />

              <SurgirFileUpload
                id="inputEvidence"
                name="input-evidence"
                size={3}
                titleButton={"Adjuntar evidencia"}
                dataFile={formData.dataFile}
                toolTip={true}
                titleTooltip={"Evidencia"}
                descriptionTooltip={
                  typeForm === "1"
                    ? descripTooltipClaim
                    : descripTooltipRequirement
                }
                getFiles={(e: any) => setformData({ ...formData, dataFile: e })}
              />

              <SurgirSnackbarContentWrapper
                open={open}
                onopen={setOpen}
              ></SurgirSnackbarContentWrapper>

              {typeForm === "1" ? (
                <SurgirCheckOptions
                  checkData={datacheck}
                  value={[formData.compliance, formData.policy]}
                  visibleValidation={visibleValidation}
                  allOptionsCheck={(e: any) =>
                    setformData({ ...formData, checksValidation: e })
                  }
                  getValues={getValuesCheck}
                ></SurgirCheckOptions>
              ) : (
                <SurgirCheckOptions
                  checkData={datacheckRequerimiento}
                  value={[formData.compliance, formData.policy]}
                  visibleValidation={visibleValidation}
                  allOptionsCheck={(e: any) =>
                    setformData({ ...formData, checksValidation: e })
                  }
                  getValues={getValuesCheck}
                ></SurgirCheckOptions>
              )}

              <div className="w-100 d-flex justify-content-center">
                <ReCAPTCHA 
                  style={{ textAlign: "center" }} 
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''} 
                  onChange={onChangeCaptcha} 
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FormInformationComplaints