import React from 'react';
import { FormHelperText } from '@mui/material';
import { StylesSurgirTextAreaField } from 'utils/StyleSurgirTextAreaField';


interface SurgirTextAreaProps {
  fullWidth: boolean;
  id?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  variant: any;
  error: boolean;
  messageError: string;
  maxLength: number;
  isDisabled?: boolean;
  name?: string;
}

const SurgirTextArea: React.FC<SurgirTextAreaProps> = ({
  messageError,
  error,
  value,
  onChange,
  label,
  fullWidth,
  variant,
  maxLength,
  isDisabled,
  id,
  name
}) => {
  return (
    <>
      <StylesSurgirTextAreaField
        helperText={error ? messageError : ''}
        error={error}
        value={value}
        onChange={onChange}
        label={label}
        multiline
        rows={5}
        aria-labelledby={id}
        inputProps={{ 
          maxLength,
          id: id,
          name: name,
         }}
        fullWidth={fullWidth}
        variant={variant}
        disabled={isDisabled ?? false}
        onCopy={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}
        onCut={(e) => e.preventDefault()}
      />
      <FormHelperText style={{ textAlign: 'right', width: '100%' }}>{`${value.length}/${maxLength}`}</FormHelperText>
    </>
  );
};

export { SurgirTextArea };
