import React, { useRef } from 'react';
import Slider from "react-slick";
import './SurgirSliders.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderCustom from './components/SliderCustom/SliderCustom';
import CustomDots from './components/CustomDots/CustomDots';
import { next, previous } from './SurgirSliders.const';


interface SurgirSlidersProps {
  sliders: any
}

const CustomPaging = () => (
  <div
    id='active-slide'
    style={{
      width: "12px",
      height: "12px",
      background: "rgba(34, 34, 34, 0.23)",
      borderRadius: "100%"
    }}
  >
  </div>
);

const SurgirSliders: React.FC<SurgirSlidersProps> = ({
  sliders
}) => {

  let sliderRef = useRef<Slider>(null);


  const isMedium = window.matchMedia('(min-width: 768px) and (max-width: 1199px)')?.matches;
  const isSmall = window.matchMedia('(min-width: 320px) and (max-width: 767px)')?.matches;

  const next_ = () => next(sliderRef);
  const previous_ = () => previous(sliderRef);

  const appendDots = (dots: React.ReactNode) => 
  <CustomDots
    isMedium={isMedium}
    isSmall={isSmall}
    next={next_}
    previous={previous_}
    dots={dots} 
  />;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 8000,
    appendDots: appendDots,
    customPaging: CustomPaging
  };

  return (

    <div className="slider-container"  data-testid="sg-sldr">
      <Slider ref={sliderRef} {...settings}>
        {
          sliders.map((slide: any) => {
            return (
              <div key={slide} className="slider-container__body">
                <div className="slider-container__body__content">
                  <SliderCustom
                    slideCustom={slide}
                  ></SliderCustom>
                </div>
              </div>
            )
          })
        }

      </Slider>
    </div>

  );
};

export default SurgirSliders;