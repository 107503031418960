import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { ReactComponent as IcCloseBanner } from "../../../../assets/images/ic_close_banner.svg";
import styled from "styled-components";
import PDFIcon from "Icons/PDFIcon";
import './ModalPromotions.scss'

interface ModalPromotions {
  title: string;
  description?: string;
  show: boolean;
  handleClose: () => void;
  documents: any
}

const ModalPromotions: React.FC<ModalPromotions> = ({
  title,
  description = 'Selecciona el documento que deseas abrir.',
  show,
  handleClose,
  documents
}) => {

  const ContainerItem = styled.div`
  display: flex;
  margin-top: 1px;
  margin-bottom: 15px;
  color:#444444;
  :hover{
    color:#EC0000;
  }`;

  const TextItem = styled.div`
  padding-left: 1rem;
  padding-top: 2px;
  cursor: pointer;
  font-family: "Str-Microtext-Regular", sans-serif;`;

  const IconItem = styled.div``;

  const ItemTabPDF = ({ name, url }: { name: string, url: string | undefined }) => {
    const openFile = () => {
      window.open(url);
    };
  return (
      <ContainerItem>
        <IconItem>
          <PDFIcon size={20} color={"#EC0000"} />
        </IconItem>
        <TextItem onClick={openFile}>{name}</TextItem>
      </ContainerItem>
  );
};

  return (
     <div >
      <Modal
        className="margen-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header style={{ paddingLeft: 30 }}>
          <span className="title_modal">{title}</span>
          <div style={{ width: "10%", textAlign: "right" }}>
            <Button variant="link" onClick={handleClose}>
              {" "}
              <IcCloseBanner />{" "}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className={title == 'Campaña: Mi independencia me hace SURGIR' ? 'scroll-modal': ''}>
          <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
            <span className="parrafo">
              {description}
            </span>
            <Row style={{ marginTop: 20 }}>
              {documents.map((document: any) => (
                <ItemTabPDF
                  name={document.name}
                  url={process.env[document.url]}
                />
              ))}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPromotions;