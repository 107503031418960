import React from "react";

export const aboutLoans = [
  {
    title: "¿Qué es el crédito Mujeres Unidas?",
    body: (
      <p>
        Es un crédito grupal que se otorga a mujeres emprendedoras quienes
        desean invertir en su negocio.
      </p>
    ),
  },
  {
    title: "¿Los montos se otorgan a nivel grupo o por cada integrante?",
    body: (
      <p>
        Los montos se prestan a cada integrante de acuerdo a sus necesidades y
        su capacidad para pagarlo. El monto podrá aumentarse en cada renovación.
      </p>
    ),
  },
  {
    title: "¿Dónde se puede solicitar un crédito Mujeres Unidas?",
    body: (
      <div>
        Desde nuestra página web{" "}
        <a style={{ textDecoration: "none", color: "#257fa4" }} href="/">
          clic aquí{" "}
        </a>
        En nuestras sucursales en{" "}
        <a
          style={{ textDecoration: "none", color: "#257fa4" }}
          href="/canales"
          rel="noopener noreferrer"
        >
          los Olivos
        </a>{" "}
        y{" "}
        <a
          style={{ textDecoration: "none", color: "#257fa4" }}
          href="/canales"
          rel="noopener noreferrer"
        >
          San Juan de Lurigancho.
        </a>
      </div>
    ),
  },
  {
    title:
      "¿Toman en cuenta la calificación de mi cónyuge al momento de ingresar a su grupo?",
    body: (
      <p>
        No, solo se toma en cuenta la calificación de la persona que solicita el
        crédito.
      </p>
    ),
  },
  {
    title:
      '¿Cuándo se considera a una clienta como "Mal Calificada" para acceder a un crédito Grupal ?',
    body: (
      <div>
        Toda clienta que tenga una calificación diferente a “Normal” se
        considera como “Mal calificada” (“CPP”, “Deficiente”, “Dudoso”,
        “Pérdida”).
        <table>
          <tr>
            <th>Calificación</th>
            <th>Días de atraso</th>
          </tr>
          <tr>
            <td>Normal </td>
            <td>0 a 8 días</td>
          </tr>
          <tr>
            <td>CPP</td>
            <td>9 a 30 días </td>
          </tr>
          <tr>
            <td>Deficiente</td>
            <td>31 a 60 días</td>
          </tr>
          <tr>
            <td>Dudoso</td>
            <td>61 a 120 días</td>
          </tr>
          <tr>
            <td>Pérdida</td>
            <td>121 días a más </td>
          </tr>
        </table>
        De acuerdo con la Resolución SBS 11356, existen las siguientes
        categorías. *Días calendario
      </div>
    ),
  },
  {
    title:
      " ¿Se requiere contar con un aval para acceder a un crédito grupal ?",
    body: (
      <p> No, ya que todas las integrantes fungen como fiadores solidarios.</p>
    ),
  },
  {
    title: "¿Se permiten familiares dentro de un mismo grupo?",
    body: <p>Sí, se podrán permitir familiares.</p>,
  },
  {
    title:
      "¿Es obligatorio adquirir un seguro para acceder a un crédito grupal?",
    body: <p> No, la adquisición del seguro optativo es voluntaria.</p>,
  },
  {
    title: '¿Qué es ser "Deudor solidario" ?',
    body: (
      <p>
        {" "}
        Es aquella persona que se compromete solidariamente a responder por la
        totalidad de la deuda que el deudor principal contrae
      </p>
    ),
  },
  {
    title: "¿Qué es una reprogramación?",
    body: (
      <p>
        Es un beneficio para los clientes quienes tendrán la opción de modificar
        las fechas de pago de su crédito. Asimismo, dentro de dicho periodo, el
        cliente asumirá el interés que se ocasiona por el plazo brindado.
      </p>
    ),
  },
  {
    title: "¿Qué tipos de reprogramación existen?",
    body: (
      <div>
        <p>
          Existen la Reprogramación unilateral (individual o masiva) y la
          Reprogramación a solicitud del cliente.
        </p>

        <p>
          Reprogramación unilateral: Se realiza de manera masiva, automática y
          sin comunicación previa al cliente (se le informa posteriormente).Cabe
          resaltar que la SBS faculta a las empresas del sistema financiero a
          realizar este tipo de operaciones,
        </p>
        <p>
          Reprogramación masiva: La SBS en situaciones particulares puede dar
          instrucción para una reprogramación masiva como es en los casos de
          pandemia.
        </p>
        <p>
          Reprogramación a solicitud del cliente: Se realiza cuando el cliente
          la solicita, debido a dificultades temporales que pudiera afrontar
          para el pago de sus créditos. SURGIR tiene la responsabilidad de
          responder la aceptación o no de la operación solicitada en un plazo
          máximo de 07 días calendarios.
        </p>
        <p>
          Condiciones complementarias: Para los casos de reprogramación
          unilateral o masiva, tienes el derecho de rechazarlo y seguir pagando
          tu cronograma. En cualquiera las modalidades se te entregará del
          cronograma modificado mediante el medio de comunicación que has
          establecido en tu crédito original.
        </p>
      </div>
    ),
  },
  {
    title:
      "¿Si la empresa me hizo una reprogramación unilateral, puedo extornarla y volver a mi cronograma original?",
    body: (
      <p>
        Si, el cliente puede solicitar el extorno de la operación y volver a su
        cronograma original.
      </p>
    ),
  },
  {
    title:
      "¿Cuál es el impacto de una reprogramación en mi cronograma de pagos?",
    body: (
      <p>
        Dependiendo de los meses de gracia y el número de cuotas ampliadas, las
        cuotas podrían ser más bajas, iguales o más altas. Sin embargo, el
        interés total del cronograma de pagos se incrementa debido a la
        aplicación de la TEA = Tasa efectiva anual que se cobra durante el
        periodo de gracia ofrecido.
      </p>
    ),
  },
  {
    title: "¿Cuál es la tasa de interés que se cobra en una reprogramación?",
    body: (
      <p>
        La Tasa efectiva anual (TEA), la cual sigue siendo la misma que se pacta
        al momento del desembolso del crédito.
      </p>
    ),
  },
  {
    title: "¿Soy sujeto de crédito después de haber tenido una reprogramación?",
    body: (
      <p>
        {" "}
        Sí, los clientes después de una reprogramación pueden solicitar nuevos
        créditos.
      </p>
    ),
  },
  {
    title:
      " ¿Mi calificación crediticia se ve impactada después de una reprogramación?",
    body: (
      <p>
        {" "}
        No, lo que se busca con la reprogramación del crédito es que la
        operación no caiga en mora y no califique mal al cliente.
      </p>
    ),
  },
];

export const aboutInsurance = [
  {
    title: "¿Qué es el Seguro de Desgravamen?",
    body: (
      <div>
        <p>
          Es un seguro de vida en el que cubre el saldo insoluto de la deuda que
          mantienen con SURGIR en caso de que mueras antes de pagarlo completo,
          y mantiene el plazo mientras la deuda esté vigente.
        </p>

        <p>
          Es decir que cuando una persona pide un préstamo, se le agrega un
          monto extra al solicitado por el cliente. En caso el solicitante
          fallezca o se produzca invalidez total que obstaculice la
          consolidación de su deuda, no se heredará a su cónyuge garante o
          familiar.
        </p>

        <p>
          Normalmente, el desgravamen establece una relación entre una entidad
          financiera (banco, caja de ahorros, financiera, etc.) y una
          aseguradora; un cliente como tú que solicita algún producto que SURGIR
          ofrece. Este es el punto de inicio.
        </p>
      </div>
    ),
  },
  {
    title: "¿El seguro es obligatorio?",
    body: (
      <p>
        {" "}
        Es obligatorio para poder acceder a un crédito grupal o individual ya
        que garantiza tanto a SURGIR como al asegurado el cumplimiento de la
        deuda contratada.
      </p>
    ),
  },
  {
    title: "¿En que casos cubre el seguro Desgravamen?",
    body: (
      <p>
        Cubre en caso de fallecimiento (natural o como consecuencia de
        accidente) o invalidez total permanente por accidente. Es obligatorio y
        lo adquieres cuando solicitas tu crédito.
      </p>
    ),
  },
  {
    title: "¿Cómo contratar el seguro de Desgravamen?",
    body: <p>El seguro de desgravamen puedes contratar a través de un
    préstamo grupal o individual.</p>,
  },
  {
    title: " ¿Desde cuándo estoy asegurado?",
    body: <p> Estará asegurado desde el momento del desembolso del
    crédito.</p>,
  },
  {
    title: "¿Hasta cuándo estoy asegurado?",
    body: <div>
         <p>
                          Estará asegurado hasta el término de la vigencia del
                          crédito, o de la fecha en que el Asegurado cumpla la
                          edad límite de permanencia, lo que ocurra primero.
                        </p>
                        <p>
                          Además, es importante que te informes sobre las
                          condiciones de este seguro: coberturas, exclusiones o
                          responsabilidades del asegurado. Asimismo, debes saber
                          que las sumas máximas aseguradas y las tasas dependen
                          del producto y del monto del préstamo que hayas
                          adquirido.
                        </p>
    </div>,
  },
  {
    title: "¿La invalidez temporal está cubierta?",
    body: <p>No. El seguro cubre sólo la invalidez total y permanente
    por accidente o enfermedad.</p>,
  },
  {
    title: "¿En caso de fallecimiento?",
    body: <p>Revisar el proceso para solicitar tus coberturas.</p>,
  },
  {
    title: " ¿Cuántos días demora la respuesta de la aseguradora ante un siniestro?",
    body: <p>  La cía. Aseguradora tiene un plazo máximo de treinta
    (30) días calendario contados desde la recepción
    completa de los documentos indicados en el certificado
    de seguros para aprobar o rechazar la solicitud de
    cobertura.</p>,
  },
  {
    title: "¿Cómo es el proceso para la atención de consultas y reclamos?",
    body: <div>
                                El Contratante, Asegurados y los Beneficiarios podrán
                        presentar sus quejas y/o reclamos a la Aseguradora, los
                        mismos que deberán ser atendidos en un plazo máximo de
                        30 días contados desde la fecha de su recepción.
                        <br /> Podrán plantearse consultas y/o reclamos formales
                        de manera gratuita a través de los siguientes canales:
                        <br /> Consultas:
                        <br /> – Comunicación dirigida al presidente del Grupo
                        Mapfre indicando: tipo de solicitante (*), nombre
                        completo, número del documento de identidad, domicilio,
                        teléfono, correo electrónico, nombre del
                        producto/servicio afectado, una breve explicación clara
                        y precisa de la consulta y/o hecho reclamado y
                        documentos que adjunta.
                        <br /> – (*) Tipo de solicitante (Contratante,
                        ASEGURADO, Beneficiario, Corredor u otro: especificar)
                        <br /> – A través de la página Web de MAPFRE Perú,
                        enlazándose al link “Atención de Consultas y/o
                        Reclamos”.
                        <br /> – Oficina Principal de LA COMPAÑÍA: Av. 28 de
                        Julio 873, Miraflores, Lima 18, Perú, teléfono 213-3333
                        (En Lima) y 0801-1-1133 (en Provincia).
                        <br /> Página Web: www.mapfre.com.pe
                        <br /> Reclamos y/o Quejas:
                        <br /> – Comunicación dirigida al presidente del Grupo
                        Mapfre indicando: tipo de solicitante (*), nombre
                        completo, número de documento de identidad, domicilio,
                        teléfono, correo electrónico, nombre del
                        Producto/servicio afectado, una breve explicación clara
                        y precisa del hecho reclamado y documentos que adjunta.
                        <br /> – “Libro de Reclamaciones Virtual” en cualquiera
                        de las oficinas a nivel nacional de LA COMPAÑÍA. Podrá
                        adjuntar de ser el caso documentos que sustentan su
                        reclamo y/o queja.
                        <br /> – A través de la página Web de MAPFRE Perú
                        http://www.mapfreperu.com enlazándose al link de “Libro
                        de Reclamaciones” (Reclamos y/o Queja)
                        <br /> Los reclamos deben efectuarse dentro de los
                        plazos de prescripción establecidos por Ley. En caso el
                        usuario no se encuentre conforme con los fundamentos de
                        la respuesta, puede acudir a la Defensoría del
                        ASEGURADO, ubicada en Calle Amador Merino Reyna 307 Piso
                        9 San Isidro y/o al INDECOPI, ubicada Calle de la Prosa
                        104, San Borja. Para cualquier consulta respecto a su
                        reclamo, puede comunicarse con la Central de Atención al
                        Cliente al teléfono 213-3333 (en Lima) y 0801-1-1133 (en
                        Provincia) o dirigirse a las plataformas de Atención al
                        Cliente de las oficinas de LA COMPAÑÍA ubicadas en Lima
                        y Provincias. La relación completa de oficinas de LA
                        COMPAÑÍA se encuentra disponible en la Página Web
                        (www.mapfre.com.pe).
    </div>,
  }
];
