import { StringGradients } from "antd/es/progress/progress";
import { PATTER_ALPH } from "../../utils/constants";

const messageP1 = "Pendiente completar este campo.";
const alertName = "Debes ingresar tus nombres.";
const alertLastName = "Debes ingresar tus apellidos.";
const alertTypeDocument = "Debes seleccionar un tipo de documento.";
const alertDocument = "Debes ingresar tu número de documento.";
const alertPhone = "Debes ingresar tu número de celular.";
const alertMont = "Debes ingresar un monto de apertura";
const alertEmail = "Debes ingresar tu correo electrónico.";
const alertDepartment = "Debes seleccionar un departamento.";
const alertProvince = "Debes seleccionar una provincia.";
const alertDistrict = "Debes seleccionar un distrito.";
const message = "Pendiente completar este campo.";
const messageObject = "Pendiente seleccionar una opción";
const emptyTypeDoc = "Elije primero un tipo de documento";
const messageDNI = "El número debe contener 8 dígitos.";
const messageEmail = "Ingresa un correo válido: “ejemplo@correo.com”.";
const messageText = "Solo letras (y signos que van con letras como tilde, diéresis, ñ, etc.)";
const messagePhone = "Debe ser menor o igual a 13 dígitos";
const messageNumber = "Solo números";
const messageDecimal = "Formato ejemplo: 150,350,000.00 (decimales separados por punto “.” y grupos de 3 cifras separadas por coma “,”)";
let valueType = "";

interface ValidationResult {
  error: boolean;
  message: string;
}

interface Request {
  firstName: string,
  secondName: string,
  lastName: string,
  otherLastName: string,
  typeDOI: { code: string, name: string },
  numberDOI: string,
  mobilePhone: string,
  email: string,
  import: number,
  procesingPersonalData: boolean
}

export const isEmptyString = (value: any): boolean => {
  return value == undefined || value == null || value === "" || value === "";
};

export const isEmptyObject = (value: any): boolean => {
  return value == null || value == undefined || Object.keys(value).length === 0;
};

export const validateFieldName = (value: any): ValidationResult => {
  return {
    error: isEmptyString(value),
    message: alertName,
  };
};
export const validateFieldLastName = (value: any): ValidationResult => {
  return {
    error: isEmptyString(value),
    message: alertLastName,
  };
};
export const validateFieldPhone = (value: any): ValidationResult => {
  return {
    error: isEmptyString(value),
    message: alertPhone,
  };
};
export const validateFieldMont = (value: any): ValidationResult => {
  return {
    error: isEmptyString(value),
    message: alertMont,
  };
};
//validaciones para dropdown

export const validateFieldTypeDocument = (value: any): ValidationResult => {
  return {
    error: isEmptyObject(value),
    message: alertTypeDocument,
  };
};
export const validateFieldDepartment = (value: any): ValidationResult => {
  return {
    error: isEmptyObject(value),
    message: alertDepartment,
  };
};
export const validateFieldProvince = (value: any): ValidationResult => {
  return {
    error: isEmptyObject(value),
    message: alertProvince,
  };
};
export const validateFieldDistrict = (value: any): ValidationResult => {
  return {
    error: isEmptyObject(value),
    message: alertDistrict,
  };
};

export const validateDocumentNumber = (value: string, typeDocument: string | undefined): ValidationResult => {
  if (typeDocument === null || typeDocument === undefined || typeDocument === "") {
    return {
      error: true,
      message: emptyTypeDoc,
    };
  }

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    if (typeDocument === '01') {
      return {
        error: value.length !== 8,
        message: messageDNI,
      };
    } else {
      return {
        error: false,
        message: '',
      };
    }
  }
};

export const validateEmail = (value: string): ValidationResult => {
  const regex = /^[0-9a-zA-Z_.]{0,50}@[a-zA-Z]{1,20}\.[a-zA-Z]{1,3}[a-zA-Z.]{0,5}$/;

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: alertEmail,
    };
  } else {
    return {
      error: !regex.test(value),
      message: messageEmail,
    };
  }
};

export const completeForm = (request: Request): boolean => {

  if (isEmptyString(request.lastName)) {
    return false;
  }
 
  if (isEmptyString(request.firstName)) {
    return false;
  }
  if (isEmptyString(request.email)) {
    return false;
  }
  if (isEmptyString(request.mobilePhone)) {
    return false;
  }
  if (isEmptyString(request.import)) {
    return false;
  }
  if (isEmptyString(request.otherLastName)) {
    return false;
  }
  if (isEmptyString(request.numberDOI)) {
    return false;
  }
  if (request.typeDOI == null || isEmptyString(request.typeDOI.code) || isEmptyString(request.typeDOI.name)) {
    return false;
  }
  return true;
};

export const validateForm = (request: Request): { complete: boolean } => {
  /* completar campos  */
  const complete = completeForm(request);

  return {
    complete: complete,
  };
};


export const validateText = (value: string): ValidationResult => {
  const regex = PATTER_ALPH;

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    return {
      error: !regex.test(value),
      message: messageText,
    };
  }
};

export const validatePhone = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d*$/);

  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    if (!regex.test(value)) {
      return {
        error: !regex.test(value),
        message: messageNumber,
      };
    } else {
      return {
        error: value.toString().split("").length > 13 ? true : false,
        message: messagePhone,
      };
    }
  }
};

export const validatePhoneOptional = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d*$/);

  if (!regex.test(value)) {
    return {
      error: !regex.test(value),
      message: messageNumber,
    };
  } else {
    return {
      error: value.toString().split("").length > 13 ? true : false,
      message: messagePhone,
    };
  }
};

export const validateDecimal = (value: string): ValidationResult => {
  const regex = new RegExp(/^\d{1,3}(?:\d{1,3})?(?:\d{1,3})?(?:\.\d{1,2})?$/);
  if (value) {
    return {
      error: !regex.test(value),
      message: messageDecimal,
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export const validateRequired = (value: string): ValidationResult => {
  if (isEmptyString(value)) {
    return {
      error: isEmptyString(value),
      message: messageP1,
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export const patternTypeDocument = (typeDocument: { name: string } | undefined): RegExp => {
  if (typeDocument?.name === "DNI") {
    return /^(\d{0,8})$/g;
  }

  if (typeDocument?.name === "Pasaporte") {
    return /^([a-zA-Z]|\d){0,12}$/g;
  }

  if (typeDocument?.name === "Carné de Extranjería" || typeDocument?.name === "CEX" || typeDocument?.name === "Carné de Permiso Temporal de Permanencia" || typeDocument?.name === "PTP") {
    return /^(\d){0,12}$/g;
  }

  return /^.*$/g; // Cambia esto según la lógica específica
};
