import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import './About.scss';


import LittleTree from "./../../assets/images/about/picto-planta.svg"
import Briefcase from "./../../assets/images/about/picto-trabajo.svg";
import Lightbulb from "./../../assets/images/about/picto-bulb.svg";
import Handshake from "./../../assets/images/about/picto-team.svg";
import Contract from "./../../assets/images/about/picto-certificado.svg";
import Resilence from "./../../assets/images/about/picto-muscle.svg";
import Chat from "./../../assets/images/about/picto-conversación.svg";
import Ear from "./../../assets/images/about/picto-oreja.svg";
import Respect from "./../../assets/images/about/picto-manos.svg";
import Respaldo from "./../../assets/images/empresa_respaldo.webp";
import { ReactComponent as DownloadIcon } from './../../assets/images/Icons_descargar.svg'
import { useLocation } from "react-router-dom";
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import { createLink } from "utils/urls";

interface ItemTabProps {
  list: string[];
  last: boolean;
}

const ItemTab: React.FC<ItemTabProps> = ({ list, last }) => {
  return (
    <ul style={{ borderBottomStyle: 'solid', borderBottomColor: '#cccccc', paddingTop: 16, paddingBottom: 5, paddingLeft: 20, borderBottomWidth: last ? 0 : 2 }}>
      {list.map((item) => (
        <li key={item} className={'list-tab-item'} style={{ listStyleType: 'initial', listStylePosition: 'outside' }}>
          {item}
        </li>
      ))}
    </ul>
  );
};

interface ItemImageProps {
  image: string;
  title: React.ReactNode;
}

const ItemImage: React.FC<ItemImageProps> = ({ image, title }) => {
  return (
    <div style={{ margin: "30px 30px 30px 40px" }}>
      <div
        style={{
          backgroundColor: "#eff6f9",
          display: "flex",
          width: "244px",
          height: "98px",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "205px" }}>
          <img src={image} alt="itemImage" />
        </div>
        {title}
      </div>
    </div>
  );
};

const TABS_CORPORATE_GOVERNANCE = [
  {
    title: 'Directorio',
    body: (
      <Row style={{ padding: 40 }}>
        <Col md={3}>
          <p className="Columna">Directorio</p>
        </Col>
        <Col md={9}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Angel Vares Torres</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Angel_Vares_Torres"
                  onClick={() => createLink(process.env.REACT_APP_DIRECTORIO_MIEMBRO_1 ?? "", "_blank")}
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Diana Elizabeth Pastor Horna</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Diana_Elizabeth_Pastor_Horna"
                  onClick={() => createLink(process.env.REACT_APP_DIRECTORIO_MIEMBRO_2 ?? "", "_blank")}
                >
                  <DownloadIcon /> Descargar
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Félix Javier Arroyo Rizo Patrón</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Félix_Javier_Arroyo_Rizo_Patrón"
                  onClick={() => createLink(process.env.REACT_APP_DIRECTORIO_MIEMBRO_3 ?? "", "_blank")}
                >
                  <DownloadIcon /> Descargar
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Juan Pedro Oechsle Bernos</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Juan_Pedro_Oechsle_Bernos"
                  onClick={() => createLink(process.env.REACT_APP_DIRECTORIO_MIEMBRO_4 ?? "", "_blank")}
                >
                  <DownloadIcon /> Descargar
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>María Leonie Roca Voto Bernales</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="María_Leonie_Roca_Voto_Bernales"
                  onClick={() => createLink(process.env.REACT_APP_DIRECTORIO_MIEMBRO_5 ?? "", "_blank")}
                >
                  <DownloadIcon /> Descargar
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  },
  {
    title: 'Memoria Anual',
    body: (
      <Row style={{ padding: 40 }}>
        <Col md={3}>
          <p className="Columna">Memoria Anual</p>
        </Col>
        <Col md={9}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Memoria Anual 2022</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Memoria_Anual_2022"
                  onClick={() => createLink(process.env.REACT_APP_MEMORIA_ANUAL_2022 ?? "", "_blank")}
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />
        </Col>
      </Row>
    )
  },
  {
    title: 'Documentos Importantes',
    body: (
      <Row style={{ padding: 40 }}>
        <Col md={3}>
          <p className="Columna">Documentos Importantes</p>
        </Col>
        <Col md={9}>
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Certificado de autorización de funcionamiento</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download="Certificado_de_autorización_de_funcionamiento"
                  onClick={() => createLink(process.env.REACT_APP_DOCS_CERTIFICADO_FUNCIONAMIENTO ?? "", "_blank")}
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
          <br />
          <Card body style={{ paddingLeft: 25 }}>
            {" "}
            <Row>
              <Col md={9}>Código de Buenas Prácticas y Autoevaluación</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  download="Código_de_Buenas_Prácticas_y_Autoevaluación"
                  onClick={() => createLink(process.env.REACT_APP_DOCS_CODIGO_BUENAS_PRACTICAS ?? "", "_blank")}
                >
                  {" "}
                  <DownloadIcon /> Descargar{" "}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
]

const AboutComponent: React.FC = () => {
  return (
    <div style={{ paddingTop: 55 }}>
      <div className="banner-quienes-somos" >
        <span className="text-banner-quienes-somos">
          Apasionados por acelerar tu progreso
        </span>
      </div>
      <Container>
        <div className="container">
          <br /><br />
          <span className="Ptitle">
            Quiénes somos
          </span>
          <Row>
            <Col md={6}>
              <span className="parrafo" style={{ textAlign: "left" }}>
                Somos una empresa apasionada por ofrecer oportunidades de progreso a emprendedores como tú,
                a través de nuestros productos de préstamos y seguros, te brindaremos soluciones financieras,
                así como asesoría cercana para acelerar tu progreso, el de tu familia y tu negocio.
              </span>
              <br />
              <br />
              <span>
                Porque sabemos:
              </span>
              <ItemTab key={1} list={[
                `Que ser emprendedor es enfrentar la vida con valentía y
                   optimismo.`,
                'Todo lo que te ha costado llegar hasta aquí.',
                'Del esfuerzo de tu día a día.',

              ]}
                last={true}
              />

              <span className="parrafo" >
                Es por eso que queremos ayudarte a SURGIR y acompañarte sin
                descanso en tu camino al éxito, porque ahora es el momento:
              </span>
              <ItemTab key={2} list={[

                'De progresar',
                'De alcanzar tus sueños',
                'De ¡SURGIR!',

              ]}
                last={true}
              />
            </Col>

            <Col md={6}>
              <img
                style={{
                  width: "100%",
                  position: "relative",
                  objectFit: "cover"
                }}
                className="w-100 h-80"
                src="img_quienessomos.webp"
                alt="First slide"
              />
            </Col>
          </Row>
        </div>

        <div className="container" style={{ marginBottom: "120px" }}>
          <Row>
            <Col md={6}>
              <span className="Ptitle">
                Nuestro gran respaldo
              </span>
              <p>
                <span className="parrafo">
                  Formamos parte del Grupo Santander, llevamos más de 160 años reinventándonos para crecer,
                  estamos alrededor del mundo ayudando a millones de personas a progresar.
                </span>
              </p>

              <p>
                <span className="parrafo">
                  Lo que nos ha llevado a ser el sexto banco en <a href="https://interbrand.com/best-brands-review-1/"> el
                    ranking 2020 Best Global Brands de Interbrand. </a>
                </span>
              </p>

              <p>
                <span className="parrafo">
                  Actualmente, contamos con cuotas de negocio elevadas en 10 mercados principales como son España,
                  Santander Consumer Finance (13 países europeos y 3 en américa), Polonia, Portugal, Reino Unido,
                  Brasil, México, Estados Unidos, Brasil, Chile y Argentina. Además estamos presentes en Perú,
                  Uruguay y Colombia.
                </span>
              </p>
              <p>
                <span className="parrafo" >
                  Algunas de las Empresas que forman parte de este gran grupo
                </span>
              </p>
            </Col>
            <Col md={6}>
              <img
                alt=''
                className="d-block w-100"
                style={{ marginTop: "100px" }}
                src={Respaldo}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <div className="banner-medio">
        <div className="box_text_banner">
          <span className="text_banner_medio">
            Apasionados por <b>acelerar el progreso</b> de los que hacen <b>SURGIR</b> al país.
          </span>
        </div>
      </div>

      <Container>
        <div className="container">
          <span className="Ptitle" >
            ¿Qué queremos lograr?
          </span>

          <Row>
            <Col md={6}>

              <span className="logros">
                Ser la entidad más valorada por los emprendedores de Perú y nuestros colaboradores,
                líderes en eficiencia y rentabilidad, desafiando el status quo del mercado.
              </span>

              <ItemTab key={3} list={[
                `Ser la empresa peruana que alienta e impulsa a SURGIR a los emprendedores
                que sostienen y dinamizan la economía en el Perú.`,
                'Ser la empresa peruana que crece siempre junto a ti, construyendo juntos tu historia de desarrollo y superación.',
                'Ser la empresa peruana en brindarte la mejor experiencia: Sencilla, personal, justa, ágil y oportuna.',
                'Ser el equipo de trabajo de preferencia en el Perú, que inspira, potencia y desarrolla a los mejores profesionales.',
                'Ser referentes en gestión empresarial potenciando el liderazgo transformador de la industria financiera en el Perú.'

              ]}
                last={true}
              />
            </Col>
            <Col md={6}>

              <img
                alt=''
                style={{}}
                className="d-block w-100"
                src="ganarnos_confianza-624x424.webp"
              />
            </Col>


          </Row>
        </div>
        <div className="container" >
          <span className="Ptitle">
            Valores
          </span>
          <div >
            <span className="parrafo">
              El equipo de SURGIR trabaja cada día para contribuir al progreso de forma Sencilla, Personal y Justa,
              lo que nos ayuda a merecer y mantener la confianza y fidelidad de los clientes. Es la forma de pensar
              y trabajar de todos los profesionales de Santander y representa lo que nuestros clientes esperan de
              nosotros como banco.
            </span>
          </div>

          <Row style={{ paddingTop: "32px" }}>
            <Col md={4} style={{ paddingBottom: "24px" }}>
              <span className="valores">
                Sencillo
              </span>
              <hr />
              <span className="parrafo">
                Ofrecemos a los clientes un servicio accesible, con productos sencillos y fáciles de entender.
                Usamos un lenguaje sencillo y mejoramos nuestros procesos todos los días.
              </span>
            </Col>

            <Col md={4} style={{ paddingBottom: "24px" }}>
              <span className="valores">
                Personal
              </span>
              <hr />
              <span className="parrafo">
                Tratamos a nuestros clientes de forma personalizada, ofreciéndoles los productos y
                servicios que mejor se adaptan a sus necesidades.
                <br />
                Queremos que todos y cada uno de nuestros
                empleados y clientes se sientan valorados y tratados de forma única.
              </span>
            </Col>

            <Col md={4}>
              <span className="valores">
                Justo
              </span>
              <hr />
              <span className="parrafo">
                Tratamos a nuestros empleados y clientes de forma justa y equitativa, somos transparentes y
                cumplimos nuestras promesas.
                <br />
                Entablamos buenas relaciones con los diversos grupos de interés
                porque entendemos que lo que es bueno para ellos lo es también para SURGIR.
              </span>
            </Col>
          </Row>

        </div>
        <div className="container">
          <span className="Ptitle">
            Principios culturales
          </span>
          <span className="parrafo">
            Nuestra cultura corporativa engloba ocho comportamientos.
          </span>

          <div style={{ paddingLeft: "8%" }}>
            <Row  >
              <Col md={4} style={{ margin: "0 10 0 10" }}>
                <ItemImage
                  image={LittleTree}
                  title={
                    <span className="text-rectangle">
                      Sentido de
                      <br />
                      trascendencia
                    </span>
                  }

                />
              </Col>

              <Col md={4}>
                <ItemImage

                  image={Briefcase}

                  title={
                    <span className="text-rectangle">
                      Trabajo
                      <br />
                      con pasión
                    </span>

                  }
                />
              </Col>

              <Col md={4}>
                <ItemImage
                  image={Lightbulb}
                  title={
                    <span className="text-rectangle">
                      Apetito disruptivo
                      <br />
                      <span className="subtext-rectangle">
                        Sin miedo al error
                      </span>
                    </span>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <ItemImage
                  image={Handshake}
                  title={
                    <span className="text-rectangle">
                      Promuevo la
                      <br />
                      colaboración
                    </span>
                  }
                />
              </Col>

              <Col md={4}>
                <ItemImage
                  image={Contract}
                  title={
                    <span className="text-rectangle">
                      Cumplo promesas
                    </span>
                  }
                />
              </Col>

              <Col md={4}>
                <ItemImage
                  image={Resilence}
                  title={
                    <span className="text-rectangle">
                      Resiliencia
                      <br />
                      <span className="subtext-rectangle">
                        Cero excusas
                      </span>
                    </span>
                  }
                />
              </Col>
            </Row>

            <Row >
              <Col md={4}>
                <ItemImage
                  image={Chat}
                  title={
                    <span className="text-rectangle">
                      Hablamos claro
                    </span>
                  }
                />
              </Col>

              <Col md={4}>
                <ItemImage
                  image={Ear}
                  title={
                    <span className="text-rectangle">
                      Escucho de verdad
                    </span>
                  }
                />
              </Col>

              <Col md={4}>
                <ItemImage
                  image={Respect}
                  title={
                    <span className="text-rectangle">
                      Soy respetuoso
                    </span>
                  }
                />
              </Col>
            </Row>
          </div >
          <div style={{ marginBottom: "80px" }}>
            <span className="parrafo"  >
              Y una sólida cultura de riesgos donde todos asumimos la responsabilidad de gestionar los riesgos de nuestro trabajo diario.
            </span>
          </div>

        </div>

        <div className="container" id="gobierno-corporativo">
          <span className="Ptitle">
            Gobierno Corporativo
          </span>

          <SurgirTabs tabs={TABS_CORPORATE_GOVERNANCE} />
        </div>

      </Container>
    </div>
  );
};

export default AboutComponent;