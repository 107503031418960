import React from 'react';
import {
  Button,
} from "react-bootstrap";
import "./style.scss";
import { Tooltip } from 'antd';
import { ReactComponent as CalendarIcon } from '../../../../../../src/assets/images/Icons - Channels - ic_calendar.svg'

interface ResultadosSimulacionProps {
	product: any;
	onChangeVisibilityCronograma: any;
  creditSummary: any
}

const ResultadosSimulacion: React.FC<ResultadosSimulacionProps> = ({
   product, onChangeVisibilityCronograma, creditSummary
}) => {

  const dataMock = [
    {
      montoCredito: creditSummary.formCredit.requiredAmount,
      cuotas: creditSummary.formCredit.numberQuota,
      plazoVencimiento: creditSummary.formCredit.firstPaymentDate,
      tasaInteres: creditSummary.formCredit.interestPeriodPorcentage,
      tcea: creditSummary.creditSummary.tcea,
      totalCapital: creditSummary.formCredit.requiredAmount,
      totalIntereses: creditSummary.creditSummary.totalInterest,
      totalSeguros: creditSummary.creditSummary.totalInsurance,
      montoTotalCuotas: creditSummary.creditSummary.totalQuota,
      cuotaMensual: creditSummary.creditSummary.averageQuota,
    },
    
  ];
  return (
    <div className="container__ResultadosSimulacion">
      <div >
        <center> <span className="simulator-title" >
          Resultados
        </span>
          <br />

          <span className="parrafo">
            Conoce los resultados referenciales de tu simulación de crédito.
          </span>
        </center>
      </div>
      <br />
      <table className="results-table__ResultadosSimulacion" >
        {dataMock.map((row, index) => (
          <tbody key={product} >
            <tr key={product} className="blue-row" >
              <td><span className="results-table-title">Producto:</span></td>
              <td className="results-table-title">{product}</td>
            </tr>
            <tr key={row.montoCredito} className="white-row">
              <td><span className="results-table-title">Monto del crédito:</span></td>
              <td>{row.montoCredito}</td>
            </tr>
            <tr key={row.cuotas} className="light-blue-row">
              <td><span className="results-table-title">Cantidad de cuotas:</span></td>
              <td>{row.cuotas}</td>
            </tr>
            <tr key={row.plazoVencimiento} className="white-row" >
              <td><span className="results-table-title">Primer plazo de vencimiento:</span></td>
              <td>{row.plazoVencimiento}</td>
            </tr>
            <tr key={row.tasaInteres} className={index % 2 === 0 ? 'light-blue-row' : 'white-row'}>
              <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}><div className="results-table-title">Tasa de interés:</div>
                <Tooltip title="Expresada anualmente, calculando solo los intereses del crédito." trigger={'hover'}>
                  <span style={{ width: 16, height: 16, color: 'white', backgroundColor: '#EBB138', display: 'flex', justifyContent: 'center', fontSizeAdjust: 'initial', borderRadius: 100, fontSize: 12, flexDirection: 'row', boxSizing: 'border-box', lineHeight: '90%' }}>¡
                  </span>
                </Tooltip>
              </td>
              <td>{row.tasaInteres}</td>
            </tr>
            <tr key={row.tcea} className="white-row">
              <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}><span className="results-table-title">TCEA:</span>
              <Tooltip title=" Tasa de costo efectivo anual. Implica el costo total de tu crédito: interés + comisiones + gastos" trigger={'hover'}>
                  <span style={{ width: 16, height: 16, color: 'white', backgroundColor: '#EBB138', display: 'flex', justifyContent: 'center', fontSizeAdjust: 'initial', borderRadius: 100, fontSize: 12, flexDirection: 'row', boxSizing: 'border-box', lineHeight: '90%' }}>¡
                  </span>
                </Tooltip>
              </td>
              <td>{row.tcea}</td>
            </tr>
            <tr key={row.totalCapital} className={index % 2 === 0 ? 'light-blue-row' : 'white-row'}>
              <td><span className="results-table-title">Total Capital:</span></td>
              <td>{row.totalCapital}</td>
            </tr>
            <tr key={row.totalIntereses} className="white-row">
              <td><span className="results-table-title">Total Intereses:</span></td>
              <td>{row.totalIntereses}</td>
            </tr>
            <tr key={row.totalSeguros} className={index % 2 === 0 ? 'light-blue-row' : 'white-row'}>
              <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}><span className="results-table-title">Total Seguros:</span>
              <Tooltip title="Seguro Desgravamen: S/ 7.00 + Súper Seguro: Plan 1 S/ 10.00 + Seguro Multirriesgo: S/ 4.50" trigger={'hover'}>
                  <span style={{ width: 16, height: 16, color: 'white', backgroundColor: '#EBB138', display: 'flex', justifyContent: 'center', fontSizeAdjust: 'initial', borderRadius: 100, fontSize: 12, flexDirection: 'row', boxSizing: 'border-box', lineHeight: '90%' }}>¡
                  </span>
                </Tooltip>
              </td>
              <td>{row.totalSeguros}</td>
            </tr>
            <tr key={row.montoTotalCuotas} className="white-row">
              <td><span className="results-table-title">Monto total cuotas:</span></td>
              <td>{row.montoTotalCuotas}</td>
            </tr>
            <tr key={row.cuotaMensual} className="results-turquoise-row ">
              <td><span className="results-table-title-white">Cuota mensual:</span></td>
              <td>{row.cuotaMensual}</td>
            </tr>
          </tbody>
        ))}
      </table>
      <center>
        <Button style={{ borderRadius: 32, fontFamily: "Str-Microtext-Bold", fontSize: '16', width: 180, height: 40, textAlign: "center", marginTop: "32px", marginBottom: "40px" }} variant="danger"
          onClick={(e) => { onChangeVisibilityCronograma(true) }} > <CalendarIcon /> Ver cronograma</Button>
        <br />


        <a
          className="home-link-rojo"
          style={{
            fontFamily: "Str-Microtext-Regular",
            color: "#EC0000",
          }}

          href="/simulador"
        >
          Volver a simulador de crédito
        </a>
        <br />
        <br />
      </center>
    </div>
  );
};


export default ResultadosSimulacion;