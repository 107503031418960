import React, {  useEffect, useState } from "react";
import { Checkbox, FormGroup } from "@mui/material";
import './SurgirCheckOptions.scss'

interface SurgirCheckOptionsProps {
    checkData: any;
    value : any;
    visibleValidation: any;
    allOptionsCheck: any;
    getValues: any;
}

const SurgirCheckOptions : React.FC<SurgirCheckOptionsProps> = ({ 
  checkData,
  value,
  visibleValidation,
  allOptionsCheck,
  getValues
}) => {

    const [dataCheck, setDataCheck] = useState([] as any);

    useEffect(() => {
        mapDataCheck();
    },[]);

    const mapDataCheck = () => {
        setDataCheck(checkData.map((res: any, index: any) => { return { ...res, value: value[index] } }));
    }

    const changeCheck = (e: any, i: any) => {
        dataCheck[i].value = e.target.checked;
        setDataCheck((current: any) => 
            current.map((obj: any) => {
                return obj
        }))
        allcheck(dataCheck);
        getValues(dataCheck);
    }

    const allcheck = (data: any) =>{
       const resp = data.every((x: any) => x.value);
       allOptionsCheck(resp);
    }

    return (
        <FormGroup style={{ gap: '16px'}} className="formgroup-check-surgir">
            {dataCheck.map((check: any, i: any) =>
                <div className="container-check-options" key={check.id}>
                    <div className="col-content-check">
                        <Checkbox  sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24, transform: 'scale(1.3)' },
                            '&.Mui-checked': {
                                color: '#EC0000'
                            }
                        }} id={check.id} onChange={(e) => changeCheck(e, i)} checked={check.value} />
                        <div className="text-check">{check.text}
                            {(check.textUrl && check.directUrl) && (<a href={check.directUrl} target="_blank" rel="noreferrer">{check.textUrl}</a>)} </div>
                    </div>

                    {!check.value && check.textWarning && visibleValidation && (
                        <div className="text-check-warning">{check.textWarning}</div>
                    )}
                </div>

            )}
        </FormGroup>
    );
};

export default SurgirCheckOptions