import React from 'react';
import { SurgirTooltipProps } from './SurgirTooltipProps';
import styled from 'styled-components';

const ContainerTooltip = styled.div`
display: none;
padding: 16px;
background: #FFFFFF;
border-radius: 8px;
width: 100%;
max-width: 288px;
filter: drop-shadow(0px 4px 8px rgba(29, 37, 45, 0.12));
position: absolute;
right: -6px;
top: -150px;
z-index: 1;

.titleTT{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #EC0000;
  margin-bottom: 8px;
}

.separator{
  width: 100%;
  height: 1px;
  background: #EC0000;
  margin-bottom: 8px;
}

.description{
  color: #444444;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Str-Microtext-Regular", sans-serif;
}
&::after {
  content: "";
  position: absolute;
  right: 8%;
  bottom: -23px;    
  margin-top: -10px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent #FFFFFF transparent transparent;
  width: 24px;
  height: 12px;
  transform: rotate(270deg);
}


@media (min-width: 1200px){
  &{
      max-width: 252px;
      position: absolute;
      right: -290px;
      top: -45px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -10px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent #FFFFFF transparent transparent;
    width: 24px;
    height: 24px;
    transform: initial;
  }
}

&.visible {
  display: block;
}
`;

const SurgirTooltip: React.FC<SurgirTooltipProps> = ({ title, description, children }) => {
  const handleMouseover = () => {
    const tooltip = document.getElementById('tool');
    if (tooltip) {
      tooltip.classList.add('visible');
    }
  };

  const handleMouseout = () => { 
    const tooltip = document.getElementById('tool');
    if (tooltip) {
      tooltip.classList.remove('visible');
    }
  };

  const handleFocus = () => { 
    handleMouseover()
  };

  const handleBlur = () => { 
    handleMouseout()
  };

  return (
    <>
      <button
        onMouseOver={handleMouseover}
        onMouseOut={handleMouseout}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ cursor: 'pointer', border: 'none', background: 'none', padding: 0 }}
      >
        {children}
      </button>

      <ContainerTooltip id="tool" role="tooltip" aria-label={title}>
        <div className="titleTT">{title}</div>
        <div className="separator"></div>
        <div className="description">{description}</div>
      </ContainerTooltip>
    </>
  );
};

export { SurgirTooltip };
