import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StylesSurgirTextAreaField = styled(TextField)({
    '& label': {
        fontFamily: "Str-Microtext-Regular, sans-serif",
        '&.Mui-focused': {
            color: '#257FA4' ,
        }
    },
    '& .MuiFilledInput-root': {
        background: 'rgba(255,255,255,0)',
        fontFamily: 'Str-Microtext-Regular,sans-serif',
        '&:hover': {
            background: 'rgba(255,255,255,0)',
            '&:after': {
            },
            '&:before': {
                background: 'rgba(255,255,255,0)',
                borderBottom: '1px solid #257FA4'
            },
            '&:not(.Mui-disabled):before':{
                borderBottom:'1px solid #257FA4'
            }
        },
        '&.Mui-focused': {
            background: 'rgba(255,255,255,0)',
            borderBottom: '1px solid #257FA4'
        },
        '&:before': {
            border: '1px solid #CEDEE7',
            lineHeight: 9,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            background: 'rgba(255,255,255,0)',

        },
        '&:after': {
            borderBottomColor: '#257fa4'
        },
    },

});

export { StylesSurgirTextAreaField }