import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Button,
} from "react-bootstrap";
import { ReactComponent as DownloadIcon } from '../../../../../../src/assets/images/Icons_descargar.svg'
import ConditionalLinkResultado from './../../components/ConditionalLinkResultados';
import "./style.scss";
import SurgirCardProduct from "../../../../../components/ui/SurgirCardProduct/SurgirCardProduct";
import ResultadosSimulacion from "./../ResultadosSimulacion/ResultadosSimulacion";


const Cronograma = ({ product, paymentPlan, urlDownload , handleShowResults}) => {
  const navigate = useNavigate();
  const handleGoToContactanos = () => {
    navigate.push('/contactanos', { FormularioSimulator: true });
  };
  
  const dataMock = paymentPlan.sort((item1, item2) => (item1.quotaNumber > item2.quotaNumber) ? 1 : (item1.quotaNumber < item2.quotaNumber) ? -1 : 0).map(item => {
    return {
      fechaInicio: item.startDate,
      fechaPago: item.paymentDate,
      diasCuota: item.quotaDays,
      saldoCapital: item.capitalBalance,
      interes: item.interest,
      cuotaPreliminar: item.preliminaryQuota,
      seguroDesgravamen: item.desgravamenInsurance,
      superSeguro: item.superInsurance,
      seguroMultiriesgo: item.multiRiskQuota
    }
  });
  return (
    <div>

      <div className="container__Cronograma">
        <div className="title-header__Cronograma">
          <center>
            <span className="cronograma-title" >
              Cronograma de pagos simulados
            </span>
          </center>
        </div>

        <div className="table__Cronograma" >
          <table className="simulator-table">
            <thead style={{ height: 100 }}>
              <tr className="turquoise-row subtitle_schedule">
                <th className="turquoise-row subtitle_schedule" style={{ position: 'absolute', zIndex: 2, width: 60, height: 100, display: 'flex', alignItems: 'center' }}>Cuota</th>
                <th style={{ width: 60 }}>Cuota</th>
                <th>Fecha Inicio</th>
                <th>Fecha Pago</th>
                <th>Días Cuota</th>
                <th>Saldo Capital</th>
                <th>Pago Capital</th>
                <th>Interés</th>
                <th>Cuota Preliminar</th>
                <th>Seguro Desgravamen</th>
                <th>Super Seguro</th>
                <th>Seguro Multiriesgo</th>
              </tr>
            </thead>
            <tbody className="parrafo">
              {dataMock.map((row, index) => (
                <tr key={index} className={index % 2 === 0 ? 'light-blue-row' : 'white-row'}>
                  <td className={index % 2 === 0 ? 'light-blue-row' : 'white-row'} style={{ position: 'absolute', zIndex: 3, width: 60, textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ width: 60 }}>{index + 1}</td>
                  <td>{row.fechaInicio}</td>
                  <td>{row.fechaPago}</td>
                  <td>{row.diasCuota}</td>
                  <td>{row.saldoCapital}</td>
                  <td>{row.seguroMultiriesgo}</td>
                  <td>{row.interes}</td>
                  <td>{row.cuotaPreliminar}</td>
                  <td>{row.seguroDesgravamen}</td>
                  <td>{row.superSeguro}</td>
                  <td>{row.seguroMultiriesgo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <center>
          <Button style={{ borderRadius: 32, fontFamily: "Str-Microtext-Bold", fontSize: '16', width: 180, height: 40, textAlign: "center", marginTop: "32px", marginBottom: "40px" }} variant="danger"
            href={`/contactanos?product=${product}`} > Solicita tu crédito</Button>
          <br />
          <a
            className="Descarga"
            style={{ textDecoration: "none", color: "#767676" }}
            href={
              urlDownload
            }
            rel="noopener noreferrer"
            target="_blank"
            download="Tarifario_comisión_y_seguro"
          >

            <DownloadIcon /> Descargar cronograma
          </a>
          <br />

          <br />

          <a
            className="home-link-rojo"
            style={{
              fontFamily: "Str-Microtext-Regular",
              color: "#EC0000",
              cursor: 'pointer'
            }}
            onClick={handleShowResults}
          >
            Volver a resultados
          </a>
        </center>
        <br />
      
        <Container>
          <div className="subtext_schedule">
          <hr/>
            <span>
              Este cronograma es la simulación de un crédito según los datos ingresados por el mismo interesado. No constituye un desembolso ni el compromiso de otorgamiento de un crédito.
            </span>
            <br />
            <span>Las operaciones están sujetas al pago del Impuesto a las Transacciones Financieras (ITF 0.0050%) pudiendo actualizarse el valor según lo establezca las leyes vigentes al momento de realizarse.</span>
            <br /><span>
              Este cronograma es referencial según los datos ingresados de monto, plazo, tasas de interés y seguros. Las condiciones finales estarán sujetas a evaluación por parte de SURGIR.
            </span>
            <br /> <span>
              La validez de los datos es responsabilidad de quien haga el ingreso en este simulador, no existiendo compromiso de SURGIR por atenderlo en dichas condiciones.
            </span>
            <br /><span>
              Tasa de Costo Efectivo Anual (TCEA) calculada sobre la base de 360 días.
            </span>
            <br /><span>
              La Empresa tiene la obligación de difundir información de conformidad con la Ley N° 28587 y el Reglamento de Gestión de Mercado del Sistema Financiero, aprobado mediante Resolución SBS N°3274-2017.
            </span>
          </div>
        </Container>
        <div className="section-protect">
          <Container>
            <div className="title-safe">
              Encuentra el crédito más adecuado para ti
            </div>
            <div className="description-safe">
              Conoce más sobre nuestros créditos
            </div>
          </Container>
          <div className="section-protect__cards caja">
            <SurgirCardProduct
              alt={'Crédito Prospera SURGIR'}
              title={"Crédito Prospera"} text={'Te ayudamos a crecer tu negocio con capital de trabajo y activo fijo.'} className={"card-prospera card-producto"} url={"/credito-prospera"} />
            <SurgirCardProduct
              alt={'Crédito Mujeres Unidas SURGIR'}
              title={"Crédito Mujeres Unidas"} text={'Un crédito grupal que da capital de trabajo a mujeres emprendedoras.'} className={"card-mujeres card-producto"} url={"/prestamo-solidario"} />
            <SurgirCardProduct
              alt={'Crédito Construyendo Sueños SURGIR'}
              title={"Crédito Construyendo Sueños"} text={'Podrás construir cada espacio de tu hogar sin sacrificar el capital de tu negocio.'} className={"card-construyendo card-producto"} url={"/credito-prospera#construyendo-sueños"} />
          </div>
        </div>
      </div>
      )

    </div>
  );
};

export default Cronograma;