import React from "react";
import SurgirhoverBody from "../components/SurgirhoverBody/SurgirhoverBody";
import "./Navbar.scss";

interface Props {
  onShowSearch: () => void;
}

const SurgirNavbar: React.FC<Props> = ({ onShowSearch  }) => { 
  return (
      <SurgirhoverBody onShowSearch={onShowSearch} /> 
  );
}

export default SurgirNavbar;
