import React from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as CallSurgir } from "../../assets/images/ic_phone_call.svg"
import NetworkIcons from "./components/NetworkIcons/NetworkIcons";
import LogoLibro from "./components/LogoLibro/LogoLibro";
import AddressDescription from "./components/AddressDescription/AddressDescription";
import LogoSurgir from "./components/LogoSurgir/LogoSurgir";
import { ReactComponent as Surgir } from "../../assets/images/CobrandSurgir_excepcion_neg.svg";
import './FooterSurgir.scss'
import SurgirButton from "components/ui/SurgirButton/SurgirButton";

interface Props {
  backTop: () => void;
}

const FooterSurgir: React.FC<Props> = (props) => {
  function backToTop(){
     props.backTop();
  }
  return (
    <div id="foot-srg">
      <div className="footer-container-top">
        <div className="container-up-button">
        <div className="d-flex action-up align-items-center">
          <div className="line-action"></div>
          <div className="action-details">
             <span>Volver arriba</span>
             <div className="container-btn-up">
               <SurgirButton 
                 radius={'50px'}
                 onClick={backToTop}
                 fontFamily={"Str-Microtext-Bold"} 
                 width= '32px' 
                 height= '32px' 
                 marginTop= "0px"
                 marginBottom="0px"
                 variant="danger" 
               >
                 <img className="icon-footer" alt='icon-footer' src={`${window.location.origin}/icons/chevron-up-white.svg`} />
               </SurgirButton>
             </div> 
          </div>
        </div>
        </div>
        <Container className="container-top-footer">
          <div className="footer-top-surgir">
            <div className="footer-row-surgir">
              <div className="footer-column-surgir">
                <span
                  style={{
                    display: "block",
                    color: "#444444",
                    fontFamily: "Str-Headline-Bold",
                  }}
                >
                  {" "}
                  Información de interés{" "}
                </span>
                <li>
                  <a
                    href="/quienes-somos"
                    className="item-footer-link "
                    rel="noopener noreferrer"
                  >
                    Quiénes somos
                  </a>
                </li>
                <li>
                  <a
                    href="/tasas-y-tarifas"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Tasas y tarifas
                  </a>
                </li>
                <li>
                  <a
                    href="/transparencia"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Transparencia
                  </a>
                </li>
                <li>
                  <a
                    href="/listado-de-notarios"
                    className="item-footer-link"
                    rel="noopener noreferrer"

                  >
                    Listado de notarios
                  </a>
                </li>
                <li>
                  <a
                    href="/legales"
                    className="item-footer-link"
                    rel="noopener noreferrer"

                  >
                    Legales
                  </a>
                </li>
              </div>

              <div className="footer-column-surgir">
                <span
                  style={{
                    display: "block",
                    color: "#444444",
                    fontFamily: "Str-Headline-Bold",
                  }}
                >
                  {" "}
                  Servicio al cliente{" "}
                </span>

                <li>
                  <a
                    href="/contactanos"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Contáctanos
                  </a>
                </li>

                <li>
                  <a
                    href="/donde-estamos"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Dónde estamos
                  </a>
                </li>

                <li>
                  <a
                    href="/canales"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Canales de pago
                  </a>
                </li>
                <li>
                  <a
                    href="/preguntas-frecuentes"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Preguntas frecuentes
                  </a>
                </li>

              </div>
            </div>
            <div className="footer-row-surgir">

              <div className="footer-column-surgir">
                <span
                  style={{
                    display: "block",
                    color: "#444444",
                    fontFamily: "Str-Headline-Bold",
                  }}
                >
                  {" "}
                  Trabaja con nosotros{" "}
                </span>
                <li>
                  <a
                    href="https://www.linkedin.com/company/surgir-santander-microfinanzas"
                    className="item-footer-link"
                    rel="noopener noreferrer"
                  >
                    Únete a SURGIR
                  </a>
                </li>

              </div>
              <div className="footer-column-surgir">
                <span
                  style={{
                    display: "block",
                    color: "#444444",
                    fontFamily: "Str-Headline-Bold",
                  }}
                >
                  {" "}
                  Central Telefónica{" "}
                </span>
                <li>
                  <span style={{
                    fontFamily: "Str-Headline-Bold",
                    color: "#727272",
                    fontSize: "16px"
                  }}>Lunes a Viernes:</span>
                </li>
                <li>
                  <span
                  style={{
                    fontFamily: "Str-Microtext-Regular",
                    color: "#727272",
                    fontSize: "14px"
                  }}
                  >De 9:00 a.m. a 6:00 p.m.</span>
                </li>

                <li>
                  <a className="footer-link-rojo visible-call-mobile visible-tablet justify-content-center" style={{
                    textDecoration: "none",

                  }} href="tel:(01) 480 0123">
                    <div className="footer-button-call">
                      <div>
                        <span  >Llamar ahora</span>
                      </div>

                    </div>
                  </a>
                  <a className="visible-call-destokp" style={{
                    textDecoration: "none",
                    marginTop: 10,
                  }} href="tel:(01) 480 0123">

                    <span className="call-link">(01) 480 0123</span>
                  </a>
                </li>
              </div>

            </div>

          </div>

        </Container>
      </div>
      <div className="footer-container-top-santader">
        <Container className="p-0">
          <div className="footer-top-santander">
            <div>
              <a
                href="https://www.santander.com/es/home"
                className="item-footer-link"
                rel="noopener noreferrer"
              >
                Santander en el Mundo
              </a>

            </div>
            <div className="footer-top-v-line">
            </div>
            <div >
              <a
                href="https://www.santander.com.pe/"
                className="item-footer-link"
                rel="noopener noreferrer"
              >
                Santander Perú
              </a>
            </div>

           <div className="footer-top-v-line">
            </div>
            <div>
              <a
                href="https://www.sbs.gob.pe/usuarios/"
                className="item-footer-link"
                rel="noopener noreferrer"
              >
                Portal SBS
              </a>
            </div>
            <div className="footer-top-v-line">
            </div>
            <div>
              <a
                href="https://fsd.org.pe/"
                className="item-footer-link"
                rel="noopener noreferrer"
              >
                Fondo de Seguro de Depósitos
              </a>
            </div>
            <div className="footer-top-v-line">
            </div>
            <div>
              <a
                // href="https://www.smv.gob.pe/"
                href="https://www.smv.gob.pe/SIMV/Bp_LisDatosGenerales.aspx?op=bq1"
                className="item-footer-link"
                rel="noopener noreferrer"
              >
                Portal SMV
              </a>
            </div>

          </div>
        </Container>
      </div>
      <div className="footer-container-bottom visible-mobile">
        <Container>
        <div className="container-logo-surgir mt-5">
           <img 
            src="/images/logos/surgir-logo-micro.svg" 
            alt="surgir-icon" 
          />
        </div>
          <div className="footer-bottom-surgir">
            <NetworkIcons />
            <LogoLibro />
            <div className="footer-bottom-v-line-100" />
            <div style={{ width: 300, margin: '0 auto', textAlign: "center" }}>
              <AddressDescription />
            </div>
          </div>
        </Container>
      </div>

      <div className="footer-container-bottom visible-tablet">
        <div className="footer-bottom-surgir">
          <div className="detail-top-footer">
             <div className="container-logo-surgir">
              <img 
                src="/images/logos/surgir-logo-micro.svg" 
                alt="surgir-icon" 
              />
             </div>
             <div style={{ display: "flex", justifyContent: 'center', gap: 20, alignItems: 'center' }}>
            <NetworkIcons />
            <div className="divider-footer"></div>
            <LogoLibro />
          </div>
          </div>

          <div className="footer-bottom-v-line " />

          <div
            style={{
              textAlign: "justify",
              alignSelf: "center",
              display: "flex",
              width: "100%"
            }}
          >
            <AddressDescription />
          </div>
        </div>
      </div>

      <div className="footer-container-bottom visible-destokp">
        <Container className="mx-0 px-0 mw-100">
          <div className="footer-bottom-surgir">
             <div className="container-static-bottom-srg">
             <div className="top-footer-dskt">
                <div className="container-logo-surgir">
                 <img 
                  src="/images/logos/surgir-logo-micro.svg" 
                  alt="surgir-icon" 
                />
                </div>
               <div
                 style={{
                   textAlign: "justify",
                   alignSelf: "center",
                 }}
               >
                 <AddressDescription />
               </div>
             </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
              <div className="divider-footer mr-2"></div>
              <NetworkIcons />
              <div className="divider-footer"></div>
              <LogoLibro />
            </div>
             </div>

          </div>
        </Container>
      </div>
    </div>
  )
};

export default FooterSurgir;